import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SelectableItem } from '@app/shared/item-selector/selectable-item';
import { ICD10Code } from '@app/_alchemint/alchemint_dm';
import { ApiInterfaceService } from '@app/_services/alchemint.apiinterface.service';
import { IcdCodesService } from '@app/_services/icd-codes.service';
import { map, Observable, of, tap } from 'rxjs';
import { IcdCodeFormComponent } from './icd-code-form/icd-code-form.component';
import { WebApiInterfaceService } from '@app/_services/alchemint.webapiinterface.service';

@Component({
  selector: 'app-icd-manager',
  templateUrl: './icd-manager.component.html',
  styleUrls: ['./icd-manager.component.scss']
})
export class IcdManagerComponent implements OnInit, OnDestroy {

  masterSet$: Observable<SelectableItem[]> = of([]);
  activeIcdCodes$: Observable<SelectableItem[]> = of([]);
  masterBackupData: ICD10Code[] = [];
  loadingMasterSets = true;

  constructor(
    private webApiInterface: WebApiInterfaceService,
    private apiInterface: ApiInterfaceService,
    private dialog: MatDialog, 
    private icdService: IcdCodesService) { }

  ngOnInit(): void {
    this.fetchActiveIcdCodes();
    this.fetchIcdMasterSet();
  }

  public deactivate(items: SelectableItem[]): void {
    items.forEach(item => {
      this.apiInterface.deleteEntity<ICD10Code>(new ICD10Code(), item.code).subscribe(() => { });
    })
  }

  public activate(items: SelectableItem[]): void {
    items.forEach(item => {
      const toActivated = this.masterBackupData.find(x => x.id === item.code);
      if (toActivated) {
        this.apiInterface.createEntity<ICD10Code>(new ICD10Code(), toActivated).subscribe(() => { });
      }
    })
  }
  public launchEdit(item: SelectableItem): void {
    if (item && item.code) {
      const editItem = this.masterBackupData.find(x => x.id === item.code);
      if (editItem) {
        const dialogRef = this.dialog.open(IcdCodeFormComponent, {
          data: editItem
        });

        dialogRef.afterClosed().subscribe((result: ICD10Code) => {
          if (result) {
            this.apiInterface.updateEntity(new ICD10Code(), result).subscribe(() => {
              this.activeIcdCodes$ = this.activeIcdCodes$.pipe(map(data => {
                const index = data.findIndex(x => x.code === item.code);
                data[index].description = result.description;
                return data;
              }))
            })
          }
        })
      }
    }
  }
  public launchCreate($event): void {
    const dialogRef = this.dialog.open(IcdCodeFormComponent, {
      data: new ICD10Code()
    });

    dialogRef.afterClosed().subscribe((result: ICD10Code) => {
      if (result) {
        this.apiInterface.createEntity(new ICD10Code(), result).subscribe((createdEntity) => {
          this.activeIcdCodes$ = this.activeIcdCodes$.pipe(map(data => {
           data.unshift({ code : createdEntity.id,name : createdEntity.name, description : createdEntity.description,} as SelectableItem)
            return data;
          }))
        })
      }
    })
  }

  private fetchActiveIcdCodes(): void {
    this.activeIcdCodes$ = this.webApiInterface.getEntities<ICD10Code>(new ICD10Code()).pipe(
      tap(data => this.masterBackupData = [...data, ...this.masterBackupData]),
      map(data => data.sort((a, b) => a.name > b.name ? 1 : -1)),
      map(data => data.map(icd => {
        return {
          code: icd.id,
          name: icd.name,
          description: icd.description
        } as SelectableItem;
      })));
  }

  public fetchIcdMasterSet(): void {
    this.masterSet$ = this.icdService.fetchAllIcdCodes$().pipe(
      map(data => data.filter(item => this.masterBackupData.map(z => z.name).indexOf(item.name) < 0)),
      tap(data => {this.masterBackupData = [...data, ...this.masterBackupData], this.loadingMasterSets = false}),
      map(data => data.sort((a, b) => a.name > b.name ? 1 : -1)),
      map(data => data.map(icd => {
        return {
          code: icd.id,
          name: icd.name,
          description: icd.description
        } as SelectableItem;
      })));
  }

  ngOnDestroy ()
  {
    // Edits in this page will effect preload data so force reload
    this.apiInterface.forcePreLoadDataReload();
  }

}
