<app-error-component></app-error-component>

<div class="col-md-6 offset-md-3 mt-5" [hidden]="(visible==false) || (loading==false)">
    <div class="card">
        <div class="spinner-border text-primary" role="status">

            <span class="sr-only">Loading...</span>
        </div>
    </div>
</div>



<div *ngIf="fieldCustomizerErrors" style="color: red; border: red; border-style: solid;" >
    There is a problem with settings for Field Customization :-
    <BR>
        {{fieldCustomizerErrors}}
  </div>

<div [hidden]="(loading) || (visible==false) || (fieldCustomizerErrors)"  class="dialog-content">


    <div class="col-md-6 offset-md-3 mt-5" *ngIf="isExternalRequest">
        <div class="card">
            
        
            <div class="card-body" >

                <h4 class="card-header" > <b>Dear {{patient?.firstName}}</b>  </h4>
                
                <div *ngIf="(collectPersonalInfo) && (collectAgreements)">
                    <b>{{ sharingOrganization?.name}} (Practice/Reg No.: &nbsp; {{sharingOrganization?.registrationNumber}}) </b>has requested that you please
                    fill out your information and agree to terms. Please Submit all your details and review all terms. If you have any questions 
                    please contact the practice on:
                </div>

                <div *ngIf="(collectPersonalInfo==false) && (collectAgreements == true)">
                    <b>{{ sharingOrganization?.name}} (Reg No. {{sharingOrganization?.registrationNumber}}) </b> has requested that you please review and agree to all terms. If you have any questions 
                    please contact the practice on:
                </div>

                <div *ngIf="(collectPersonalInfo==true) && (collectAgreements == false)">
                    <b>{{ sharingOrganization?.name}} (Reg No. {{sharingOrganization?.registrationNumber}}) </b>has requested that you please
                    fill out your information. If you have any questions 
                    please contact the practice on:
                </div>

                <br>
                <div>Telephone: {{sharingOrganization?.telephoneNumber}}    </div>
    
                <div>Email: {{sharingOrganization?.email}}</div>

                <div *ngIf="orgWebSiteLink">
                    <a [href]="orgWebSiteLink" target="_blank">Practice Website</a>
                </div>

                <br>
                <div>Thank you</div>

            </div>
        
            

        </div>
    </div>

    <app-entity-editor #entityEditorComponentPatient [hidden]="collectPersonalInfo==false" [isPatientEditorMode]="true"></app-entity-editor>
    
    <app-entity-editor #entityEditorComponentBiographical [hidden]="collectPersonalInfo==false" [isPatientEditorMode]="true"></app-entity-editor>
    
    <app-entity-editor #entEditContact [hidden]="collectPersonalInfo==false" [isPatientEditorMode]="true" 
        [additionalButtonText2]="'Postal and Address are Same'" (additionalButtonClicked2)="fillContactPostalAddressFromHome()"></app-entity-editor>

    <app-entity-editor #entEditPersRes [hidden]="collectPersonalInfo==false"  [isPatientEditorMode]="true"
        [additionalButtonText]="'Fill with Patients Details'" (additionalButtonClicked)="fillPersResponseFromPatientDetails()" 
        
        [additionalButtonText3]="'Postal and Address are Same'" (additionalButtonClicked3)="fillPersResponsiblePostalAddressFromHome()"
        hiddenFields="Medical Aid Plan Sub Option Code"
    >
    </app-entity-editor>

    <div class="col-md-6 offset-md-3 mt-5" [hidden]="(loading)">

        <div class="card" [hidden]="(collectAgreements==false)">
            <div class="card-body">
                <h4 class="card-header" [hidden]="collectAgreements==false"> <b>Agreements with {{ sharingOrganization?.name}} (Reg No. {{sharingOrganization?.registrationNumber}})</b>  </h4>

                <div [hidden]="collectAgreements==false">
                    <div *ngIf="contentViewTermsAndConditions.documentLoaded">
                        <input type="checkbox" [(ngModel)]="aggreeToTermsAndConditions" />
                        <label for="aggreeToAllTermsAndConditions"  [style.color]="aggreeToTermsAndConditions ? 'green': 'red'"> &nbsp;
                            I have read and agree to all <b>Terms and Conditions</b> as outlined here:
                        </label>
                        <br>
                    </div>
                    
                    <app-content-view #contentViewTermsAndConditions [hidden]="aggreeToTermsAndConditions"></app-content-view>
    
                    <div *ngIf="contentViewPaymentPolicy.documentLoaded">
                        <br>
                        <input type="checkbox" [(ngModel)]="aggreeToPaymentPolicy" />
                        <label for="aggreeToAllPricing" [style.color]="aggreeToPaymentPolicy ? 'green': 'red'"> &nbsp;
                            I have read and agree to all <b>Payment Policies</b> as outlined here:
                        </label>
                        <br>
                    </div>
    
                    <app-content-view #contentViewPaymentPolicy [hidden]="aggreeToPaymentPolicy"></app-content-view>
    
                    <div *ngIf="contentViewPersonConsentPOPI.documentLoaded">
                        <br>
                        <input type="checkbox" [(ngModel)]="aggreeToPersonConsentPOPI" />
                        <label for="aggreeToPersonConsentPOPI" [style.color]="aggreeToPersonConsentPOPI ? 'green': 'red'"> &nbsp;
                            I have read the terms below and <b>Consent to Allow Electronic Commumications</b>
                        </label>
                        <br>
                    </div>
    
                    <app-content-view #contentViewPersonConsentPOPI [hidden]="aggreeToPersonConsentPOPI"></app-content-view>
    
                    <div *ngIf="contentViewPopiAct.documentLoaded">
                        <br>
                        <input type="checkbox" [(ngModel)]="aggreeToPopiAct" />
                        <label for="aggreeToPopiAct" [style.color]="aggreeToPopiAct ? 'green': 'red'"> &nbsp;
                            I am familiar with my rights in terms of <b>POPI (Protection of Personal Information)</b> as outlined below
                        </label>
                        <br>
                    </div>
    
                    <app-content-view #contentViewPopiAct [hidden]="aggreeToPopiAct"></app-content-view>
    
    
                    <div *ngIf="contentViewOtherContract1.documentLoaded">
                        <br>
                        <input type="checkbox" [(ngModel)]="aggreeToOtherContract1" />
                        <label for="aggreeToOtherContract1" [style.color]="aggreeToOtherContract1 ? 'green': 'red'"> &nbsp;
                            I have read the and agree to the terms below
                        </label>
                        <br>
                    </div>

                    <app-content-view #contentViewOtherContract1 [hidden]="aggreeToOtherContract1"></app-content-view>
    
                    <div *ngIf="contentViewOtherContract2.documentLoaded">
                        <br>
                        <input type="checkbox" [(ngModel)]="aggreeToOtherContract2" />
                        <label for="aggreeToOtherContract2" [style.color]="aggreeToOtherContract2 ? 'green': 'red'"> &nbsp;
                            I have read the and agree to the terms below
                        </label>
                        <br>
                    </div>

                    <app-content-view #contentViewOtherContract2 [hidden]="aggreeToOtherContract2"></app-content-view>

                    <div *ngIf="contentViewOtherContract3.documentLoaded">
                        <br>
                        <input type="checkbox" [(ngModel)]="aggreeToOtherContract3" />
                        <label for="aggreeToOtherContract3" [style.color]="aggreeToOtherContract3 ? 'green': 'red'"> &nbsp;
                            I have read the and agree to the terms below
                        </label>
                        <br>
                    </div>

                    <app-content-view #contentViewOtherContract3 [hidden]="aggreeToOtherContract3"></app-content-view>

                    <label for="signedName" >Please type in your name below as signature &nbsp;</label> <label [style.color]="'red'" [hidden]="signatureStillRequired==false"> *Required </label>
                    <label [style.color]="signedByName ? 'green' : 'red'">Please note that by typing in your name and submitting the information you are acknowledging that you are bound to the terms of the agreement(s).&nbsp;  </label>
                    
                    <input type="text" class="form-control" [(ngModel)]="signedByName" style="font: 400 45px/0.5 'Courgette', Helvetica, sans-serif;" autocomplete="false" />
    
                    <br>    
    
                </div>

                <!-- Patient Consent to use electronic communications

                POPI ACT -->

                


            </div>
    
        </div>

        
        <div class="card-bk" *ngIf="isExternalRequest==true">
            <div class="card-body">
                <div [hidden]="submitAllowKeyInValid==false">
                    Submit not Allowed
                </div>
                <button class="btn btn-primary" (click)="onSubmit()" [disabled]="(allTermsAgreed == false) || (signatureStillRequired==true) || (formErrors) || (submitAllowKeyInValid)">
                    Submit
                </button>
                

                <div [hidden]="(allTermsAgreed==true) && (signatureStillRequired==false) && (!formErrors)" class="alert alert-danger mt-3 mb-0">

                             
                    <button class="btn btn-secondary btn-goto-invalid" (click)="scrollToFirstInvalid()" [hidden]="(formErrorsDetail?.length === 0) || (collectPersonalInfo==false)">
                        Goto Invalid Fields
                        <app-svg color="white" >
                            arrow_upward
                        </app-svg>
                    </button>
                    <div [hidden]="(formErrorsDetail?.length === 0) || (collectPersonalInfo==false)">
                        <li *ngFor="let err of messagesWithScrollTo;">
                            <label> {{err[0]}}. &nbsp;</label>
          


                        </li>
                    </div>


                    <div [hidden]="(!incompleteUserActions)">
                        <li>
                            <label for="formErrors" > Please check the following boxes: {{incompleteUserActions}}. &nbsp;</label> 
                        </li>
                        <!-- <br> -->
                    </div>

                    <div [hidden]="signatureStillRequired==false">
                        <li>
                            <label for="signedName" > Please type in your name as signature. &nbsp;</label> 
                        </li>
                    </div>

                </div>

                <div [hidden]="(!invalidationMessage)" class="alert alert-danger mt-3 mb-0">
                    <p>
                        Invalid input for these fields. Please correct them before submitting.
                    </p>
                    <p>
                        {{invalidationMessage}}
                    </p>
                    
                </div>

            </div>
        </div>
    </div>


    <!-- <div class="card-bk" *ngIf="isExternalRequest==false">
        <div class="card-body"> -->



    
</div>

<div *ngIf="isExternalRequest==false"  [class]="inDialogMode === true ? 'dialog-footer-modal' : 'dialog-footer'">
    <div >


        <button #addOrSaveButton class="btn btn-primary" style="margin-left:1em;" (click)="onSubmit()" [disabled]="(formErrors)">
            {{(addNewPatientMode == true) ? 'Add' : 'Save'}}
        </button>
        <div [hidden]="(!formErrors)" class="alert alert-danger mt-3 mb-0">
           
            <div [hidden]="(!formErrors) || (collectPersonalInfo==false)">
                
                <label for="formErrors" > {{formErrors}}. &nbsp;</label> 
                <br>
            </div>
           
            <div [hidden]="(!incompleteUserActions)">
                <label for="formErrors" > Please check the following boxes: {{incompleteUserActions}}. &nbsp;</label> 
                <br>
            </div>

        </div>

    </div>
</div>
