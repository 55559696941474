import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Route } from '@angular/router';
import { isDevMode } from '@angular/core';
import { AuthenticationService } from '@app/_services';
import { ApiInterfaceService } from '@app/_services/alchemint.apiinterface.service';
import { AlchemintSharedService } from '@app/alchemint-shared.service';
import { Buttons } from '@app/message-box/message-box.module';

@Injectable({ providedIn: 'root' })
export class AuthGuardAdvancedSearch implements CanActivate {
    _canActivate : boolean = false;
    constructor(
        private api: ApiInterfaceService, 
        private alchemintSharedService: AlchemintSharedService,
    ) { 

        
        var sub = api.getPreLoadDataAndSetGlobals().subscribe(    
            data => {
                this._canActivate =  data.apiKeyDetails?.dataLakeLicensed;
            }, 
            error => {
                this._canActivate = false;
                sub?.unsubscribe();
            },
            () => {
                sub?.unsubscribe();
            }
        );

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        
        if (this._canActivate == false)
        {
            this.alchemintSharedService.openMessageBoxSync('Feature not Licensed', 'This feature is not enabled for your organisation. Please contact Alchemed to enable this feature.', Buttons.Ok);
        }

        return this._canActivate;
   }
}