import { Component, OnDestroy, OnInit } from '@angular/core';
import { Consult, ConsultType } from '@app/_alchemint/alchemint_dm';
import { ApiInterfaceService } from '@app/_services/alchemint.apiinterface.service';
import { WebApiInterfaceService } from '@app/_services/alchemint.webapiinterface.service';
import { AlchemintSharedService } from '@app/alchemint-shared.service';
import { map, Observable, of } from 'rxjs';

@Component({
  selector: 'app-manage-consult-types',
  templateUrl: './manage-consult-types.component.html',
  styleUrls: ['./manage-consult-types.component.scss']
})
export class ManageConsultTypesComponent implements OnInit, OnDestroy {

  // public consultTypes$: Observable<ConsultType[]> = of([]);
  public consultTypes: ConsultType[] = [];
  public activeConsultType: ConsultType = new ConsultType();
  public colorOptions = ["Pink", "White", "Green", "Yellow", "Turquoise", "Orange", "Black", "Red", "DodgerBlue", "MediumSeaGreen", "Coral", "SlateBlue", "Goldenrod", "CadetBlue", "Tomato", "SteelBlue", "Peru", "DarkOrchid", "DeepSkyBlue", "LightCoral", "MediumPurple", "DarkSalmon", "LightGreen", "SandyBrown", "Plum", "DarkTurquoise", "Sienna", "Orchid"];

  constructor(private api: ApiInterfaceService, private webApi: WebApiInterfaceService, private alchemintSharedService : AlchemintSharedService) { }

  ngOnInit(): void {
    var sub = this.fetchConsultTypes().subscribe(consultTypes => {
      this.consultTypes = consultTypes;
    },
      err => {
        this.alchemintSharedService.oppenErrDialogForObjectErr(err);
        sub.unsubscribe();
      },
      () => {
        sub.unsubscribe();
      });
  }

  public get canSaveConsultType(): boolean {
    return this.activeConsultType && hasValue(this.activeConsultType?.name);
  }

  public createNewConsult() {
    this.activeConsultType = new ConsultType();
  }

  private fetchConsultTypes(): Observable<ConsultType[]> {
    return this.webApi.getEntities<ConsultType>(new ConsultType());
  }

  // public deleteConsultType(): void {
  //   this.api.deleteEntity<ConsultType>(new ConsultType(), this.activeConsultType.id).subscribe(() => {
  //     const deletetedConsultTypeId = this.activeConsultType.id;
  //     this.consultTypes$ = this.consultTypes$.pipe(map(data => data.filter(x => x.id !== deletetedConsultTypeId)));
  //   })
  // }


  public deleteConsultType(consultType: ConsultType) {

    var sub = this.webApi.getEntitiesExistsByKey<Consult>(new Consult(), "ConsultTypeId", consultType.id).subscribe(exist => {
      if (exist) {
        this.alchemintSharedService.messageDialogSync("This Consult Type has been used for some consults, please unassign the Consult Type from these consults before attempting to delete the Consult Type.");
      }
      else {
        
        var sub3 = this.alchemintSharedService.confirmYesNoDialog(`Are you sure you want to delete the "${consultType.name}" Consult Type?`, false).subscribe(result => {
          if (result === true) {
            this.doConsultTypeDelete(consultType);
          }
        },
          error => {
            this.alchemintSharedService.oppenErrDialogForObjectErr(error);
          },
          () => { sub3.unsubscribe(); }
        );

        

      }
    },
      error => {
        this.alchemintSharedService.oppenErrDialogForObjectErr(error);
      },
      () => { sub.unsubscribe(); }
    );

  }

  doConsultTypeDelete (consultType: ConsultType)
  {
    var sub2 = this.webApi.deleteEntity<ConsultType>(new ConsultType(), consultType.id).subscribe(() => {
      this.consultTypes = this.consultTypes.filter(x => x.id !== consultType.id);
    }, 
    error => {
      this.alchemintSharedService.oppenErrDialogForObjectErr(error);
    },
    () => { sub2.unsubscribe(); }
  );
  }


  public saveConsultType(): void {
    if (this.activeConsultType.id) {
      this.updateConsultType();
    } else {
      this.createNewConsultType();
    }
  }

  private createNewConsultType() {
    this.api.createEntity<ConsultType>(new ConsultType(), this.activeConsultType).subscribe((savedConsultType) => {
      this.consultTypes.push (savedConsultType);
      this.activeConsultType = savedConsultType;
    });
  }

  private updateConsultType() {
    var sub = this.api.updateEntity<ConsultType>(new ConsultType(), this.activeConsultType).subscribe((savedConsultType) => {
      //replace the updatedConsult in this.consultTypes
            //replace the updated site in the this.sites array with savedSite
            var index = this .consultTypes.findIndex(x => x.id == savedConsultType.id);
            if (index)
            {
              this.consultTypes[index] = savedConsultType;
            }
            this.activeConsultType = savedConsultType;
    },
    error => {
      this.alchemintSharedService.oppenErrDialogForObjectErr(error);
      sub.unsubscribe();
    },
    () => { sub.unsubscribe(); }
    );

  }

  ngOnDestroy() {
    // Edits in this page will effect preload data so force reload
    this.api.forcePreLoadDataReload();
  }

}

function hasValue(obj: any): boolean {
  return (obj != null || obj != undefined) && obj !== '';
}