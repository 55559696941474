import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Artifact } from '@app/_alchemint/alchemint_dm';
import { ApiInterfaceService } from '@app/_services/alchemint.apiinterface.service';
import { map, Observable, of, switchMap } from 'rxjs';

@Component({
  selector: 'app-template-selector',
  templateUrl: './template-selector.component.html',
  styleUrls: ['./template-selector.component.scss']
})
export class TemplateSelectorComponent implements OnInit {
  
  templates$: Observable<Artifact[]>;

  constructor(private api: ApiInterfaceService, public dialogRef: MatDialogRef<TemplateSelectorComponent>) { }

  ngOnInit(): void {
    this.templates$ = this.fetchArtifacts();
  }

  private fetchArtifacts(): Observable<Artifact[]> {
    return this.api.getPreLoadDataAndSetGlobals().pipe(
      map(data => data.artifactTypes.find(x => x.code === 'TEMPLATE')),
      switchMap(data => this.fetch(data)));
  }

  private fetch(artiType: any) {
    if (!artiType || !artiType.id) {
      return of([]);
    } else {
      return this.api.getEntityByMatchingProperties<Artifact>(new Artifact(),
        { artifactTypeId: artiType.id } as Artifact).pipe(map(data => data.sort((a, b) => a.name > b.name ? 1 : -1)));
    }
  }
}
