import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { eBillingMethods } from '@app/_alchemint/alchemint_billing';
import { AlchemedPreLoadData } from '@app/_alchemint/alchemint_composite_requests';
import { BillingInstruction, Site } from '@app/_alchemint/alchemint_dm';
import { ApiInterfaceService } from '@app/_services/alchemint.apiinterface.service';

@Injectable({
  providedIn: 'root'
})
export class BillingRequestService {
  constructor(private router : Router) {

  }

  private _preLoadData : AlchemedPreLoadData;
  private _integratedMode : boolean = false;

  public set preLoadData (preloadData : AlchemedPreLoadData)
  {
    this._preLoadData = preloadData;
    this._integratedMode = (this.billingMethod === eBillingMethods.eIntegratedBillingModule);
  }

  public get billingMethod () : eBillingMethods
  {
    if (this._preLoadData)
    {
      return this._preLoadData?.apiKeyDetails?.bilingMethod;
    }
    else {
      return eBillingMethods.eNone;
    }
  }

  public get integratedMode () : boolean
  {
    return this._integratedMode;
  }

  public routeToInvoiceForBillingInstruction(billingInstruntion : BillingInstruction) : void
  {
    this.router.navigate(['billing', 'capture-invoice', billingInstruntion.customerId, 'bi', billingInstruntion.id]);
  }


  public get useBilling(): boolean {
    var billMethod = this.billingMethod;
    return this.billingMethod != eBillingMethods.eNone;
  }

  public get isIntegratedBillingEnabled () : boolean
  {
    return ((this?.billingMethod === eBillingMethods.eIntegratedBillingModule));
  }


}



