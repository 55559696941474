<section class="container alc_update_div">
    <aside class="left">
        <mat-card>
            <li *ngFor="let c of consultTypes" (click)="activeConsultType = c" class="custom-action-list-item" [style]="'background-color:' + c.color + ';'">
                {{c.name}} <app-svg color="red" style="float:right" (click)="deleteConsultType(c)">delete</app-svg>
            </li>
        </mat-card>
    </aside>
    <main>
        <div class="page-title"> <p>Manage Consult Types</p></div>
        <mat-card>
            <div style="display: flex; justify-content: flex-end; width:100%">
                <button mat-raised-button  style="color: white;" class="btn btn-primary" (click)="createNewConsult()">
                    <app-svg color="white">add</app-svg> Add Consult Type
                </button>
            </div>
            <div class="label-input-row">
                <label>Name</label>
                <mat-form-field appearance="fill">
                    <input matInput [(ngModel)]="activeConsultType.name">
                </mat-form-field>
            </div>
            <div class="label-input-row">
                <label>Description</label>
                <mat-form-field appearance="fill">
                    <input matInput [(ngModel)]="activeConsultType.description">
                </mat-form-field>
            </div>
            <div class="label-input-row">
                <label>Colour</label>
                <mat-select [(ngModel)]="activeConsultType.color" [style]="'background-color:' + activeConsultType.color + ';'">
                    <mat-option [value]="c" *ngFor="let c of colorOptions" [style]="'background-color:' + c + ';'">{{c}}</mat-option>
                </mat-select>
            </div>
            <div class="label-input-row" hidden>
                <label>Budget Amount</label>
                <mat-form-field style="width: 120px;" appearance="fill">
                    <input matInput [(ngModel)]="activeConsultType.budgetAmount">
                </mat-form-field>
            </div>
            <div>
                <button mat-raised-button [disabled]="!canSaveConsultType" class="btn btn-primary" style="color: white;"
                    (click)="saveConsultType()">Save Consult Type</button>
            </div>
        </mat-card>



        <router-outlet></router-outlet>
    </main>
</section>

<button class="btn btn-primary" appDoneButton>
    <app-svg color="white" class="btn-icon"> arrow_back </app-svg> Done
</button>

