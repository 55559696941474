import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Route } from '@angular/router';
import { isDevMode } from '@angular/core';
import { AuthenticationService } from '@app/_services';

@Injectable({ providedIn: 'root' })
export class AuthGuardAdminFunctions implements CanActivate {
    _canActivate : boolean = false;
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService, 
    ) { 


        if (this.authenticationService.useNewAuthenticationMethod)
        {
            const user = this.authenticationService.getStoreLogidInUser();
            this._canActivate = usr?.masterPriveledges;
        }
        else
        {
            const user = this.authenticationService.userValue;

            if (this.authenticationService.userValue === null)
            {
                var usr = this.authenticationService.GetStoredUserWithNoPassword ();
                this._canActivate = usr?.masterPriveledges;
            }
            else if (user?.masterPriveledges === true)
            {
                
                this._canActivate =  true;
            }
            else
            {
                
                this._canActivate = false;
            }
    
    
        }


    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        
        if (this._canActivate == false)
        {
            alert('You do not have administrative rights.')
        }
        
        return this._canActivate;
        const user = this.authenticationService.userValue;
        
        if (this.authenticationService.userValue === null)
        {
            this.authenticationService.userValue 
        }
        else if (user?.masterPriveledges === true)
        {
            
            return true;
        }
        else
        {
            alert('You do not have administrative rights.')
            return false;
        }
   }
}