<!-- <div *ngIf="isChatOpen" class="chat-body">
  <div class="messages">
    <ng-container *ngFor="let group of groupedMessages">
      <div class="day-separator">
        {{ group.date | date: 'EEEE, MMM d, y' }}
      </div>

      <div *ngFor="let message of group.messages" class="message"
        [ngClass]="{'sent': message.isClientMessage, 'received': !message.isClientMessage}">
        <p>
          {{ message.message }}
          <button *ngIf="message.routeLink" class="btn btn-primary" (click)="gotoSentLink(message.routeLink)" 
                  [title]="message.routeLink">Goto Link</button>
        </p>
        <div class="timestamp">
          {{ message.addedDate | date: 'shortTime' }}
        </div>
      </div>
    </ng-container>
  </div>
  <div class="chat-input">
    <input type="text" [(ngModel)]="newMessage" (keydown.enter)="sendMessage()" placeholder="Type a message...">
    <button (click)="sendMessage()">Send</button>
  </div>
</div> -->



<div class="chat-window" >
<div [hidden]="historyVisible===true">
  <div class="chat-header">
    <h2 (click)="showDetail=!showDetail">Support Chat</h2>


    <div class="close-btn">

      <table>
        <tr>
          <td>
            <app-svg title="History" (click)="historyVisible=true">work_history</app-svg>
          </td>

          <td>

            <app-svg (click)="closeChat()" title="Close">close</app-svg>
          </td>
          <td>
            <div class="connection-status" (click)="toggleConnectionState()"
              [title]="isConnected ? 'Online' : 'Offline'">
              <span [class]="isConnected ? 'led led-green' : 'led led-red'"></span>
            </div>
          </td>
        </tr>
      </table>






    </div>


  </div>
  <div *ngIf="showDetail" class="chat-header">
    <p>
      UserId: {{uniqueUserId}}
    </p>
  </div>
  <div *ngIf="showDetail" class="chat-header">
    <p>
      Ticket: {{supportTicket?.id}}
    </p>
  </div>
  <div>



  </div>
  <div class="chat-body" *ngIf="isChatOpen">
    <div class="messages">
      <div *ngFor="let message of messages" class="message"
        [ngClass]="{'sent': message.isClientMessage, 'received': !message.isClientMessage}">
        <p>
          {{ message.message }}

          <button *ngIf="message.routeLink"  class="btn btn-primary" (click)="gotoSentLink(message.routeLink)" [title]="message.routeLink"> Goto Link</button>


        </p>

        
        <div class="timestamp">
          <!-- {{ message.addedDate | date: 'shortTime' }} -->
          {{ message.addedDate | date: 'HH:mm-dd-MMM' }}
        </div>
      </div>
    </div>
    <div class="chat-input">
      <input type="text" [(ngModel)]="newMessage" (keydown.enter)="sendMessage()" placeholder="Type a message...">
      <button (click)="sendMessage()">Send</button>
    </div>
  </div>
</div>



<div  [hidden]="historyVisible===false" class="chat-header">
  
  <div class="close-btn">
    <app-svg (click)="historyVisible=false" title="BAck">arrow_back</app-svg>
  </div>
  
  <p *ngIf="orgSupportItems?.length <= 0">
    No previous support tickets found.
  </p>
  <table style="max-height: 50%; overflow-y: scroll;" *ngIf="orgSupportItems?.length > 0">
    <thead>
      <tr >
        <th>Ticket</th>
        <th style="padding: 0.5em;">Issue</th>

      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let ticket of orgSupportItems" (click)="loadHistoricalSupportTicket(ticket.supportTicketId); historyVisible=false;">

        <td>{{ 1000 + ticket.sequenceNumber }}</td>
        <td style="padding: 0.5em;">{{ ticket.question }}</td>

      </tr>
    </tbody>
  </table>
</div>


</div>

