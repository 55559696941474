import { Injectable } from '@angular/core';
import { AlchemintSharedService } from '@app/alchemint-shared.service';

@Injectable({
  providedIn: 'root'
})
export class UnhandledErrorLoggingService {


    logHistory = [];
    originalConsoleLog: any;
    originalConsoleError: any;
    constructor(private alchemintSharedService: AlchemintSharedService) {
        (window as any).onerror = (errorMsg: string, url: string, lineNumber: number, columnNumber: number, error: Error) => {
          console.error(`Error: ${errorMsg} at ${url}:${lineNumber}:${columnNumber}`);
          // You can also send the error details to a server for further analysis.
        };




        // Store the original console.log function
          this.originalConsoleLog = console.log;

          this.originalConsoleError = console.error;
          

          if (this.consoleLoggingToCacheEnabled)
          {
            this.swithchOnLogCache();
          }
          

        // Custom logging function


      }


      public get consoleLoggingToCacheEnabled (): boolean {
        return localStorage.getItem("useCachedLogging")==='true';
      }

          // Add an error handler for unhandled promise rejections

        //  console.log = 
      CachingConsoleLog(message)  {
            // Call the original console.log function
          this.originalConsoleLog(message);
          // Store the message in the log history
          // this.logHistory.push(message);

          this.logMEssageOrError(message,null);

      };
      
      CachingConsoleError(message?: any, ...optionalParams: any[]): void
      {
        // Call the original console.log function
        this.originalConsoleError(message, optionalParams);
        // Store the message in the log history
        this.logMEssageOrError(message,optionalParams);

      }
      

      logMEssageOrError(message: any, optionalParams: any[])
      {
        if (typeof message === 'string') {
          this.logHistory.push(message);
        } else if (typeof message === 'object') {
          this.logHistory.push(JSON.stringify(message));
        } else {
          this.logHistory.push(message);
        }
        
        if (optionalParams)
        {
          this.logHistory.push(String(optionalParams));
        }
      }



      // Display log history with an alert
      displayLogHistory() {
        
        this.alchemintSharedService.messageDialogSync(this.logHistory.join("\n"));
      }

      swithchOnLogCache()
      {
        console.log = this.CachingConsoleLog.bind(this);
        console.error = this.CachingConsoleError.bind(this);
      }

      swithchOffLogCache()
      {
        console.log = this.originalConsoleLog;
        console.error = this.originalConsoleError;
      }

      //displayLogHistory();

}