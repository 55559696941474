<div style="background-color: transparent;">
  <app-window-close-button [dialogRef]="activeDialog"></app-window-close-button>
</div>

<div class="container" *ngIf="!requestUserMfaRecoveryDetails; else shoUserMfaRecoverySettings">
  <h2>Multi factor authentication configuration</h2>
  <div class="message-container" *ngIf="!confirmOTP">
    <p class="message">Enhance the security of your account by setting up Two-Factor Authentication (2FA).</p>
  </div>
  <div class="config-options" *ngIf="!confirmOTP">
    <mat-card>
      <mat-card-content class="mfa-channel">
        <div>
          <mat-icon class="toggle-icon">message</mat-icon>
          <span>SMS</span>
        </div>
        <mat-slide-toggle [color]="'primary'" [checked]="useSMS" [(ngModel)]="useSMS"
          (ngModelChange)="on2FAchannelToggled('SMS', useSMS)">
        </mat-slide-toggle>
      </mat-card-content>
    </mat-card>

    <mat-card>
      <mat-card-content class="mfa-channel">
        <div>
          <mat-icon class="toggle-icon">email</mat-icon>
          <span>Email</span>
        </div>
        <mat-slide-toggle [color]="'primary'" [checked]="useEmail" [(ngModel)]="useEmail"
          (ngModelChange)="on2FAchannelToggled('EMAIL',useEmail)">
        </mat-slide-toggle>
      </mat-card-content>
    </mat-card>

    <mat-card>
      <mat-card-content class="mfa-channel">
        <div>
          <mat-icon class="toggle-icon">lock</mat-icon>
          <span> App</span>
        </div>
        <mat-slide-toggle [color]="'primary'" [checked]="useAuthApp" [(ngModel)]="useAuthApp"
          (ngModelChange)="on2FAchannelToggled('APP', useAuthApp)">
        </mat-slide-toggle>
      </mat-card-content>
    </mat-card>
  </div>

  <form class="phoneNumberInput" *ngIf="useSMS" [formGroup]="phoneForm">
    <div style="display: flex;">
      <mat-form-field class="country-code">
        <mat-label>Code</mat-label>
        <mat-select placeholder="Select Country Code" formControlName="countryCode">
          <mat-option *ngFor="let country of countryCodes" [value]="country.code">
            {{ country.name }} ({{ country.code }})
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Phone Number</mat-label>
        <input matInput placeholder="+27781236456" formControlName="phoneNumber" required>
        <mat-error *ngIf="phoneForm?.controls['phoneNumber'].invalid">Invalid phone number</mat-error>
      </mat-form-field>
    </div>
    <button type="button" class="btn btn-primary" mat-raised-button [disabled]="phoneForm?.invalid"
      (click)="enable2FAWithSMS()">Send OTP</button>
  </form>

  <form class="otpEmailForm" *ngIf="useEmail">
    <mat-form-field appearance="fill">
      <mat-label>Email Address</mat-label>
      <input matInput [formControl]="emailFormControl" placeholder="Email Address">
      <mat-error *ngIf="emailFormControl.invalid">
        Valid email address required
      </mat-error>
    </mat-form-field>
    <button mat-raised-button class="btn btn-primary" (click)="enable2FAWithEmail()">Send OTP</button>
  </form>

  <div *ngIf="useAuthApp">
    <div *ngIf="qrCodeImagePath;then tfaQrImage"></div>
  </div>
  <div *ngIf="useSMS || useEmail">
    <div *ngIf="confirmOTP;then otpConfirmForm"></div>
  </div>
</div>

<div class="disable-2fa" *ngIf="!confirmOTP && !requestUserMfaRecoveryDetails" >
  <button class="btn btn-warning" (click)="disableTwoFactorAuth()">Click here to disable two factor
    authentication</button>
</div>

<div class="error-box" *ngIf="otpConfirmationErrorMessage">
  <div class="error-message">
    {{otpConfirmationErrorMessage}}
  </div>
</div>

<div class="error-box" *ngIf="configure2FARequestErrorMessage">
  <div class="error-message">
    {{configure2FARequestErrorMessage}}
  </div>
</div>

<ng-template #tfaQrImage>
  <div style="display: flex;flex-direction: column;justify-content: center; align-items: center;">
    <img [src]="qrCodeImagePath" width="270" height="270">
    <ng-container *ngTemplateOutlet="otpConfirmForm"></ng-container>
  </div>
</ng-template>

<ng-template #otpConfirmForm>
  <div class="confirm-otp" *ngIf="confirmOTP || useAuthApp">
    <one-time-passowrd-form [allowOtpResending]="false" [mode]="'CONFIGURE'"  (onVerify)="submit($event)"></one-time-passowrd-form>
  </div>
</ng-template>



<ng-template #shoUserMfaRecoverySettings>
     <mfa-recovery-settings [otpCode]="optCode" [currentEmail]="currentRecoveryEmail" [currentPhoneNumber]="currentRecoveryPhone"></mfa-recovery-settings>
</ng-template>
