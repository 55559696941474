export class ArtiFormHelper
{
    public static validStringTypeFormFields () : string[]
    {
        return [
            'STRING','MAT','ICD','PROC','USERSIG', 'MULTILINE','INTELI','CUCB','CULI','CULIMULT','SELECTLIST','PICKER', 'LABEL', 'SITE','CLINICIAN','DIGSIG','IMAGE','AUTOEMAIL','CONTACT'];
    }

    public static isStringType (fieldType : string) : boolean
    {
        return (this.validStringTypeFormFields().indexOf(fieldType) >= 0);
    }

    public static canChangeFieldType (toField : string, fromField : string) : boolean
    {
        
        if ((toField === 'INT') &&  (fromField === 'RANGEOPT'))
        {
            return true;
        }
        else if ((toField === 'RANGEOPT') &&  (fromField === 'INT'))
        {
            return true;
        }
        else if (toField === fromField)
        {
            return true;
        }
        else if (this.isStringType(toField) && this.isStringType(fromField))
        {
            return true;
        }
        else
        {
            return false;
        }
    }
    //'BOOL', 'INT', "DECIMAL", "DATETIME", "LABEL"
}

