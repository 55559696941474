import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { ChartsModule } from 'ng2-charts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WindowCloseButtonComponent } from '../window-close-button/window-close-button.component';

//import { BrowserModule } from '@angular/platform-browser';

const routes: Routes = [
  {path: "", component: WindowCloseButtonComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes) , CommonModule
  ],
  exports: [RouterModule]
})
export class WindowCloseButtonRoutingModule { }

