
import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { ActivityTracker } from '@app/activity-tracker.service';

@Directive({ selector: '[activity-track]' })
export class ClickTrackerDirective implements OnInit {

  @Input() elementID?: string;
  @Input() trackCategory?: string;

  constructor(private el: ElementRef, private tracker: ActivityTracker) { }

  ngOnInit() {
    this.el.nativeElement.addEventListener('click', (event: any) => {
      const clickedElementId = this.elementID || event.target['id'];
      if (clickedElementId) {
        this.tracker.track(clickedElementId, this.trackCategory);
      }
    });
  }
}
