import { EventEmitter, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as Alchemint from '@app/_alchemint/alchemint_dm';
import { HelperFunctions } from '@app/_helpers/helperfunctions';
import { WebApiInterfaceService } from '@app/_services/alchemint.webapiinterface.service';
import { async, Observable } from 'rxjs';

// export interface CompSetUser
// {
//     set SerCurrentUserId (value : string);
// }

export class ExternalRequestDetails {
  public requestDetails: string;
  public requestId: string;
}

export class CompositeExternalRequest {
  public Requests: ExternalRequestDetails[];
  public wk: string;
}

export class ExternalRqDetails {
  public patient: Alchemint.Patient;
  public biographicalDetails: Alchemint.BiographicalDetails;
  public contactDetails: Alchemint.ContactDetails;
  public personResponsibleForAccount: Alchemint.PersonResponsibleForAccount;

  public externalRequest: Alchemint.ExternalRequest;
  public patientProperties: PropertyDescriber[];
  public biographicProperties: PropertyDescriber[];
  public contactProperties: PropertyDescriber[];
  public personResponsibleProperties: PropertyDescriber[];
  public artiFormBundle: ArtiFormBundle;

  public clinicalNotes: string;

  public organisation: Alchemint.Organisation;
  public completedPreAnaesthesia: string;

  public automatedPreAnaesthesiaRecommendations: string;

  public preAnaesthesiaRecommendations: string;

  public preAnaestheticReportPreEdit: string;

  public preAnaesthesiaReport: string;

  public paduaPredictionScore: number;

  public rCRIScore: number;

  public bloodThinnerTypes: string[];

  public anaestheticOptions: string[];

  public publicContentKey: string;

  public completedCovidReport: string;

  public covidFormFieldAliases: string;

  public preAnaesthesiaFormFieldAliases: string;

  public useIdNumberToDeduceDateOfBirth: boolean;

  public fieldCustomizers: FieldCustomizer[];
  public customizationErrors: string;


  public contact: Alchemint.Contact;

  public medicalAidSchemes:MedicalAidScheme [];

  public iHealthIntegratedMode: boolean;

  public canUseSMSForSharedArtifact: boolean;

  public canUseEmailForSharedArtifact: boolean;
}

export enum RequestType {
  DetailsCollection,
  ShareArtifact,
  PreAenestheticQuestionaire,
  CovidQuestionaire,
  FormCompletion,
  PatientInfoCollection,
  BiographicInfoCollection,
  ContactDetailsCollection,
  PersResponsibleCollection,
  ShareClinicalNotes,
  ShareAllOtherFiles,
  ShareForms,
  ShareMedicalConditions,
  ShareConsultHistory,
  AlchemedRecommendations,
  AnaesthetistDoRecommendations,
  AnaesthetistShareWithPatient,
  SurgeonShareWithPatient,
  ViewTheatreSlate,
  ViewPDFTheatreSlate,
  PostOpSugeonForms,
  TrackWorkFlow,
  AnaesthetistViewPreAnaestheticQuestionaire,
  SurgeonViewReport,
  ContractsCollectionOnly,
  DetailsCollectionButNoContracts,
  NullRequestType,
  FormResults,
  CovidResults,
  ShareFolder,
  ShareFile, 
  ExistingFormForPatientToSign
}

export class ExternalRequestShareFileOptions
{
  public emailMeNewPassword : boolean = false;
  public smsMeNewPassword : boolean = false;
  public emailMeOTP : boolean = false;
  public smsMeOTP : boolean = false;
  public resetPassword : boolean = false;
}


export class PropertyDescriber {
  public name: string;
  public isHidden: boolean;
  public isPrimaryKey: boolean;
  public isComboList: boolean;
  public isComputed: boolean;
  public isForeignKey: boolean;
  public isInternal: boolean;
  public isLocked: boolean;
  public isRequired: boolean;
  public isSelectList: boolean;
  public Length: boolean;
  public isModifiedDate: boolean;
  public label: string;
  public typeCode: string;
  public listValues: string[];
  public isEmailAddress: boolean;
}

export class FormFieldDescriber {
  public formFieldId: string;
  public type: string;
  public label: string;
  public options: string[];
  public value: any;
  public hidden: boolean;
  public description: string;
  public listID: string;
  public listItems: string[];
  public pattern: string;
  public required: boolean;
  public minimumValue: number;
  public maximumValue: number;
  public invalidationStyle: string;
  public ommit: boolean;
  public fieldKey: string;
  public default: string;
  public billingField: boolean;
  public autoCompleteValues: string[];
  public fetchAutoCompleters: boolean;
  public storeAutoCompleters: boolean;
  public autoCompleteFilteredValues: Observable<string[]>;
  public isInvalidValue: boolean;
  public invalidationMessage: string;
  public smartFormFieldStyleAttributes: string;
  public toolTip: string;
  public multiSelectList: boolean;
  public complexListItem: NameAndDesc[];
  public longdescription: string;
  public isCustomList: boolean;
  public custListCode: string;
  public labelStyle: string;
  public ratingAliases: string;
  public emailAddressField: boolean;
  public showCondition: ShowCondition;
}

export class ShowCondition
{ 
  public conditionsfield: string; 
  public value: string;
  public comparison: string;
}



export class NameAndDesc {
  constructor(public name: string, public description: string) {}
}

export class ArtiFormBundle {
  public artiForm: Alchemint.ArtiForm;
  public artiFormFields: Alchemint.ArtiFormFld[];
  public artiforFieldTypes: any;
  public formResults: string;
  public template: any;
  public templateString: string;
  public templateFileFormat: string;
  public mergedTemplate: any;
}

export class ArtiFormInstanceBundle {
  public artiForm: Alchemint.ArtiForm;
  public artiFormInstanceDetails: Alchemint.ArtiFormInstance;
  public artiFormInstance: any;
  public attachments: any;
  public billingInstruction: Alchemint.BillingInstruction;
  public mergedArtifact: Alchemint.Artifact;
  public mergedHtml: string;
  public wasAutoEmailed: boolean;
  public autoEmailedAddresses: string;
}

export class PreAnaestheticQuestionaire {
  public popiconsent: boolean = false;
  public height: number = 0;
  public weight: number = 0;

  public dob: Date = new Date('01-01-1990');
  public sex: string = '';
  public smoke: boolean = false;
  public numsmokesperday: number = 0;
  public smokeyears: number = 0;
  public alcohol: boolean = false;
  public alcoholdailyunits: number = 0;
  public recreationaldrugs: boolean = false;
  public recreationaldrugsdetails: string = '';

  public surgery: boolean = false;
  public bodypart: string = '';
  public procedures: string = '';
  public procedureyear: number;
  public complications: string = '';

  public surgery2: boolean = false;
  public bodypart2: string = '';
  public procedures2: string = '';
  public procedureyear2: number;
  public complications2: string = '';

  public surgery3: boolean = false;
  public bodypart3: string = '';
  public procedures3: string = '';
  public procedureyear3: number;
  public complications3: string = '';

  public surgeryother: string = '';

  public surgeryortraumainlastmonth: boolean = false;

  public meds1: string = '';
  public meds2: string = '';
  public meds3: string = '';
  public meds4: string = '';
  public meds5: string = '';
  public meds6: string = '';

  public takingbetablockers: boolean = false;
  public takingbloodthinners: boolean = false;
  public bloodthinnersname: string = '';
  public steroids: boolean = false;
  public steroidsname: string = '';
  public steroidsdose: string = '';
  public steroidduration: string = '';
  public hrt: boolean = false;
  public noregularmedications: boolean = false;

  public allergy1: string = '';
  public allergy2: string = '';
  public allergy3: string = '';
  public allergy4: string = '';
  public allergy5: string = '';

  public latexallergy: boolean = false;
  public noknowndrugallergies: boolean = false;

  public cardiovascular: boolean = false;

  public chestpain: boolean = false;
  public irregularheartbeat: boolean = false;
  public heartmurmur: boolean = false;
  public cardiacfailure: boolean = false;
  public cardiacfailureyear: number = 0;
  public pacemaker: boolean = false;
  public pacemakertype: string = '';

  public circulationproblem: boolean = false;
  public bloodclots: boolean = false;
  public highbp: boolean = false;
  public cardiologist: string = '';
  public cardiovasother: string = '';

  public respiratory: boolean = false;
  public asthma: boolean = false;
  public emphysema: boolean = false;
  public tb: boolean = false;
  public bronchitis: boolean = false;
  public shortofbreath: boolean = false;
  public upperrespinfectorcoldtwoweeks: boolean = false;
  public sleepapnea: boolean = false;
  public usecpap: boolean = false;
  public pulmonologist: string = '';
  public respiratoryother: string = '';

  public gastrointestinal: boolean = false;
  public gastrointestinalother: string = '';

  public musculoskeletal: boolean = false;
  public musculoskeletalother: string = '';

  public blood: boolean = false;
  public abnormalbleeding: boolean = false;
  public sicklediseaseortrait: boolean = false;
  public historyofbloodtransfusions: boolean = false;
  public objecttobloodtransfuse: boolean = false;
  public hivpositive: boolean = false;
  public bloodother: string = '';

  public physicallyactive: boolean = false;
  public walk1kmnotoutofbreath: boolean = false;
  public walkup2flightsnotoutofbreath: boolean = false;
  public physicallylimiteddisability: boolean = false;
  public physicallylimiteddisabilitydetails: string = '';
  public physicallylimitedinjury: boolean = false;
  public physicallylimitedinjurydetails: string = '';
  public sleeplyingflat: boolean = false;
  public wakeoutofbreath: boolean = false;

  public diabetes: boolean = false;
  public takinginsulin: boolean = false;
  public insulinpump: boolean = false;
  public thyroidproblem: boolean = false;
  public thyroidhypo: boolean = false;
  public thyroidhyper: boolean = false;
  public kidneyproblem: boolean = false;
  public kidneyprobdetails: string = '';
  public bladderproblem: boolean = false;
  public bladderproblemdetails: string = '';
  public prostateproblem: boolean = false;
  public prostateproblemdetails: string = '';
  public canturinateafteranaesthetic: boolean = false;
  public dialysis: boolean = false;
  public dialysisschedule: string = '';
  public otherother: string = '';

  public liverdisease: boolean = false;
  public hiatialhernia: boolean = false;
  public refluxorheartburn: boolean = false;
  public gastriculcers: boolean = false;

  public neurological: boolean = false;
  public stroke: boolean = false;
  public tia: boolean = false;
  public seizures: boolean = false;
  public epilepsy: boolean = false;
  public memoryproblems: boolean = false;
  public confusion: boolean = false;
  public multiplesclerosis: boolean = false;
  public nerveinjury: boolean = false;
  public spinalcordinjury: boolean = false;
  public neuropathy: boolean = false;
  public neurologicalother: string = '';

  public backorneckprob: boolean = false;
  public physicalrestrictions: boolean = false;
  public physicalrestrictiondetails: string = '';
  public musculardystrophy: boolean = false;
  public weakness: boolean = false;
  public osteoarthritis: boolean = false;
  public rheumatoidarthritis: boolean = false;

  public cancer: boolean = false;
  public cancerdetails: string = '';
  public psychiatricdisorder: boolean = false;
  public psychiatricdisorderdetails: string = '';
  public otherillness: boolean = false;
  public otherillnessdetails: string = '';

  public looseteeth: boolean = false;
  public dentures: boolean = false;
  public dentalcaps: boolean = false;
  public dentalcrowns: boolean = false;
  public dentalother: string = '';

  public pregnant: boolean = false;
  public postmenopause: boolean = false;
  public hysterectomy: boolean = false;

  public anaesthesiainlastyear: boolean = false;
  public difficultintubation: boolean = false;
  public objectionspinalorepidural: boolean = false;
  public adversereactionanaesthesia: boolean = false;
  public malignantyhperthermia: boolean = false;
  public scolineapnoea: boolean = false;
  public nauseaorvomitafteranaesthesia: boolean = false;

  public anythingelse: string = '';

  public risksduetoeatanddrink: boolean = false;
  public confirmtrue: boolean = false;
}

export class AnaesthetistDoRecommendations {
  constructor(
    public name: string,
    public value: string,
    public highlight: boolean,
    public extra: string,
    public selected: boolean,
    public style: string
  ) {}
}

export class PreAnaesthesiaReport {
  public Id: string;
  public PatientId: string;
  public SurgeryId: string;

  public Salutation: string;
  public Introduction: string;

  public Age: number;
  public Sex: string;
  public Bmi: number;

  public MedicalHistory: string;
  public SurgicalHistory: string;
  public RegularMedications: string;
  public KnownAllergies: string;
  public PhysicalAbility: string;
  public Smoking: string;
  public Drinking: string;
  public Drugs: string;

  public RecommendAnaestheticOptions: string;
  public Recommendations: string;
  public RecommendConsult: boolean;
  public RecommendationOther: string;

  public Valediction: string;

  public SignedByDigital: string;

  public PaduaPredictionScore: number;
  public RCRIScore: number;

  public ASAScore: number;

  public ForWoman: string;
  public AnaesthesiaRelated: string;
  public AnyThingElse: string;
}

export class CovidQuestionaire {
  previouspositivediagnosis: boolean = false;
  previouscoviddetails: string = '';
  admittedduetocovid: boolean = false;
  admittedtoicuduetocovid: boolean = false;
  noninvasiveventilator: boolean = false;
  ventilator: boolean = false;
  numberofdays: number = 0;

  previouslyvaccinated: boolean = false;
  vaccinationtype: string = '';
  vaccinationdate: Date = new Date();

  recentlyexposedtocovid: boolean = false;
  anysymptoms: boolean = false;

  covidtested: boolean = false;
  testnasalswabantigen: boolean = false;
  testrapid: boolean = false;
  testpcr: boolean = false;
  testbloodantibodies: boolean = false;
  testdate: Date = new Date();
  testpositive: boolean = false;
  testscheduledfordate: Date = new Date();
}

export class CompositePatient {
  patient: Alchemint.Patient;
  biographicalDetails: Alchemint.BiographicalDetails;
  contactDetails: Alchemint.ContactDetails;
  personResponsibleForAccount: Alchemint.PersonResponsibleForAccount;
  aggreementDTO: AggreementDTO[];
  agreements: Alchemint.Agreement[];
}

export class CorrectIntegratedPatientLinkDTO {
  sourceAppointMentId: string;
  wrongLinkedPatientId: string;
  correctPatientId: string;
  deleteWrongLinkedPatient: boolean;
  moveFilesToTargetPatient: boolean;
}

export class CoditionalFormatter {
  constructor(
    public fieldNameToCheck: string,
    public fieldValueToCheck: string,
    public trueFormat: string,
    public falseFormat: string,
    public fullRowFormat: boolean = false
  ) {}
  public getFormat(colName, value): string {
    if (colName === this.fieldNameToCheck) {
      if ((value === +this.fieldValueToCheck) || (value === this.fieldValueToCheck) || (this.fieldValueToCheck === "@ANY"))
      {
        return this.trueFormat;
      }
      else if (!value) {
        return '';
      } 
      else if ((value != +this.fieldValueToCheck) || (value != +this.fieldValueToCheck)) 
      {
        return this.falseFormat;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }
}

export class ReportDataDTO {
  columnNames: string[];
  data: any[][];
  hyperlinkColumnNames: string[];
  coditionalFormatters: CoditionalFormatter[];
  hiddenColumnNames: string[];
  pausedColumnName: string;
  completedColumnName: string;
  sourceRecordIdColumn: string;
  deleteColumnName: string;
  appRoutingColumn: string;
  graphOptions: GraphOptions[];

  vsiRules: VsiRule[];


}

export class VsiRule {
  code: string;
  name: string;
  etlReportName: string;
  mustBeGreaterThan: number;
  weighting: number;

}

export class GraphOptions
{
    public xAxis: string
    public yAxis: string
    public secondaryCategory:  string;
    public chartType: string;
    public top: number;
    public bottom: number;
    public filter: string;
    public graphName: string
}

// export class VsiValue {
//   code: string;
//   value: number;
//   month: number;
//   year: number;
//   client: string;

//   vsiScore: number;

 

// }


export enum ContentFileTypes {
  eText,
  eImg,
  ePdf,
}

export class DiaryStatics {
  public static GENERAL_MEETING_PATIENT_ID_SUFFIX: string = 'GG_';
}

export class AggreementDTO {
  constructor(public FunctionalCode: string, public SignatureText: string) {}
}

export enum eStandardFunctionalContent {
  eTermsAndConditions,
  ePersonConsentPOPI,
  ePaymentPolicy,
  ePopiAct,
  eContactUs,
  eOtherContract1,
  eOtherContract2,
  eOtherContract3,
  eWhatsNew,
  eHelpContent,
  eWebPage,
}

export enum eWinIntegrationMessageType {
  eSetPatient,
  eSetArtifact,
  eClearPatient,
  eClearArtifact,
  eAdvancedDiary,
  eSickNote,
  eScript,
}

export class WinIntegrationMessage {
  public constructor(
    public eWinIntegrationMessageType: eWinIntegrationMessageType,
    public entityJson: string
  ) {}
}

export const TermsAndConditionsFunctionalCode: string =
  'stdfc.eTermsAndConditions';
export const PaymentPolicyFunctionalCode: string = 'stdfc.ePaymentPolicy';
export const PersonConsentPOPIFunctionalCode: string =
  'stdfc.ePersonConsentPOPI';
export const PopiActFunctionalCode: string = 'stdfc.ePopiAct';
export const OtherContract1FunctionalCode: string = 'stdfc.eOtherContract1';
export const OtherContract2FunctionalCode: string = 'stdfc.eOtherContract2';
export const OtherContract3FunctionalCode: string = 'stdfc.eOtherContract3';

export class UserSeletInfo {
  public entities: any[];
  public prompt: string;
  public preLoadData: AlchemedPreLoadData;
  public patient: Alchemint.Patient;
  public editObject: any = null;
  public makeSelectNonOptionAvailable: boolean = false;
  public allowMultiSelect: boolean = false;
  public qrCodeImageBase64: string;
  public qrCodeUrl: string;
  public dynamicProperties: object;

  public showOkOnly: boolean;
  public default: string = null;

  public fullPatientDetails: FullPatientDetails;

  public patientId: string;
  public allowEmailAddressEntry: boolean;

  public hideAdd: boolean;
  public isGeneralMeeting: boolean;
  public includePatientDetailsRequest: boolean;
  public includePatientSignContracts: boolean;
  public matIconForEntity: string; 

  public deriveDateOfBirthFromIdNumber: boolean;
  public hideCCInput: boolean;
  public hideAttachButton: boolean;
  public integratedMode: boolean;

  public yesIsGood: boolean;
  public allowPastingPatientImage: boolean = false;
  public mobileDevice: boolean = false;
}

export class AlchemedPreLoadData {
  public sites: Alchemint.Site[];
  public consultTypes: Alchemint.ConsultType[];
  public orgSettings: Alchemint.OrgSetting[];
  public logins: Alchemint.Login[];
  public organisations: Alchemint.Organisation[];
  public featureMaps: Alchemint.ArtifactToFeatureMap[];
  public shortCutKeys: Alchemint.CustomShortcutKeys[];
  public icD10Codes: Alchemint.ICD10Code[];
  public procedureCodes: Alchemint.ProcedureCode[];
  public diaryConfigs: Alchemint.DiaryConfig[];
  public contacts: Alchemint.Contact[];
  public noteItemTypes: Alchemint.NoteItemType[];
  public artifactTypes: Alchemint.ArtifactType[];
  public fieldCustomizers: FieldCustomizer[];
  public customizationErrors: string;
  public apiKeyDetails: Alchemint.ApiKeys;
  public materialCodes: Alchemint.MaterialCode[];
  public contactTypes: Alchemint.ContactType[];
  public standardStyleElements: Alchemint.CustomStyleElement[];
  public customStyleElement: Alchemint.CustomStyleElement[];
  public medicalSpecialities: Alchemint.MedicalSpeciality[];

  public diaryRefreshInterval: number;
  public integratedDiaryRefreshInterval: number;
  public medicalAidSchemes: MedicalAidScheme[]; 

  public userCustomConfigurations: Alchemint.UserCustomConfiguration[];
  public secondaryServerMode: boolean;
  public latestUserTsAndCsVersion: number;
  public newIHealthApiMode: boolean;
}

export enum eDiaryModes {
  Diary = 0,
  InPatients = 1,
  Tasks = 2,
  TheatreSlates = 3,
  Discharges = 4,
}

export enum eTaskGetStates {
  All,
  Complete,
  Incomplete,
}

export class UserTaskDetail {
  public id: string;
  public addedDate: Date;
  public assignedTo: string;
  public task: string;
  public detail: string;
  public completed: boolean;
  public completedDate: Date;
  public addedBy: string;
  public firstName: string;
  public lastName: string;
  public patientNo: string;
  public patientId: string;
  public userId: string;
  public priority: string;
  public reminder: boolean;
  public reminderDate: Date;
  public taskType: string;
  public parentTaskId: string;
}

export interface ISupportReadOnlyMode {
  ReadOnlyMode: boolean;
  // get  ReadOnlyMode () : boolean;
  // set ReadOnlyMode (value : boolean);
}

export type formInputhChangeExtender = (
  input: string,
  f: FormFieldDescriber,
  allFields: FormFieldDescriber[],
  entityForm: FormGroup
) => void;

export class customFormFieldError {
  constructor(public error: string) {}
}
export class FieldAlias {
  fieldName: string;
  alias: string;
  hide: boolean;
}
export class FieldCustomizer {
  forObjectType: string;
  fieldAliases: FieldAlias[];
}

export class ICDEvent {
  public dateTime: Date;
  public icdCode: string;
  public consultId: string;
}

export class ArtifactChangeReport {
  public artifactChangeLog: Alchemint.ArtifactChangeLog;
  public artifact: Alchemint.Artifact;
}

export class PatientHistory {
  public icdEvents: ICDEvent[];
  public consults: Alchemint.Consult[];
  public artifactChangeLogs: ArtifactChangeReport[];
  public agreeemtnHistory: any;
  public admissions: Alchemint.Admission[];

  public forms: string[];
  public medicalConditions: Alchemint.MedicalConditions;

  public completePatientDetails: Alchemint.CompletePatientDetails;
}

export class AccCustomerInvoice {
  constructor(
    public head: Alchemint.AccCustomerInvoiceHead,
    public lines: Alchemint.AccCustomerInvoiceLine[]
  ) {}
}

export class AccCustomerPaymentDTO {
  constructor(
    public accCustomerPayment: Alchemint.AccCustomerPayment,
    public paymentAllocations: Alchemint.AccCustomerPaymentAllocation[]
  ) {}
}

export class PaymentAmountAgainstInvoiceAllocatorDTO {
  constructor(
    public paymentId: string,
    public invoiceId: string,
    public allocatedAmount: number
  ) {}
}

export const PAYMENT_METHODS = ['CASH', 'CARD', 'EFT'];

export enum eAccPaymentType {
  Cash,
  Card,
  Eft,
}

export enum eAccInvoiceState {
  Draft,
  Approved,
  Quote,
}

export class FullPatientDetails {
  public patient: Alchemint.Patient;
  public biographicalDetails: Alchemint.BiographicalDetails;
  public contactDetails: Alchemint.ContactDetails;
  public personResponsibleForAccount: Alchemint.PersonResponsibleForAccount;
}

export class CustomListDTO {
  public customList: Alchemint.CustomList;
  public CustomListValues: Alchemint.CustomListValue[];
}

export class AlchemintDataRow {
  public values: string[];
}
export class AlchemintDataTable {
  public fieldNames: string[];
  public rows: BillingInstructionDTO[];
  public rows1: AlchemintDataRow[];
  public rawdata: any;
}

export class BillingInstructionDTO {
  public id: string;
  public patientId: string;
  public firstName: string;
  public lastName: string;
  public patientNo: string;
  public diagnosisCodes: string;
  public procedureCodes: string;
  public materialCodes: string;
  public originationId: string;
}

export class ArtifactWithData {
  constructor(public Artifact: Alchemint.Artifact, public TextData: string) {}
}

export class ArtifactWithFile {
  constructor(public Artifact: Alchemint.Artifact, public file: File) {}
}


export enum eHealthCheckItemType {
  CompanyLogo,
  Org,
  LetterHead,
  Smtp,
  ProcedureCodes,
  ICDCodes,
  MaterialCodes,
  Site,
  ConsultType,
  Folders,
  ShortCutKey,
  Contracts,
  Forms,
  UserSignature,
  DocXConversion,
  Users,
  PracticeManagers,
}
export class HealthCheckItem {
  public name: string;
  public isComplete: boolean;
  public eHealthCheckItemType: eHealthCheckItemType;
  // public eHealthCheckItemTypeEnum: eHealthCheckItemType;
  public count: number;
  public badRangeStart: number;
  public goodRangeStart: number;
  public incldeDrWill: boolean = false;
  public showDrWill: boolean = false;
}

export class HealthCheckItemExtended {
  public name: string;
  public isComplete: boolean;
  public eHealthCheckItemType: eHealthCheckItemType;
  public count: number;
  public badRangeStart: number;
  public goodRangeStart: number;
  public incldeDrWill: boolean = false;
  public showDrWill: boolean = false;
  public drWillText: string;
  public includeFileUpload: boolean = false;
  public includePasteText: boolean = false;
  public includeTable: boolean = false;
  public tableColumnNames: string;
  public drWillConfirmText: boolean;
  public userSelections: any;
  public setupDelegate: any;
  public preSetupDelegateAssertions: (HealthCheckItemExtended, any) => boolean;
  public includeConfirm: boolean = false;

  public suggestionsPromptSource: string;
  public suggestionsPromptSource2: string;
  public suggestionsPromptSource3: string;
  public suggestionsPromptSource4: string;

  public suggestionsPromptResults: string[];

  public suggestionsPromptVariables: [string, string][];
  public emitSuggestions: EventEmitter<string[]>;

  public busy: boolean = false;
  public fetchArtifactFromLibrary: boolean;
  public fetchArtifactFromLibraryFunctionalCode: string;
  public hidden: boolean = false;

  public itemComplete = new EventEmitter<number>();
}

export class HealthCheckItemExtendedHelpers {
  public static fetchSuggestions(
    webApi: WebApiInterfaceService,
    hc: HealthCheckItemExtended
  ): void {
    if (!hc.suggestionsPromptVariables) {
      return;
    }

    hc.busy = true;
    var prompt = hc.suggestionsPromptSource;

    if (hc.suggestionsPromptSource2) {
      prompt += ';' + hc.suggestionsPromptSource2;
    }

    if (hc.suggestionsPromptSource3) {
      prompt += ';' + hc.suggestionsPromptSource3;
    }

    if (hc.suggestionsPromptSource4) {
      prompt += ';' + hc.suggestionsPromptSource4;
    }

    if (hc.suggestionsPromptVariables) {
      // hc.suggestionsPromptVariables.forEach(v => {
      // 	prompt = HelperFunctions.replaceAllTextOccurrencesWith(prompt,v[0], v[1]);
      // });
    }

    var sub = webApi
      .querychatgptgenerallywithvariables(
        prompt,
        hc.eHealthCheckItemType,
        hc.suggestionsPromptVariables,
        true
      )
      .subscribe(
        (res) => {
          if (res) {
            //hc.suggestionsPromptResults = res.split(',');
            try {
              hc.suggestionsPromptResults = [];
              var results = res.split(';');
              results.forEach((r) => {
                var arr1: string[] = JSON.parse(r);
                if (arr1) {
                  arr1.forEach((element) => {
                    hc.suggestionsPromptResults.push(element);
                  });
                }
              });

              hc.suggestionsPromptResults = [
                ...new Set(hc.suggestionsPromptResults),
              ];
              hc.suggestionsPromptResults = hc.suggestionsPromptResults.sort(
                (a, b) => (a > b ? 1 : -1)
              );
              hc.emitSuggestions?.emit(hc.suggestionsPromptResults);
            } catch (error) {
              console.log(error);
              console.log('Invalid json suggestions');
              console.log(res);
              alert(error);
            }
          }
        },
        (err) => {
          alert(err?.status);
        },
        () => {
          hc.busy = false;
          sub.unsubscribe();
        }
      );
  }
}

export enum TemplateDocumentFeatureCode {
  ClinicalNotes,
  Letter,
  CompanyLogo,
  EmailSignature
}

export class PreparedEmailDTO {
  public subject: string;
  public emailBody: string;
  public to: string;
  public cC: string;
  public externalRequestIds: string;
}

export class ArtifactWithThumbnail {
  constructor(
    public hasThumbnail: boolean,
    public artifact: Alchemint.Artifact,
    public artifactThumbnailBase64: string
  ) {}
}

export class ArtiFormInstanceWithArtifactPreview {
  constructor(
    public hasPreviewFile: boolean,
    public artiformInstance: Alchemint.ArtiFormInstance,
    public artifact: Alchemint.Artifact
  ) {}
}

export class ArtiFormFormRequestDetails {
  constructor(
    public artiForm: Alchemint.ArtiForm,
    public consult: Alchemint.Consult
  ) {}
}

export class StringDTO {
  constructor(public text: string, public modelVersion: string) {}

  public useScaleForHowQuestions: boolean = false;
  public scaleForHowQuestionsMin: number = 1;
  public scaleForHowQuestionsMax: number = 10;
  public includeLabels: boolean = false;
  public extraInstructions: string;
  public overrideFieldType: string;
  public useRangeRadiosForNumbers: boolean;
  public customizedGenerationPrompt: string;
}

export class SnapshotDetails {
  public id: string;
  public name: string;
  public comments: string;
  public dateTime: Date;
  public totalSizeBytes: number;
}

export class WhisperDTO {
  public text: string;
}

export class AddOnServiceDTO {
  public serviceName: string;
}

export class TextDTO {
  public text: string;
}

export class HelpVideoDTO {
  public fileId: string;
  public name: string;
  public description: string;
  public positionInList: number;
}

export class RequestFormForConsultDetails {
  constructor(
    public consult: Alchemint.Consult,
    public includeBillingForms: boolean,
    public includeNonBillingForms: boolean
  ) {}
}


export interface IReturnValue
{
  get value() : any;
  set value(val : any);
}

export interface IGlowWhenBranchActivated
{
  glow : boolean;
}

export class EmailDetails
{
  subject: string;
  content: string;
  recipients: string[];
  numberOfAttachments: number;
  attachmentNames: string[];
  isHtmlEmail: boolean;
  emailFileFormat: string;
}


export class  PatientFromScanStickerDTO 
{
    existed: boolean;
    completePatientDetails: Alchemint.CompletePatientDetails;
}


export class MedicalAidScheme {
  scheme: string;
  plan: string;
  optionCode: string;

  constructor(scheme: string, plan: string, optionCode: string) {
    this.scheme = scheme;
    this.plan = plan;
    this.optionCode = optionCode;
  }
}


export class ArtifactSyncDTO
{
    artifactId: string;
    sha256: string;
    usedfilename: string;
    isMainDBFile: boolean;
   
}


export class QrCodeDTO
{
    qrCodeImageData: ArrayBuffer;
    qrCodeUrl: string;
}

export class HistoricalArtifactDTO
{
    artifacts: Alchemint.Artifact []
    artifactChangeLogs: Alchemint.ArtifactChangeLog [];
    artifactChangeLogPerson: ArtifactChangeLogPerson[]; 
}

export class ArtifactChangeLogPerson
{
    artifactChangeLogId: string;
    userName: string;
}

export class iHealthVenuesDTO
{
    public name: string;
}

export class iHealthVenuesByLoginIdDTO
{
    public loginId: string;
    public venues: string[];
}

export class iHealthAppointmentTypesDTO
{
    public id: string;
    public name: string;
    public duration: string;
    public relatedBillingCodes: string[];
    public diagnosis:  iHealthDiagnosis[]; 
}

export class iHealthDiagnosis
{
    public code: string;
    public description: string;
}


export class iHealthPracticePractitionerDTONew
{
  public id: string;
  public name: string;
  public practiceNumber: string;
}

export class UIConfig
{
  public theatreSlate: boolean;
  public clinicalNotes: boolean;
  public pathology : boolean;
  public radiology : boolean;
  public scripts : boolean;
  public sickNotes : boolean;
  public inPatients : boolean;
  public tasks : boolean;
  public search : boolean;
  public more : boolean;
  public modules : boolean;
  public configuration : boolean;

}

export class EmailResultDTO
{
    public success: boolean;
    public failureMessage: string;
}

export  class CustomListExportDTO
{
    public customList: Alchemint.CustomList;
    public customListValue: Alchemint.CustomListValue [];
}

export class InitialSupportTicketDTO
{
    public supportTicket : Alchemint.SupportTicket;
    public supportTicketChatItem: Alchemint.SupportTicketChatItem;
    public orgSupportTicket : Alchemint.OrgSupportTicket;
}

export class ArtifactAndIsNewDTO
{
    public artifact: Alchemint.Artifact;
    public isNew: boolean;
}

export class ApiKeyWithMasterPassword {
  apiKey: Alchemint.ApiKeys;
  apiKeyMasterPassword: ApiKeyMasterPassword;
}

export class ApiKeyMasterPassword {
  apiKey: string;
  masterPassword: string;

}

export class AngularComponent {
  
  
  public name: string;
  
  public htmlTemplateFile: string;
  public scssFile: string;
  public componentFile: string;
  public moduleFile: string;
  public serviceFile: string;



  public componentFileName: string;
  public htmlTemplateFileName: string;
  public scssFileName: string;
  public serviceFileName: string;

}

export class DataModelInfo {
  public typeName: string;
  public fields: FieldInfo[];
}

export class FieldInfo {
  public fieldName: string;
}

export class PatientSignedAgreementsDTO {
  public id: string;
  public patientId: string;
  public agreeDate: Date | null;
  public agreeementArtifactId: string;
  public agreeementArtifactSha256: string;
  public standardFunctionalContentId: string;
  public signatureString: string;
}

export class HelpFileContent
{
    public content: string;
}
