import { Injectable } from "@angular/core";
import { UserAlchemint } from "./_models/user";
import { AuthenticationService } from "./_services";

@Injectable({ providedIn: 'root' })

export class ActivityTracker {

  private readonly localStorageKey = 'CLICKS';

  private activeUser: UserAlchemint;

  constructor(private authSerivce: AuthenticationService) {
    this.authSerivce.user.subscribe({
      next: (user) => {
        this.activeUser = user;
      }
    })
  }

  public track(clickedItemId: string, trackCategory?: string): void {
    const clickData = {
      id: clickedItemId,
      time: new Date(),
      category: trackCategory || '',
      userId: this.activeUser?.id
    };

    // Get previously saved clicks from local storage
    const savedClicks = this.getSavedClicks();

    // Add the new click to the saved clicks
    savedClicks.push(clickData);

    // Save the updated clicks to local storage
    localStorage.setItem(this.localStorageKey, JSON.stringify(savedClicks));

  }

  private getSavedClicks(): Array<any> {
    const savedClicksString = localStorage.getItem(this.localStorageKey);
    if (savedClicksString) {
      return JSON.parse(savedClicksString);
    } else {
      return [];
    }
  }
}
