<!-- 


<div class="notification-container" (click)="toggleNotifications()">

    {{messages?.length}}
    <div class="bell-icon">
        <i class="fa fa-bell">XXXXC</i>
        <span *ngIf="newNotificationsCount > 0" class="notification-count">{{ newNotificationsCount }}</span>
    </div>
    <div *ngIf="showNotifications" class="notifications-dropdown">
        <ul>
            
            <li *ngFor="let message of messages" >
                <div class="notification-title">{{ message }}</div>
            
            </li>
        </ul>
    </div>
</div> -->
<!-- (click)="toggleNotifications()" -->

<!-- <div *ngIf="disconnected===true">
    Disconnect
</div> -->
<div *ngIf="enabled===true">
    <div class="notification-container"  [hidden]="newNotificationsCount==0">
        <div class="bell-icon">
            <i class="fa fa-bell"></i>
            <span class="notification-count">{{ newNotificationsCount }}</span>
        </div>
        <div [hidden]="showNotifications==false" class="notifications-dropdown">
            <ul>
                <li *ngFor="let message of messages" >
                    <div class="notification-title">{{ message }}</div>
                    <div  class="notification-message">{{ message }}</div> 
                </li>
            </ul>
        </div>
    </div>
    
</div>
