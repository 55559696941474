import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AlphaFeatureDirective,
  BetaFeatureDirective,
} from '../beta-feature.directive';
import { HideIfUsingIntegratedBillingDirective } from '../hide-if-using-integrated-billing.directive';
import { NonMobileFeatureDirective } from '../non-mobile-feature.directive';
import { ValidationMessageDirective } from '../validation-message-directive';
import { ZoomableDirective } from '../zoomable.directive';
import { PatientSafeShowEntityDirective } from '../patient-safe-show-entity-directive';
import { BusyDirective, SpinnerDirective } from '../app-spinner.directive';
import { SpeechBalloonDirective } from '../speech-balloon.directive';
import { InlineStylingDirective } from '../inline-styling-directive';
import { FadeInDirective } from '../FadeInDirective ';
import { StoreColumnSizesDirective } from '../store-column-sizes-directive';
import { TranscribeDirective } from '../transcribe.directive';
import {
  DragOverHighlightDirective,
  DragParentHighlighChildrenDropDirective,
} from '../drag-over-highlight-directive ';
import { NavigateBackOnDoneDirective } from '../navigate-back-on-done.directive';
import { DoneButtonDirective } from '../done-button.directive';
import { ControlIdDirective } from '../control-id-directive';
import { ClickTrackerDirective } from '@app/directives/activity-tracker.directive';
import { MaximizeAndMinimizeDirective } from '@app/appdirectives/maximize-and-minimize.directive';
import { BlurElementDirective } from '../blur-element-directive';
import { LandscapeDirective } from '../landscape-directive';
import { StepHighlightDirective } from '../step-highlight-directive';
import { ImageOverlayDirective } from '../image-overlay-directive';
import { GuidedTourService } from '@app/_services/guided-tour.service';
import { MovableWindowDirective } from '../moveable-window.directive';
import { DetachAttachDirective } from '../detach-attach-directive';
import { ImageCropperDirective } from '../image-cropper.directive';
import { ImageDrawerDirective } from '../image-drawer.directive';
import { DisableButtonForGuidedTourDirective } from '../disable-button-forguidedtour.directive';
import { MobileOnlyDirective } from '../mobile-only.directive';
import { HideOnElementWidthDirective } from '../hide-on-width.directive';
import { DebounceClickDirective } from '../debounce-click.directive';
import { TagSelectorDirective } from '../tag-selector.directive';
import { SideToolsDirective } from '../side-tools.directive';
import { PhoneNumberDirective } from '../phone-number.directive';
import { MedicalLensDirective } from '../medical-lens.directive';
import { ColumnResizerDirective } from '../resizable-columns-directive';
import { FloatingWhenOutOfViewDirective } from '../floating-when-out-of-view.directive';
import { FloatingContainerService } from '@app/_services/floating-container.service';



@NgModule({
  declarations: [
    BetaFeatureDirective,
    NonMobileFeatureDirective,
    AlphaFeatureDirective,
    HideIfUsingIntegratedBillingDirective,
    ValidationMessageDirective,
    ZoomableDirective,
    PatientSafeShowEntityDirective,
    SpinnerDirective,
    BusyDirective,
    SpeechBalloonDirective,
    InlineStylingDirective,
    FadeInDirective,
    StoreColumnSizesDirective,
    TranscribeDirective,
    DragOverHighlightDirective,
    DragParentHighlighChildrenDropDirective,
    NavigateBackOnDoneDirective,
    DoneButtonDirective,
    ControlIdDirective,
    ClickTrackerDirective,
    MaximizeAndMinimizeDirective,
    BlurElementDirective,
    LandscapeDirective, 
    StepHighlightDirective, 
    ImageOverlayDirective, 
   	MovableWindowDirective,
     DetachAttachDirective, 
     ImageCropperDirective, 
     ImageDrawerDirective, 
     DisableButtonForGuidedTourDirective, 
     MobileOnlyDirective, 
     HideOnElementWidthDirective, 
     DebounceClickDirective, 
     TagSelectorDirective, 
     SideToolsDirective,
     PhoneNumberDirective,
     MedicalLensDirective, 
     ColumnResizerDirective,
     FloatingWhenOutOfViewDirective

  ],
  imports: [CommonModule],
  exports: [
    BetaFeatureDirective,
    NonMobileFeatureDirective,
    AlphaFeatureDirective,
    HideIfUsingIntegratedBillingDirective,
    ValidationMessageDirective,
    ZoomableDirective,
    PatientSafeShowEntityDirective,
    SpinnerDirective,
    BusyDirective,
    SpeechBalloonDirective,
    InlineStylingDirective,
    FadeInDirective,
    StoreColumnSizesDirective,
    TranscribeDirective,
    DragOverHighlightDirective,
    DragParentHighlighChildrenDropDirective,
    NavigateBackOnDoneDirective,
    DoneButtonDirective,
    ControlIdDirective,
    ClickTrackerDirective,
    MaximizeAndMinimizeDirective,
    BlurElementDirective, 
    LandscapeDirective, 
    StepHighlightDirective,
    ImageOverlayDirective,
    MovableWindowDirective,
    DetachAttachDirective, 
    ImageCropperDirective,
    ImageDrawerDirective, 
    DisableButtonForGuidedTourDirective, 
    MobileOnlyDirective, 
    HideOnElementWidthDirective, 
    DebounceClickDirective, 
    TagSelectorDirective,
    SideToolsDirective, 
    PhoneNumberDirective,
    MedicalLensDirective, 
    ColumnResizerDirective,
    FloatingWhenOutOfViewDirective

  ],
  providers: [
    GuidedTourService, // Provide the service here,
    FloatingContainerService
  ]
})
export class SharedDirectivesModule {}
