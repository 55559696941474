import { Component, ContentChildren, ElementRef, OnInit, QueryList } from '@angular/core';
import { PopupMenuItemComponent } from '../popup-menu-item/popup-menu-item.component';

@Component({
  selector: 'app-popup-menu',
  templateUrl: './popup-menu.component.html',
  styleUrls: ['./popup-menu.component.scss']
})
export class PopupMenuComponent implements OnInit {

  @ContentChildren( PopupMenuItemComponent, {descendants: true}) contentChildren!: QueryList<PopupMenuItemComponent>;
  public showPopup = false;

  constructor(private elementRef: ElementRef) { }

  ngOnInit(): void {
  }

  ngAfterContentInit() {
   }

}
