import { Component, OnInit, Output, EventEmitter, AfterViewInit, OnDestroy, Input } from '@angular/core';
import { Artifact } from '@app/_alchemint/alchemint_dm';
import { ApiInterfaceService } from '@app/_services/alchemint.apiinterface.service';

import { MAT_DIALOG_DATA, MatDialogClose, MatDialogContent, MatDialogActions, MatDialogRef } from '@angular/material/dialog';
import { ImageManipulationService } from '@app/_services/image-manipulation.service';

import { UtilityService } from '@app/utility.service';
// import { WebcamImage } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import { WebcamImage, WebcamUtil } from 'ngx-webcam';
import { timeStamp } from 'console';
import { IHideable } from '@app/_alchemint/alchemint-interfaces';
import { OrganizationServiceService } from '@app/_services/organization-service.service';
import { SettingsService } from '@app/_services/settings-service.service';

// import { WebcamComponent } from 'ngx-webcam';

@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.scss']
})
export class CameraComponent implements OnInit, IHideable {
  @Output() uploadedFile = new EventEmitter<Artifact>();
  @Output() errorEvent = new EventEmitter<string>();
  @Output() cameraCancelled = new EventEmitter();
  @Output() cameraReady = new EventEmitter();
  @Output() cameraSnapped = new EventEmitter();

  @Output() imageReady = new EventEmitter<ImageData>();

  showSettings: boolean = false;
  _visible: boolean = false;
  artifact: Artifact;
  videoVisible: boolean = false;
  loading: boolean = false;
  initErrMessage: string;

  errorOnVideoInit: boolean = false;
  photoSize: number;

  cameraConnected: boolean = false;

  emitImageMode: boolean = false;
  public cameras: MediaDeviceInfo[];
  public selectedCamera: MediaDeviceInfo;
  public webcamImage: WebcamImage = null;
  private trigger: Subject<void> = new Subject<void>();

  // private settingsService: SettingsService
  constructor(
    private apiInterfaceService: ApiInterfaceService, 
    private imageManipulationService: ImageManipulationService, 
    private organizationServiceService: OrganizationServiceService, 
    private utilityService: UtilityService, 
    private settingsService: SettingsService) {


    this.initializeDefaultSettings();

    // // Fetch available cameras
    // WebcamUtil.getAvailableVideoInputs().then((mediaDevices: MediaDeviceInfo[]) => {
    //   this.cameras = mediaDevices;

    //   // Set the camera from localStorage or default
    //   const savedCameraId = localStorage.getItem('selectedCameraId');
    //   if (savedCameraId) {
    //     this.selectedCamera = this.cameras.find(c => c.deviceId === savedCameraId);
    //   } else if (this.cameras.length > 0) {
    //     this.selectedCamera = this.cameras[0];
    //   }
    // });


// Fetch available cameras
WebcamUtil.getAvailableVideoInputs().then((mediaDevices: MediaDeviceInfo[]) => {
  this.cameras = mediaDevices;

  // Set the camera from localStorage or default
  const savedCameraId = localStorage.getItem('selectedCameraId');

  this.defaultCameraPosition = localStorage.getItem('defaultCameraPosition') || 'back';
  if (savedCameraId) {
    this.selectedCamera = this.cameras.find(c => c.deviceId === savedCameraId);
  } else if (this.cameras.length > 1) {
    // Default to the second camera if available (assuming it's the rear camera)
    this.selectedCamera = this.cameras[1];
  } else if (this.cameras.length > 0) {
    // Fallback to the first camera if only one camera is available
    this.selectedCamera = this.cameras[0];
  }
});


    // this.selectedImageQuality = this.availableResolutions[0];

    // WebcamUtil.getAvailableVideoInputs()
    //   .then((mediaDevices: MediaDeviceInfo[]) => {
    //     this.cameras = mediaDevices;
    //     if (this.cameras && this.cameras.length > 0) {
    //       this.selectedCamera = this.cameras[0];
    //     }
    //   });
  }


  ngOnInit(): void {
  }

  triggerSnapshot(): void {
    this.videoVisible = false;
    this.trigger.next();
  }
  handleImage(webcamImage: WebcamImage): void {
    //console.info('Saved webcam image', webcamImage);
    //this.webcamImage = webcamImage;
    var imageData: ImageData = webcamImage.imageData;


    if (this.emitImageMode === true) {
      this.imageReady?.emit(imageData);
    }
    else {
      this.imageManipulationService.ImageDataToBlob(imageData).then(
        blob =>
          this.cameraImageUpload(<Blob>blob)
      );
    }

  }


  public cameraSettingsContainerStyle: string = null;


  // Define webcam configuration
  public webcamConfig = {
    constraints: {
      width: { ideal: 1920 },
      height: { ideal: 1080 }
    }
  };
  cameraImageUpload(data: Blob) {
    this.cameraConnected = false;

    this.loading = true;
    const file = this.imageManipulationService.blobToFile2(data, this.artifact?.name);
    this.photoSize = data.size;
    this.cameraSnapped?.emit();
    this.utilityService.requestNormalScreen();
    this.imageManipulationService.uploadFileWithResizeAttempts(file, this.artifact.patientId, this.artifact.artifactTypeId);
    this.loading = false;

  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }
  showWebcam: boolean = true;
  // Method to change the camera
  public changeCamera(camera: MediaDeviceInfo): void {
    this.selectedCamera = camera;
    // Refresh the webcam component
    localStorage.setItem('selectedCameraId', camera.deviceId);
    this.showWebcam = false;
    setTimeout(() => this.showWebcam = true, 100);
  }

  public changeResolution(resolution: ImageResolution): void {
    this.selectedImageQuality = resolution;
    localStorage.setItem('selectedImageQuality', `${resolution.width}x${resolution.height}`);
    this.showWebcam = false;
    setTimeout(() => this.showWebcam = true, 100);
    // ... any additional logic for resolution change ...
  }

  public defaultCameraPosition = 'back';
  public setDefaultCameraPosition(position: string): void {
    this.defaultCameraPosition = position;
    localStorage.setItem('defaultCameraPosition', position);
    if (position === 'front') {
      this.selectedCamera = this.cameras.find(camera => camera.label.toLowerCase().includes('front')) || this.cameras[0];
    } else if (position === 'back') {
      this.selectedCamera = this.cameras.find(camera => camera.label.toLowerCase().includes('back')) || this.cameras[0];
    }
  }

  private initializeDefaultSettings() {
    // Set default or saved resolution
    const savedResolution = localStorage.getItem('selectedImageQuality');

    const HIGH_DEF_INDEX = 3;

    if (savedResolution) {
      const [width, height] = savedResolution.split('x').map(Number);
      // Find the matching resolution in the availableResolutions array
      const matchingResolution = this.availableResolutions.find(r => r.width === width && r.height === height);
      if (matchingResolution) {
        this.selectedImageQuality = matchingResolution;
      } else {
        // Fallback to default if no matching resolution is found
        this.selectedImageQuality = this.availableResolutions[HIGH_DEF_INDEX];
      }
    } else {
      // Default to 720p HD
      this.selectedImageQuality = this.availableResolutions[HIGH_DEF_INDEX];
    }
  }



  public get blurOn(): boolean {
    return this.settingsService.useBlurMode;
  }

  @Input()
  public set visible(value: boolean) {
    this._visible = value;
  }

  public get visible(): boolean {
    return this._visible;
  }

  public promptPhoto(artifact: Artifact, fullScreenMode: boolean = true) {
    try {
      this.cameraConnected = true;
      this.artifact = artifact;
      this.visible = true;
      //this.takePhoto();

      this.cameraReady?.emit();
      window.scrollTo(0, 0);
      this.utilityService.requestCameraScreen(fullScreenMode);
      this.videoVisible = true;
    }
    catch (error) {
      alert(error);
      this.errorOnVideoInit = true;
      this.initErrMessage = error.toString();
      this.utilityService.requestNormalScreen();
      this.cameraCancelled?.emit();
      this.cameraConnected = false;
    }

  }


  cancel(): void {
    this.visible = false;
    this.cameraConnected = false;
    //this.releaseCamera();
    this.cameraCancelled?.emit();
    this.utilityService.requestNormalScreen();
  }



  // public set visible (value : boolean)
  // {
  //   this._visible = value;
  //   if (value == false)
  //   {
  //     try {
  //       this.releaseCamera();
  //     } catch (error) {
  //       console.log("Error trying to release camera");
  //       console.error(error);
  //     }
  //   }
  // }


  // private player : HTMLVideoElement;
  // private localstream : any;

  // takePhoto () : void 
  // {

  //   if (this.player)
  //   {
  //     this.releaseCamera();
  //   }

  //   const supported = 'mediaDevices' in navigator;
  //   this.player =  this.getCameraVideoElement; //<HTMLVideoElement> document.getElementById('cameraPreviewPlayer');
  //   const canvas = <HTMLCanvasElement> document.getElementById('canvas');
  //   // const context = (<HTMLCanvasElement> canvas).getContext('2d');
  //   // const captureButton = document.getElementById('capture');

  //   this.errorOnVideoInit = false;
  //   this.initErrMessage = null;
  //   this.player.hidden = false;
  //   this.videoVisible = true;

  //   try
  //   {
  //     var constraints = {
  //       audio: false,
  //       video: {

  //         // width: {
  //         //       min: 320,
  //         //       max: 1280
  //         //   },
  //         //   height: {
  //         //       min: 240,
  //         //       max: 720
  //         //   }, 

  //           width: { min: 640, max: 5000 },
  //           height: { min: 400, max:5000  },

  //           facingMode: 'environment'

  //       }
  //   };

  //     navigator.mediaDevices.getUserMedia(constraints)
  //     .then((stream) => {

  //       try {
  //         this.localstream = stream;

  //         // const tracks = this.localstream.getTracks();

  //         // var i : number = 0;
  //         // if (tracks?.length > 0)
  //         // {
  //         //   (<MediaStreamTrack>tracks[i]).applyConstraints
  //         //   i++;
  //         // }

  //         this.player.srcObject = stream;  
  //         this.player?.setAttribute("playsinline", "true");
  //         this.player?.setAttribute("autoplay", "true");

  //       } catch (error) {
  //         this.utilityService?.requestNormalScreen();

  //         alert(error);
  //         this.cameraCancelled?.emit();
  //       }


  //       this.errorOnVideoInit = false;
  //     }).catch ((err) => 
  //       {
  //         this.utilityService?.requestNormalScreen();

  //         this.errorOnVideoInit = true;
  //         this.initErrMessage = err;
  //         this.cameraCancelled?.emit();
  //         //this.errorEvent?.emit(err);
  //         // alert(err);
  //       }
  //     );
  //   }
  //   catch(e)
  //   {
  //     this.utilityService?.requestNormalScreen();
  //     this.errorOnVideoInit = true;
  //     this.initErrMessage = `There was a problem accessing the camera. If you are running Alchemed as a home screen installed app on iOS on older versions the camera may not work. The error was [${e.toString()}]`;
  //   }

  // }

  // public get getCameraHeight () : number 
  // {
  //   return this.getCameraVideoElement?.videoHeight;;
  // }

  // public get getCameraWidth () : number 
  // {
  //   return this.getCameraVideoElement?.videoWidth;;
  // }

  // private get getCameraVideoElement () : HTMLVideoElement
  // {
  //   return <HTMLVideoElement>  document.getElementById('cameraPreviewPlayer');
  // }

  // takePic ()
  // {
  //   const player : HTMLVideoElement = this.getCameraVideoElement;

  //   const canvas = <HTMLCanvasElement> document.getElementById('canvas');
  //   const context = (<HTMLCanvasElement> canvas).getContext('2d');
  //   const captureButton = document.getElementById('capture');


  //   context.drawImage(player, 0, 0, this.getCameraWidth, this.getCameraHeight);
  //   //context.drawImage(player, 0, 0, canvas.width, canvas.height);
  //   //context.drawImage(player, 0, 0, 10000, 0);
  //   //context.drawImage(player, 0, 0, 10000, 10000 * player.height / player.width)

  //   canvas.toBlob(
  //     bl => {this.cameraImageUpload(bl)} , 
  //     "image/png", 1);

  //   this.videoVisible = false;
  //   player.hidden = true;

  // }


  convertBlobToBase64 = async (blob) => {
    return await this.blobToBase64(blob);
  }

  blobToBase64 = blob => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  private setError(error: any): void {
    this.errorEvent?.emit(error);
  }

  selectedImageQuality: ImageResolution;
  availableResolutions: ImageResolution[] = [
    new ImageResolution(640, 480),   // 480p SD (NTSC Standard)
    new ImageResolution(720, 576),   // 576p SD (PAL Standard)
    new ImageResolution(960, 540),   // 540p MD
    new ImageResolution(1280, 720), // 720p HD
    new ImageResolution(1920, 1080), // 1080p Full HD
    new ImageResolution(2560, 1440), // 1440p Quad HD
    new ImageResolution(3840, 2160), // 2160p 4K Ultra HD

  ];


  getResolutionName(res: ImageResolution): string {
    switch (`${res.width}x${res.height}`) {
      case '1280x720': return 'HD';
      case '1920x1080': return 'Full HD';
      case '2560x1440': return 'Quad HD';
      case '3840x2160': return '4K Ultra HD';
      case '640x480': return 'SD NTSC';
      case '720x576': return 'SD PAL';
      case '960x540': return 'MD';
      default: return '';
    }
  }

  //  releaseCamera() : void 
  //  {
  //   try {

  //     if (this.player)
  //     {
  //       this.player.pause();
  //       this.player.src = "";
  //       this.player.srcObject = null;
  //       this.player = null;
  //     }

  //     if (this.localstream)
  //     {
  //       const tracks = this.localstream.getTracks();

  //       var i : number = 0;
  //       if (tracks?.length > 0)
  //       {
  //         tracks[i].stop;
  //         i++;
  //       }

  //       this.localstream = null;
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }



  // ngOnDestroy(): void {
  //   this.releaseCamera();
  // }

}




export class ImageResolution {
  constructor(public width: number, public height: number) { }
}



