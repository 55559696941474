<section class="container alc_update_div" style="background-color: rgb(237, 237, 237);">
    <div style="display: flex; flex-direction: column;">
        <div style="display: flex; flex-direction: column;">
            <p style="color: darkgray; margin: 2px 10px;">Bookings</p>
        </div>
        <div class="card-widget">
            <div class="card-title"></div>
            <div class="card-content">
                <div class="content-top">TODAY</div>
                <div class="content-bottom">#17</div>
            </div>
            <div class="card-widget-footer">
                <div class="btn-round" mat-icon-button>
                    <app-svg color="primary" style="font-size: 11pt;  font-weight: 600; "> open_in_new</app-svg>
                </div>
            </div>
        </div>
        <div class="card-widget">
            <div class="card-title"></div>
            <div class="card-content">
                <div class="content-top">UPCOMING</div>
                <div class="content-bottom">#108</div>
            </div>
            <div class="card-widget-footer">
                <div class="btn-round" mat-icon-button>
                    <app-svg color="primary" style="font-size: 11pt;  font-weight: 600; "> open_in_new</app-svg>
                </div>
            </div>
        </div>
    </div>
    <div style="display: flex; flex-direction: column;">
        <div style="display: flex; flex-direction: column;">
            <p style="color: darkgray; margin: 2px 10px;">Other</p>
        </div>
        <div style="display: flex;">
            <div class="card-widget">
                <div class="card-title"></div>
                <div class="card-content">
                    <div class="content-top">CONSULTS</div>
                    <div class="content-bottom">#25</div>
                </div>
                <div class="card-widget-footer">
                    Year today <span style="padding: 0 2px;"> <strong>101</strong> </span>
                </div>
            </div>

            <div class="card-widget">
                <div class="card-title"></div>
                <div class="card-content">
                    <div class="content-top">INVOICES</div>
                    <div class="content-bottom">#25</div>
                </div>
                <div class="card-widget-footer">
                    Year today <span style="padding: 0 2px;"> <strong>{{1915 |number}}</strong> </span>
                </div>
            </div>

            <div class="card-widget">
                <div class="card-title"></div>
                <div class="card-content">
                    <div class="content-top">CREDIT NOTES</div>
                    <div class="content-bottom">#4</div>
                </div>
                <div class="card-widget-footer">
                    Year today <span style="padding: 0 2px;"> <strong>45</strong> </span>
                </div>
            </div>
        </div>
       <div class="table-container">
        <div style="display: flex; flex-direction: column;">
            <p style="color: darkgray; margin: 2px 10px;">TOP 10 DEBTORS BY VALUE </p>
        </div>
        <table class="xtable">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Value</th>
                    <th> Days</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>MR. A</td>
                    <td>{{1915 |number}}</td>
                    <td>15</td>
                </tr>
                <tr>
                    <td>MR. BEE</td>
                    <td>{{900 |number}}</td>
                    <td>22</td>
                </tr>
                <tr>
                    <td>MR. Cyan</td>
                    <td>{{2500 |number}}</td>
                    <td>3</td>
                </tr>
            </tbody>
        </table>
       </div>
    </div>


    
    
</section>