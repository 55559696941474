import { Injectable } from '@angular/core';



@Injectable()
export class DateTimeService {
  
  
  constructor() 
  { }

//   public formatDateToSATimeZone(date: Date): string {
//     // Convert the input UTC date string to a Date object

//     // Use Intl.DateTimeFormat to format the date to South Africa time zone
//     const formatter = new Intl.DateTimeFormat('en-ZA', {
//         timeZone: 'Africa/Johannesburg',
//         year: 'numeric',
//         month: '2-digit',
//         day: '2-digit',
//         hour: '2-digit',
//         minute: '2-digit',
//         second: '2-digit'
//     });

//     var ret = formatter.format(date);
//     return ret;
// }



public convertUTCDateToLocalDate(utcDate: Date): string {

  if (utcDate)
  {

    var utcDate2  = new Date(utcDate);

    // Get the local time offset in milliseconds
    let timeOffset: number = utcDate2.getTimezoneOffset() * 60000;
    
    // Create a new Date object adjusted to the local time
    let localDate: Date = new Date(utcDate2.getTime() - timeOffset);
    
    // Convert to a local date string
    let localDateString: string = localDate.toLocaleString();
    
    return localDateString;

  }
  else
  {
    return '';
  }
  
  
}

}
