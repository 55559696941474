import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiInterfaceService } from '@app/_services/alchemint.apiinterface.service';
import { AlchemintSharedService } from '@app/alchemint-shared.service';
import { UiErrorHelper } from '@app/_helpers/uierror.helper';
import { Console } from 'console';

@Component({
  selector: 'app-error-component',
  templateUrl: './error-component.component.html',
  styleUrls: ['./error-component.component.scss']
})
export class ErrorComponentComponent implements OnInit {

  @ViewChild('softErrorBoxLabel') softErrorBoxLabel;

  private defaultErrorTitle : string = "An error occurred. Please contact Alchemed for assistance.";
  

  public useSnackBarErrors : boolean = true;
  error : string = null;
  inErrorState : boolean = false;

  //errorTitle : string = 
  errorTitle : string = "An error occurred.";
  isSoftUserErrorMessage : boolean = false;

  isOnline : boolean = true;
  apiServer : string;
  showDismissButton : boolean = true;

  public scrollToTopOnError : boolean = false;

  constructor(private apiInterfaceService : ApiInterfaceService, private alchemintSharedService : AlchemintSharedService) { }

  ngOnInit(): void {
  
    this.apiServer = this.apiInterfaceService?.apiUri;
  }
  
  

  public SetErrorDetails (
    errorMessage : any, 
    errorTitle : string, 
    softUserErrorMessage : boolean = false, 
    showDismissButton : boolean = true, 
    useMessageBox: boolean = false) 
  {

    this.isSoftUserErrorMessage = softUserErrorMessage;
    this.showDismissButton = showDismissButton;
    
    var errorDetails = UiErrorHelper.buildErrror(errorMessage, errorTitle);

    this.error = errorDetails.message;
    this.errorTitle = errorDetails.title;
    console.warn(this.error);
    var divErrorBox = document.getElementById("errorBox");
    var divSoftErrrorBox = document.getElementById("softErrorBox");
    this.inErrorState = true;    
    
    if (softUserErrorMessage == false)
    {
      try 
      {
        this.CheckReachServer();
      }
      catch (e)
      {
        console.warn(e);
      }
    }

    if (errorDetails.authError === true)
    {
      // AUth errors would have been redirected to logon so just show in console
      console.log(errorMessage);
    }
    else if (this.useSnackBarErrors === true)
    {
      if (useMessageBox === true)
      {
        this.alchemintSharedService.oppenErrDialogForObjectErr(this.error);
      }
      else
      {
        this.alchemintSharedService.openErrorSnackBar(this.error);
      }
      
    }
    else
    {
      if (this.scrollToTopOnError)
      {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      }
      else if ((divErrorBox) && (this.isSoftUserErrorMessage == false))
      {
        divErrorBox.scrollIntoView(); 
      }
      else if ((divSoftErrrorBox) && (this.isSoftUserErrorMessage == true))
      {
        // divSoftErrrorBox.scrollIntoView(); 
        this.softErrorBoxLabel.nativeElement.scrollIntoView();
      }
  
    }

  }






  public SetErrorDetailsDefTitle (errorMessage : any, useMessageBox: boolean = false) 
  {
    this.SetErrorDetails(errorMessage, this.defaultErrorTitle, true, true, useMessageBox);
  }


  public Reset () 
  {
    this.inErrorState = false;
    this.error = null;
    this.errorTitle = this.defaultErrorTitle;
    this.isOnline = true;
  }


  public SettHttpError(httpError: HttpErrorResponse) {
    if (httpError.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      this.SetErrorDetails(httpError.statusText, "Client or Network Error");
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      this.SetErrorDetails(httpError.statusText, `Status ${httpError.status}`);
    }
  }
  public get hideMainErrorBox () : boolean
  {
    if (this.isSoftUserErrorMessage)
    {
      return true;
    }
    else if (this.error) 
    {
      return false;
    }
    else
    {
      return true;
    }
  }

  public get hideSoftErrorBox () : boolean
  {
    if (this.isSoftUserErrorMessage == false)
    {
      return true;
    }
    else if (this.error) 
    {
      return false;
    }
    else
    {
      return true;
    }
  }

  


  private CheckReachServer () : void
  {
    this.isOnline = true
    this.apiInterfaceService.checkCanReachServer().subscribe(
      resp => { this.isOnline = true;}, 
      error =>{  
        if (error.status === 404)
        {
          this.isOnline = true;
        }
        else
        {
          this.isOnline = false;
        }
        
      }, 
    );
  }
}

