import { Component, HostBinding, OnInit } from '@angular/core';

@Component({
  selector: 'app-popup-menu-item',
  templateUrl: './popup-menu-item.component.html',
  styleUrls: ['./popup-menu-item.component.scss']
})
export class PopupMenuItemComponent implements OnInit {

  @HostBinding('class') class: string = 'mat-menu-item';

  constructor() { }

  ngOnInit(): void {
  }
}
