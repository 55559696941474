<h2 *ngIf="title" class="mat-dialog-title">{{title}}</h2>


<div [hidden]="!videoLink" >
  <app-in-line-assistance [uiItemIdentifier]="'MSGBOX.VIDEO'" [videoLink]="videoLink" [autoStartVideo]="true" [hideOpenCloseIcon]="true"></app-in-line-assistance>
</div>


<mat-dialog-content [hidden]="!message">

  <div [innerHtml]="message">

  </div>

  <a *ngIf="moreInfoLink" [href]="moreInfoLink" target="_blank">More information</a>

</mat-dialog-content>








<mat-dialog-actions *ngIf="buttons === Buttons.Ok">
  <button mat-raised-button (click)="click(Button.Ok)">Ok</button>
</mat-dialog-actions>
<mat-dialog-actions *ngIf="buttons === Buttons.YesNo ">
  <button mat-raised-button (click)="click(Button.Yes)" tabindex="-1">Yes</button>
  <button mat-raised-button (click)="click(Button.No)" class="mat-primary">No</button>
</mat-dialog-actions>

