<h1 class="dialog-form-heading" mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>

    <table style="width: 100%">
        <tr>
            <td>
                RE: {{patient?.patient?.name}}
            </td>
            <td>
                <app-window-close-button [dialogRef]="dialogRef"></app-window-close-button>
            </td>
    </table>


</h1>

<mat-card>
    <!-- <app-window-close-button [dialogRef]="dialogRef"></app-window-close-button> -->
    <div class="card darkcard custom-card">
        <section class="mb-4">
            <div class="row" [hidden]="!sendingEmail">
                <div class="spinner-wrapper">
                    <div class="spinner-inner">
                        <div class="spinner-border"></div>
                        <label class="bold-label sending-label">Sending...</label>
                    </div>
                </div>
            </div>

            <div class="row" [hidden]="sendingEmail">
                <div class="col-md-12 mb-md-0 mb-5">
                    <form id="contact-form" name="contact-form">

                        <div class="row">
                            <div *ngIf="(!toEmailAddresses)" class="control-div">
                                <label class="bold-label radio-label">
                                    <input type="radio" name="recipient" value="contact"
                                        [(ngModel)]="selectedRecipientType"> To Contact
                                </label>
                                <label class="bold-label">
                                    <input type="radio" name="recipient" value="anyone"
                                        [(ngModel)]="selectedRecipientType"> To Anyone
                                </label>
                            </div>

                            <div class="control-div"
                                *ngIf="(contacts?.length > 0) && (!toEmailAddresses) && (selectedRecipientType=='contact')">




                                <table style="width: 100%">
                                    <tr>
                                        <td>
                                            <input type="text" placeholder="Filter Contacts" [(ngModel)]="emailFilter"
                                                id="7123123" name="123123123" class="form-control" autocomplete="off">
                                        </td>
                                    </tr>
                                </table>


                                <div>
                                    <div class="control div scrollable-div rounded-div">
                                        <div
                                            *ngFor="let contactSelection of filteredContactsSelections(); let i = index">
                                            <input type="checkbox" [id]="'contact' + i"
                                                [(ngModel)]="contactSelection.selected">
                                            <label class="contact-label"
                                                [for]="'contact' + i">{{contactSelection.contact?.title}}
                                                {{contactSelection.contact?.firstName}}
                                                {{contactSelection.contact?.lastName}} -
                                                {{contactSelection.contact?.email}}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="control-div"
                                [hidden]="(selectedRecipientType=='contact') && (!toEmailAddresses)">
                                <div>

                                    <table style="width: 100%">
                                        <tr>
                                            <td style="width: 2cm;">
                                                <label for="email" class="bold-label">To</label>
                                            </td>
                                            <td>
                                                <!-- id="12122121" -->
                                                <input type="text" [disabled]="allowEmailAddressEntry===false"
                                                    [(ngModel)]="toEmailAddresses" class="form-control"
                                                    autocomplete="off" name="132131231313">
                                            </td>
                                    </table>
                                    <div *ngIf="toEmailAddressesInvalid" class="invalid-email">Invalid Email</div>

                                </div>
                            </div>


                            <div class="control-div" [hidden]="hideCCInput">
                                <div>
                                    <table style="width: 100%">
                                        <tr>
                                            <td style="width: 2cm;">
                                                <label for="ccAddresses" class="bold-label">CC</label>
                                            </td>
                                            <td>
                                                <input [(ngModel)]="ccEmailAddresses" type="text" class="form-control"
                                                    autocomplete="off" name="1321312313135">
                                            </td>
                                    </table>


                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="control-div">
                                <div>
                                    <table style="width: 100%">
                                        <tr>
                                            <td style="width: 2cm;">
                                                <label for="subject" class="bold-label">Subject</label>
                                            </td>
                                            <td>
                                                <input #subjectInput type="text" id="subject" name="subject"
                                                    [(ngModel)]="messageSubject" class="form-control"
                                                    autocomplete="off">
                                            </td>
                                    </table>


                                    <!-- <label for="subject" class="bold-label">Subject</label>
                                        <input type="text" id="subject" name="subject" [(ngModel)]="messageSubject" class="form-control"> -->
                                </div>
                            </div>
                        </div>


                        <div class="row">
                            <div class="control-div">
                                <div>
                                    <label for="message" class="bold-label">Your message</label>

                                    <div class="btn btn-primary" style="margin-left: 1em; float: right;"
                                        *ngIf="showShortcutKeys">
                                        <div>
                                            <mat-label>Shortcut Keys</mat-label>
                                            <mat-select style="width: 50px;">
                                                <!-- [value]="type" -->
                                                <mat-option *ngFor="let sk of shortCutKeys"
                                                    (click)="onShortCutKeySelected(sk)">

                                                    {{sk.name}} - <span style="color: seagreen;">Ctrl +
                                                        {{shortCutKeyName(sk)}}</span>

                                                </mat-option>
                                            </mat-select>


                                        </div>
                                    </div>



                                    <div (keydown)="editorKeyPress($event)" class="message-body" #messageBodyTextArea
                                        contenteditable></div>


                                </div>
                            </div>
                        </div>

                        <div *ngIf="selectedFiles.length > 0">
                            <div class="control-div">
                                <div>
                                    <label *ngFor="let file of selectedFiles" class="bold-label">📎{{file.name}}</label>
                                </div>
                            </div>
                        </div>

                        <div class="row" *ngIf="attachmentFileData">
                            <div class="control-div">
                                <div>
                                    <label class="bold-label">📎{{attachmentFileData.name}}</label>
                                </div>
                            </div>
                        </div>

                        <div class="row" *ngIf="attachmentArtifactId">
                            <div class="control-div">
                                <div>
                                    <label class="bold-label" for="message">Attachment {{attachmentArtifactId}}</label>
                                </div>
                            </div>
                        </div>


                        <table style="width: 100%">
                            <tr>
                                <td style="width: 4cm;" [hidden]="hideAttachButton">
                                    <button class="btn btn-secondary" (click)="fileInput.click()">Add
                                        Attachment</button>
                                </td>
                                <td>
                                    <button class="btn btn-primary" (click)="sendEmail()"
                                        [disabled]="sendDisabled()">Send Email</button>
                                </td>
                        </table>


                        <input type="file" #fileInput class="file-input" (change)="onFileSelected($event)" multiple>
                        <!-- <div class="row">
                                <div class="control-div">
                                    <button class="btn btn-secondary" (click)="fileInput.click()">Add Attachment</button>
                                </div>
                            </div>
    

    
                            <div class="row">
                                <div class="control-div">
                                    <button class="btn btn-primary" (click)="sendEmail()" [disabled]="sendDisabled()">Send Email</button>
                                </div>
                            </div> -->
                    </form>
                </div>
            </div>
        </section>
    </div>
</mat-card>




<!-- <mat-card>
    
        <app-window-close-button [dialogRef]="dialogRef"></app-window-close-button>
    
        <div class="card darkcard" style="width:80vw;">
    
            <section class="mb-4">
                <div class="row" [hidden]="!sendingEmail">
                    <div class="spinner-wrapper">
                        <div class="spinner-inner">
                            <div class="spinner-border"></div>
                            <label class="bold-label sending-label">Sending...</label>
                        </div>
                    </div>
                </div>
    
                <div class="row" [hidden]="sendingEmail">
    
                    <div class="col-md-12 mb-md-0 mb-5">
                        <form id="contact-form" name="contact-form">
    
                            <div class="row">
                                <div class="control-div">
                                    <div>
                                        <h2 class="patient-name">{{patient?.patient?.name}} </h2>
                                    </div>
                                </div>
                            </div>
    
    
                            <div class="row">
    
                                <div *ngIf="(!toEmailAddresses)" class="control-div">
                                    <label class="bold-label radio-label">
                                        <input type="radio" name="recipient" value="contact"
                                            [(ngModel)]="selectedRecipientType">
                                        To Contact
                                    </label>
    
                                    <label class="bold-label">
                                        <input type="radio" name="recipient" value="anyone"
                                            [(ngModel)]="selectedRecipientType">
                                        To Anyone
                                    </label>
                                </div>
    
                                <div class="control-div"
                                    *ngIf="(contacts?.length > 0) && (!toEmailAddresses) && (selectedRecipientType=='contact')">
                                    <div>
                                        <div class="control div scrollable-div rounded-div">
                                            <div *ngFor="let contactSelection of contactsSelections; let i = index">
                                                <input type="checkbox" [id]="'contact' + i"
                                                    [(ngModel)]="contactSelection.selected">
                                                <label class="contact-label"
                                                    [for]="'contact' + i">{{contactSelection.contact?.title}}
                                                    {{contactSelection.contact?.firstName}}
                                                    {{contactSelection.contact?.lastName}} -
                                                    {{contactSelection.contact?.email}}</label>
                                            </div>
                                        </div>
    
                                    </div>
                                </div>
    
                                <div class="control-div"
                                    [hidden]="(selectedRecipientType=='contact') && (!toEmailAddresses)">
                                    <div>
                                        <label for="email" bold-label>To</label>
                                        <input type="text" [disabled]="allowEmailAddressEntry===false"
                                            [(ngModel)]="toEmailAddresses" id="email" name="email" class="form-control">
    
                                        <div *ngIf="toEmailAddressesInvalid" class="invalid-email">
                                            Invalid Email
                                        </div>
    
                                    </div>
                                </div>
    
                            </div>
    
                            <div class="row">
    
                                <div class="control-div">
                                    <div>
                                        <label for="subject" bold-label>Subject</label>
                                        <input type="text" id="subject" name="subject" [(ngModel)]="messageSubject"
                                            class="form-control">
                                    </div>
                                </div>
                            </div>
    
    
                            <div class="row">
                                <div class="control-div">
                                    <div>
                                        <label for="message" class="bold-label">Your message</label>
                                        <div style="border: solid; border-width: 1px; border-radius: 5px; padding: 1em;  min-width: 90%; min-height: 4cm; overflow-y: auto; max-height: 10cm;;"
                                            #messageBodyTextArea contenteditable>
                                        </div>
                                    </div>
                                </div>
                            </div>
    
    
                            
                            <div *ngIf="selectedFiles.length > 0">
                                <div class="control-div">
                                    <div>
                                        <label *ngFor="let file of selectedFiles" class="bold-label">📎{{file.name}}</label>
                                    </div>
    
                                </div>
                            </div>
    
                            <div class="row" *ngIf="attachmentFileData">
                                <div class="control-div">
                                    <div>
                                        <label class="bold-label">📎{{attachmentFileData.name}}</label>
                                    </div>
                                </div>
                            </div>
    
                            
                            <input type="file" #fileInput style="display: none" (change)="onFileSelected($event)" multiple>
    
                            
                            <div class="row">
                                <div class="control-div">
                                    <button class="btn btn-secondary" (click)="fileInput.click()">Add Attachment</button>
                                </div>
                            </div>
    
                            <div class="row" *ngIf="attachmentArtifactId">
                                <div class="control-div">
                                    <div>
                                        <label class="bold-label" for="message">Attachment {{attachmentArtifactId}}</label>
                                    </div>
                                </div>
                            </div>
    
                            <div class="row">
                                <div class="control-div">
                                    <button class="btn btn-primary" (click)="sendEmail()" [disabled]="sendDisabled()">
                                        Send Email
                                    </button>
    
                                </div>
                            </div>
    
                        </form>
                    </div>
                </div>
    
            </section>
    
        </div>
    </mat-card> -->

<!-- <button *ngIf="showVerifyEmail" (click)="verifyEmail()">
    VerifyEmail
</button> -->

<button *ngIf="showVerifyEmail" (click)="generateMsgFile()">
    generateMsgFile
</button>