
export class HelperFunctions
{
    public static replaceAllTextOccurrencesWith (data : string, replaceThis : string, withThat : string) : string
    {
      while(data.indexOf(replaceThis) > -1)
      {
        data = data.replace(replaceThis,withThat);
      }
      return data;
    }
}