import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/_services';
import { SseService } from '@app/sse-listner/sse-listner.service';
import { Subscription } from 'rxjs';
import { EventEmitter, Output } from '@angular/core';
import { SupportTicketChatItem } from '@app/_alchemint/alchemint_dm';
import { InitialSupportTicketDTO } from '@app/_alchemint/alchemint_composite_requests';
import { EnvService } from '@app/_services/environment.service';

interface Notification {
  id: number;
  title: string;
  message: string;
  expanded: boolean;
}

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  providers: [SseService]
})
export class NotificationsComponent implements OnInit {
  notifications: Notification[] = [];
  newNotificationsCount: number = 0;
  showNotifications: boolean = false;
  disconnected: boolean = false; // Property to indicate connection status
  enabled : boolean = true;
  @Output() public messageReceived : EventEmitter<InitialSupportTicketDTO> = new EventEmitter<InitialSupportTicketDTO>();

  constructor(private sseService: SseService, private auth: AuthenticationService, private env: EnvService) {}

  subscription: Subscription;
  messages: string[] = [];
  private eventSource!: EventSource;
  private reconnectAttempts: number = 0;

  ngOnInit(): void {
    if (this.enabled)
    {
      var userId = this.auth.userIdFromToken;
      if (userId)
      {
        const url: string = `${this.env.deploymentSettings.apiExtendedUrl}pushnotification/${userId}/${this.auth.storedBearerToken}`;
        this.connectWithExponentialBackoff(url);
      }
  
    }
  }

  connectWithExponentialBackoff(url: string): void {
    this.disconnected = false; // Assume connection is active
    this.subscription = this.sseService.getServerSentEvent(url)
      .subscribe(
        (msg) => {
          this.reconnectAttempts = 0; // Reset on successful message
          this.messages.push(msg);
          this.newNotificationsCount++;

          const supportTicketChatItemDTO = JSON.parse(msg) as InitialSupportTicketDTO;
          this.messageReceived?.emit(supportTicketChatItemDTO);
        },
        (err) => {
          console.error('SSE error:', err);
          this.disconnected = true; // Set disconnected on error
          this.handleReconnect(url);
        }
      );
  }

  handleReconnect(url: string): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    const backoffTime = Math.min(1000 * Math.pow(2, this.reconnectAttempts), 600000); // Max backoff of 600 seconds ( 10 mins)
    console.log(`Reconnecting in ${backoffTime / 1000} seconds...`);

    setTimeout(() => {
      this.reconnectAttempts++;
      this.connectWithExponentialBackoff(url);
    }, backoffTime);
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  toggleNotifications(): void {
    this.showNotifications = !this.showNotifications;
  }

  expandNotification(notification: Notification): void {
    notification.expanded = !notification.expanded;
  }

  createNotification(notification: Notification): void {
    this.notifications.push(notification);
    this.newNotificationsCount++;
  }

  updateNotification(notification: Notification): void {
    const index = this.notifications.findIndex(n => n.id === notification.id);
    if (index !== -1) {
      this.notifications[index] = notification;
    }
  }

  deleteNotification(notificationId: number): void {
    this.notifications = this.notifications.filter(n => n.id !== notificationId);
    this.newNotificationsCount = this.notifications.length;
  }
}
