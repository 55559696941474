// app-svg.component.ts
import { Component, ViewChild, ElementRef, AfterViewInit, OnChanges, SimpleChanges, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SvgIconComponent, SvgIconRegistryService } from 'angular-svg-icon';

@Component({
  selector: 'app-svg',
  templateUrl: './svg.component.html',
  styleUrls: ['./svg.component.scss']
})
export class AppSvgComponent implements AfterViewInit {
  
  @Input() color: string = 'white';
  @Input() customClass: string = null;
  

  constructor(private sanitizer: DomSanitizer) {}

  // @ViewChild('icon', { read: ElementRef }) iconRef: ElementRef;
  @ViewChild(SvgIconComponent) icon: SvgIconComponent;

  @ViewChild('textContent') textContent: ElementRef;
  iconName: string;
  svgUrl: string;

  ngAfterViewInit(): void {
    this.iconName = this.textContent.nativeElement.innerText.trim();
    this.svgUrl = `assets/svgs/${this.iconName}_${this.imageSuffix}_24dp.svg`;

  }
  defaultBrokenImageUrl: string = 'assets/svgs/broken_image_black_24dp.svg'
  brokenImage: boolean = false;
  handleError(): void {
    //this.svgUrl = this.defaultBrokenImageUrl;
    this.brokenImage = true;
  }


  // getStyle(): string {
  //   if (this.height) {
  //     return `height: ${this.height};`;
  //   }
  //   return '';
  // }

  imageSuffix: string = 'white';  



  
  svgClass(): string
  {
    var cls = this._svgClass();
    if (this.customClass)
    {
      cls += ' ' + this.customClass;
    }
    return cls;
  }

  _svgClass(): string{
    this.imageSuffix = 'white';  
    if (this.color === 'primary') {
      return 'custom-svg-icon-green';
    }
    else if (this.color === 'green') {
      return 'custom-svg-icon-green';
    }
    else if (this.color === 'darkgreen') {
      return 'custom-svg-icon-darkgreen';
    }
    else if (this.color === 'red') {
      return 'custom-svg-icon-red';
    }
    else if (this.color === 'orange') {
      return 'custom-svg-icon-orange';
    }
    else if (this.color === 'yellow') {
      return 'custom-svg-icon-yellow';
    }

    else if (this.color === 'black')
    {
      this.imageSuffix = 'black';  
      return '';
    }
    if (this.color === 'white')
    {
      return '';
    }
    else
    {
      return 'primary';
    }
  }

 

  // ngOnChanges(changes: SimpleChanges) {
  //   if(!this.icon.src) {
  //     this.handleError();
  //   }
  // }

}

