import { DatePipe } from '@angular/common';
import *  as Alchemint from '@app/_alchemint/alchemint_dm';
import { ApiInterfaceService } from '@app/_services/alchemint.apiinterface.service';
import { te } from 'date-fns/locale';
import { Observable } from 'rxjs';

export class ShortcutKeyExecuter {

  public execCustomShortCutKey(keycode: any, shiftKeyAlsoDown: boolean, shortcuts: Alchemint.CustomShortcutKeys[]): string {

    var matchKeyContent :string | null = ShortcutKeyExecuter.getShortCutKeyContent(keycode, shiftKeyAlsoDown, shortcuts);

    if (matchKeyContent) {
      var sh: ShortcutKeyExecuter = new ShortcutKeyExecuter(this.hTMLTextAreaElement, this.tinymceInstance, this.insertionsAtCursor);
      return sh.Execute(matchKeyContent, false);
    }
    else {
      return null;
    }
  }


    public static getShortCutKeyContent(keycode: any, shiftKeyAlsoDown: boolean, shortcuts: Alchemint.CustomShortcutKeys[]): string {
      var matchKey: Alchemint.CustomShortcutKeys[];
      if (shiftKeyAlsoDown) {
        matchKey = shortcuts.filter(x => x.needsShiftKeyAsWell == true);
      }
      else {
        matchKey = shortcuts;
      }
  
      if (matchKey?.length > 0) {
        matchKey = matchKey.filter(x => x.shortCutKey.charCodeAt(0) == keycode);
      }

      if (matchKey?.length > 0) {
        return matchKey[0]?.content;
      }
      else {
        return null;
      }

    }


  public static getShortCutKeyCombo(sk: Alchemint.CustomShortcutKeys) {
    var ret = 'Ctrl + ';
    ret += sk?.needsShiftKeyAsWell ? 'Shift + ' : '';
    ret += sk?.shortCutKey;
    return ret;
  }


  insertionsAtCursor: boolean = false;

  constructor(private hTMLTextAreaElement: HTMLTextAreaElement | HTMLDivElement, private tinymceInstance: any = null, insertionsAtCursor: boolean = false) {
    this.insertionsAtCursor = insertionsAtCursor;
  }


  public static _shortCutKeyName(c: Alchemint.CustomShortcutKeys): string {
    var ret: string = c.shortCutKey?.replace(' ', 'SPACE');
    ret = ret.replace('\t', 'TAB');

    // ret += ' - ' + c.name;
    return ret;
  }
  public static skCustomCutKeys: Alchemint.CustomShortcutKeys[] = null;


  public static buildShortCutKEys(isExternalCall: boolean, showShortCutKeyMenu: boolean, apiInterfaceService: ApiInterfaceService, friendlyNames: boolean): Observable<Alchemint.CustomShortcutKeys[]> {
    return new Observable<Alchemint.CustomShortcutKeys[]>(
      observer => {
        // if ((this.showShortCutKeyMenu) && (isExternalCall == false) &&  (!(this.shortCustomCutKeys)))
        if ((showShortCutKeyMenu) && (isExternalCall == false)) {

          if (this.skCustomCutKeys) {
            observer.next(this.skCustomCutKeys)
          }
          else {
            apiInterfaceService.getPreLoadDataAndSetGlobals().subscribe(
              (sks) => {
                var skCustomCutKeys: Alchemint.CustomShortcutKeys[] = sks.shortCutKeys;
                skCustomCutKeys.push(this.AppendShortcutKey("D", "Add Date"));
                skCustomCutKeys.push(this.AppendShortcutKey("G", "Add Age"));
                skCustomCutKeys.push(this.AppendShortcutKey("1", "Add Age and Date"));
                skCustomCutKeys.push(this.AppendShortcutKey("2", "Username and Date"));
                skCustomCutKeys.push(this.AppendShortcutKey(" ", "Add start entry"));
                skCustomCutKeys.push(this.AppendShortcutKey("\\", "Add end entry"));

                if (friendlyNames) {
                  for (let s of skCustomCutKeys) {
                    // s.name = this._shortCutKeyName(s);
                  }
                }

                this.skCustomCutKeys = skCustomCutKeys;
                observer.next(skCustomCutKeys);
              }
            );

          }
        }
        else {
          observer.next(null);
        }

      }

    );
  }


  private static AppendShortcutKey(key: string, name: string): Alchemint.CustomShortcutKeys {
    var sk: Alchemint.CustomShortcutKeys = new Alchemint.CustomShortcutKeys();
    sk.shortCutKey = key;
    sk.name = name;
    return sk;
  }
  public static _editorKeyPress(
    event: any, userName: string,
    datepipe: DatePipe, apiInterfaceService: ApiInterfaceService, hTMLTextAreaElement: HTMLTextAreaElement | HTMLDivElement, txtChanged: boolean,
    patientId: string,
    shortCustomCutKeys: Alchemint.CustomShortcutKeys[],
    tinyInstance: any
  ): Observable<shortCutKeyResultsObject> {
    return new Observable<shortCutKeyResultsObject>(
      observer => {


        var shortCutKeyResult = new shortCutKeyResultsObject(txtChanged, null);
        var shortCutHelper = new ShortCutKeyHelper(datepipe, userName, apiInterfaceService, hTMLTextAreaElement, tinyInstance);

        if (event.ctrlKey && (event.keyCode == 68)) {
          if (event.preventDefault) {
            event.preventDefault();
          }

          shortCutKeyResult.textChanged = true;
          //this.execShortCutKey(shortCutHelper.getTodayDateString(), true);

          var sh: ShortcutKeyExecuter = new ShortcutKeyExecuter(hTMLTextAreaElement, tinyInstance);
          shortCutKeyResult.textFileData = sh.Execute(shortCutHelper.getTodayDateString(), true);
          shortCutKeyResult.mustUpdateDocText = true;

          observer.next(shortCutKeyResult);
        }
        //Ctrl+2 --> UserName and Date
        else if ((event.ctrlKey && event.keyCode == 50) || (event.ctrlKey && event.keyCode == 32) || (event.ctrlKey && event.keyCode == 220) || (event.ctrlKey && event.keyCode == 92)) {

          if (event.preventDefault) {
            event.preventDefault();
          }

          var userNameAndDate: string;

          if (!(userName)) {
            observer.error(`Username has not been set on editor component.`);
            return;
          }
          else {
            if (event.keyCode == 32) {
              userNameAndDate = shortCutHelper.getUSerNameAndStartEntry();
            }
            else if ((event.keyCode == 220) || (event.keyCode == 92)) {
              userNameAndDate = shortCutHelper.getUSerNameAndEndEntry();
            }
            else {
              userNameAndDate = shortCutHelper.getUSerNameAndToday();
            }
            shortCutKeyResult.textChanged = true;
            //this.execShortCutKey(userNameAndDate, true);
            var sh: ShortcutKeyExecuter = new ShortcutKeyExecuter(hTMLTextAreaElement, tinyInstance);
            shortCutKeyResult.textFileData = sh.Execute(userNameAndDate, true);
            shortCutKeyResult.mustUpdateDocText = true;

          }
          observer.next(shortCutKeyResult);
        }

        // Ctrl-G --> Age
        else if ((event.ctrlKey && event.keyCode == 71) || (event.ctrlKey && event.keyCode == 49)) {

          var ageAndDate: boolean = (event.keyCode == 49);
          if (event.preventDefault) {
            event.preventDefault();
          }

          // #### this.working = true;
          shortCutKeyResult.textChanged = true;
          shortCutHelper.executeAgeShortcut(patientId, ageAndDate).subscribe(
            result => {
              // #### this.working = false;
              //############# this.fileTextData = result;
              shortCutKeyResult.mustUpdateDocText = true;
              shortCutKeyResult.textFileData = result;
              //this.parseMarkDown();
              observer.next(shortCutKeyResult);
            },
            err => {
              // #### this.working = false;
              observer.error(err);
              return;
            }
          )


        }
        else if (event.ctrlKey) {

          var alsoMustBeHoldingShiftKey: boolean = event.shiftKey;
          if (shortCustomCutKeys) {
            // #######this.execCustomShortCutKey(event);

            var sh: ShortcutKeyExecuter = new ShortcutKeyExecuter(hTMLTextAreaElement);

            var resultText = sh.execCustomShortCutKey(event.keyCode, alsoMustBeHoldingShiftKey, shortCustomCutKeys);
            if (resultText) {
              shortCutKeyResult.textChanged = true
              shortCutKeyResult.textFileData = resultText;
              if (event.preventDefault) {
                event.preventDefault();
              }
            }

            observer.next(shortCutKeyResult);
          }
          else {
            // #### this.working = true;
            apiInterfaceService.getPreLoadDataAndSetGlobals().subscribe(
              preloadData => {
                // #### this.working = false;
                shortCustomCutKeys = preloadData.shortCutKeys;
                //  ######this.execCustomShortCutKey(event);


                var sh: ShortcutKeyExecuter = new ShortcutKeyExecuter(hTMLTextAreaElement);
                var resultText = sh.execCustomShortCutKey(event.keyCode, alsoMustBeHoldingShiftKey, shortCustomCutKeys);
                if (resultText) {
                  shortCutKeyResult.textChanged = true
                  shortCutKeyResult.textFileData = resultText;
                  if (event.preventDefault) {
                    event.preventDefault();
                  }
                }

                observer.next(shortCutKeyResult);
              },
              error => {
                // #### this.working = false;
                observer.error(error);

              }
            );

          }

        }



      }
    )


  }

  public Execute(appendText: string, useFormat: boolean): string {
    var appText = this.doVariableSubstitutions(appendText);

    if (appendText) {
      if (this.tinymceInstance) {
        this.tinymceInstance.activeEditor.execCommand('mceInsertContent', false, appText);
        return null;
      }
      else {
        var textData: string = this.getEditorTextWithAppended(appText, useFormat, this.insertionsAtCursor);
        this._setEditorText(textData);
        return textData;
      }

    }
    else {
      return null;
    }

  }

  private doVariableSubstitutions(template: string): string {

    if (template) {
      var updatedString = template.replace(new RegExp('@DATE@', 'g'), this.getTodayDateString());
      updatedString = updatedString.replace(new RegExp('@TIME@', 'g'), this.getNowTime());
      updatedString = updatedString.replace(new RegExp('@DATETIME@', 'g'), this.getTodayDateAndTime());
      return updatedString;
    }
    else {
      return template;
    }
  }

  private getTodayDateAndTime(): string {
    const now = new Date();
    const formattedDateTime = [
      now.getDate().toString().padStart(2, '0'),
      (now.getMonth() + 1).toString().padStart(2, '0'),
      now.getFullYear().toString(),
    ].join('-') + ' ' + [
      now.getHours().toString().padStart(2, '0'),
      now.getMinutes().toString().padStart(2, '0'),
    ].join(':');
    return formattedDateTime;
  }

  // private getTodayDateString(): string {
  //   const currentDate = new Date();
  //   const formattedDate = [
  //     (currentDate.getMonth() + 1).toString().padStart(2, '0'),
  //     currentDate.getDate().toString().padStart(2, '0'),
  //     currentDate.getFullYear()
  //   ].join('-')
  //   return formattedDate;
  // }

  public getTodayDateString(): string {
    const dt = new Date(); // Get current date
    const day = dt.getDate(); // Get current day
    const month = dt.getMonth(); // Get current month (0-indexed)
    const year = dt.getFullYear(); // Get current year

    const months = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"];
    const todaydate = `${day < 10 ? '0' + day : day} ${months[month]} ${year}`;
    return todaydate;
  }


  private getNowTime(): string {
    const currentTime = new Date();
    const formattedTime = [
      currentTime.getHours().toString().padStart(2, '0'),
      currentTime.getMinutes().toString().padStart(2, '0')
    ].join(':');
    return formattedTime;
  }



  private getEditorTextWithAppended(appendText: string, useFormat: boolean, insertionsAtCursor: boolean = false): string {
    
    if (insertionsAtCursor) {
      return this.getEditorTextWithAppendedTextAtCursor(appendText, useFormat);
    }
    
    
    var currEditorText: string = this._getCurrentEditorText();
    var textData: string = '';

    var SHORTCUTKEYUNDERLINER_COUNT: number = 50;
    var underlineString: string = "-".repeat(SHORTCUTKEYUNDERLINER_COUNT);

    var newLineChars = this.isEditorADivElement ? '<br>' : '\n';

    if (useFormat) {
      textData = textData + newLineChars;
      textData = textData + underlineString;
      textData = textData + newLineChars;
    }

    textData = textData + appendText;

    if (useFormat) {
      textData = textData + newLineChars;
      textData = textData + underlineString;
      textData = textData + newLineChars;
      textData = textData + newLineChars;
    }

    if (!(currEditorText)) {

    }
    else {
      textData = currEditorText + textData;
    }

    return textData;
  }


  // 7 NOV 2024 - This is incomplete. The calculation of cursor position for a div element is incorrect
  // So do not use yet
  private getEditorTextWithAppendedTextAtCursor(appendText: string, useFormat: boolean): string {
    var currEditorText: string = this._getCurrentEditorText();
    var textData: string = '';

    var SHORTCUTKEYUNDERLINER_COUNT: number = 50;
    var underlineString: string = "-".repeat(SHORTCUTKEYUNDERLINER_COUNT);

    var newLineChars = this.isEditorADivElement ? '<br>' : '\n';

    if (useFormat) {
        textData += newLineChars;
        textData += underlineString;
        textData += newLineChars;
    }

    textData += appendText;

    if (useFormat) {
        textData += newLineChars;
        textData += underlineString;
        textData += newLineChars;
        textData += newLineChars;
    }

    if (!currEditorText) {
        return textData;
    }

    const cursorPosition = this._getCursorPosition(); // Implement this method to get the cursor index
    const beforeCursor = currEditorText.substring(0, cursorPosition);
    const afterCursor = currEditorText.substring(cursorPosition);
    return beforeCursor + textData + afterCursor;
  }




  private get isEditorADivElement(): boolean {
    return this.hTMLTextAreaElement?.tagName === 'DIV';
  }

  private _getCurrentEditorText(): string {


    // Check if this.hTMLTextAreaElement is a textarea element
    if (this.hTMLTextAreaElement?.tagName === 'TEXTAREA') {
      var element: HTMLTextAreaElement = <HTMLTextAreaElement>this.hTMLTextAreaElement;

      if (!(element)) {
        throw ('Editor not found.');
      }

      return element.value;

    }
    // Check if this.hTMLTextAreaElement is a div element
    else if (this.hTMLTextAreaElement?.tagName === 'DIV') {
      var element: HTMLTextAreaElement = <HTMLTextAreaElement>this.hTMLTextAreaElement;

      if (!(element)) {
        throw ('Editor not found.');
      }

      return element.innerHTML;

    }
    else {
      throw ('Invalid Editor.');
      return null;
    }


  }


  private _getCursorPosition(): number {
    if (this.hTMLTextAreaElement?.tagName === 'TEXTAREA') {
        const element = this.hTMLTextAreaElement as HTMLTextAreaElement;
        return element.selectionStart ?? 0; // Cursor for <textarea>
    } else if (this.hTMLTextAreaElement?.tagName === 'DIV') {
        const selection = window.getSelection();
        if (selection && selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);
            let charCount = 0;

            const walker = document.createTreeWalker(
                this.hTMLTextAreaElement,
                NodeFilter.SHOW_TEXT, // Only text nodes
                null // No custom filter function
            );

            let currentNode: Node | null = walker.nextNode();

            while (currentNode) {
                if (currentNode === range.endContainer) {
                    charCount += range.endOffset;
                    break;
                } else {
                    charCount += currentNode.textContent?.length ?? 0;
                }
                currentNode = walker.nextNode();
            }

            return charCount; // Exact cursor position
        }
        return 0;
    } else {
        throw new Error('Unsupported editor type for cursor position.');
    }
}




  private _setEditorText(textData: string): void {

    this.hTMLTextAreaElement.focus();

    if (this.hTMLTextAreaElement?.tagName === 'TEXTAREA') {
      var textarea = <HTMLTextAreaElement>this.hTMLTextAreaElement;
      textarea.value = textData;
      textarea.setSelectionRange(textData.length, textData.length);
      textarea.scrollTop = textarea.scrollHeight;
    }
    else if (this.hTMLTextAreaElement?.tagName === 'DIV') {
      var div = <HTMLDivElement>this.hTMLTextAreaElement;
      div.innerHTML = textData;
      div.scrollTop = div.scrollHeight;
    }


  }




}

export class ShortCutKeyHelper {

 
  executeAgeShortcut(patientId: string, ageAndDate: boolean, insertionsAtCursor: boolean = false): Observable<string> {
    return new Observable<string>(
      observer => {
        var srch = new Alchemint.BiographicalDetails();
        srch.patientId = patientId;

        this.apiInterfaceService.getEntityByMatchingProperties<Alchemint.BiographicalDetails>(new Alchemint.BiographicalDetails(), srch).subscribe(
          bio => {
            if (bio.length > 0) {
              var ageString: string = this.getAgeString(bio[0].dateOfBirth, ageAndDate);
              var text = this.execShortCutKey(ageString, true);
              observer.next(text);
            }
            else {
              var ageString: string = this.getAgeString(null, ageAndDate);
              var text = this.execShortCutKey(ageString, true);
              observer.next(text);

            }

          },
          err => {
            observer.error(err);
          }
        );

      }
    );
  }
  private execShortCutKey(appendText: string, useFormat: boolean, insertionsAtCursor: boolean = false): string {
    var sh: ShortcutKeyExecuter = new ShortcutKeyExecuter(this.hTMLTextAreaElement, this.tinyInstance, insertionsAtCursor);
    return sh.Execute(appendText, useFormat);
  }

  constructor(private datepipe: DatePipe, private userName: string, private apiInterfaceService: ApiInterfaceService, private hTMLTextAreaElement: HTMLTextAreaElement | HTMLDivElement, private tinyInstance: any) {

  }



  public getTodayDateString(): string {
    const dt = new Date(); // Get current date
    const day = dt.getDate(); // Get current day
    const month = dt.getMonth(); // Get current month (0-indexed)
    const year = dt.getFullYear(); // Get current year

    const months = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"];
    const todaydate = `${day < 10 ? '0' + day : day} ${months[month]} ${year}`;
    return todaydate;
  }


  public getUSerNameAndStartEntry(): string {
    var extra: string = ' entry start @ ';
    var userNameAndDate = this.userName + extra + this.getTodayString();
    return userNameAndDate;
  }
  public getUSerNameAndEndEntry(): string {
    var extra: string = ' entry end @ ';
    var userNameAndDate = this.userName + extra + this.getTodayString();
    return userNameAndDate;
  }
  public getUSerNameAndToday(): string {
    var userNameAndDate = this.userName + ' : ' + this.getTodayString();
    return userNameAndDate;
  }

  public getTodayString(): string {
    var dt = new Date(Date.now());
    let todaydate = this.datepipe.transform(dt, 'dd MMMM yyyy');
    return todaydate;
  }

  public getAgeString(dob: Date, ageAndDate: boolean): string {


    if (dob) {
      let age = this.apiInterfaceService.ageFromDateOfBirthday(dob);
      var ageString: string = "Age : " + age.toString();
      if (ageAndDate) {
        ageString = ageString + '\n';
        ageString = ageString + this.getTodayString();
      }
      return ageString;
    }
    else {
      var ageString: string = "Age : " + "Unknown";
      if (ageAndDate) {
        ageString = ageString + '\n';
        ageString = ageString + this.getTodayString();
      }
      return ageString;
    }
  }


}


export class shortCutKeyResultsObject {
  constructor(public textChanged: boolean, public textFileData: string) {

  }
  public mustUpdateDocText: boolean
}
