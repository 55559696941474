  
  <div class="splash-screen" *ngIf="showSplash">
    <div class="logo-container">
        <img src="assets/AlchemedBlackLogoTransparent.png"  alt="App Logo" />
  

        <img src="assets/AIAssist-transparent.png"  alt="App Logo" />


        <h2 style="color: black;">
            AI Powered Medicine
        </h2>
        
  
  
  
    </div>
  </div>
  
  