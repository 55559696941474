<section class="big-screen" >
    <div class="block">
        <div class="block-head">
            <label>{{leftBlockLabel}}
                <button mat-icon-button color="primary" *ngIf="loading">
                    <span>
                        <mat-spinner color="warn" diameter="20">
                        </mat-spinner>
                    </span>
                </button></label>
            <input type="text" placeholder="search here..." [(ngModel)]="leftSearchTerm"
                (ngModelChange)="searchEvent$.next({block:'left',value:$event})" class="search-input">
        </div>
        <div class="from-list">
            <li *ngFor="let item of masterRecords" (click)="item.selected=!item.selected;lastSelectedBlock = 'LEFT'"
                [ngClass]="item.selected?'selected':''"> <span
                    class="selectable-item-name">{{item.name}}</span>{{item.description}} <span class="item-units"> - (R {{item.price?? '0'}})</span></li>
        </div>
    </div>
    <div class="mover-actions">
        <button class="mb-1" mat-raised-button (click)="activate()" [disabled]="lastSelectedBlock === 'RIGHT'">
            Activate<app-svg color="black">keyboard_double_arrow_right</app-svg>
        </button>
        <button class="mt-1" mat-raised-button (click)="deactivate()" [disabled]="lastSelectedBlock === 'LEFT'">
            <app-svg color="black">keyboard_double_arrow_left</app-svg> Deactivate
        </button>
    </div>
    <div class="block">
        <div class="block-head">
            <label>{{rightBlockLabel}}</label>
            <button [disabled]="countActiveSelected < 1" mat-icon-button color="primary" (click)="onRequestEdit()" title="Edit">
                <app-svg>edit_square</app-svg>
            </button>
            <button (click)="onCreateRequest()" mat-icon-button class="btn btn-primary mb-1" title="Add">
                <app-svg>add</app-svg>
            </button>
            <input type="text" placeholder="search here..." [(ngModel)]="righSearchTerm"
                (ngModelChange)="searchEvent$.next({block:'right',value:$event})" class="search-input">
        </div>
        <div class="to-list">
            <li *ngFor="let item of activatedRecords" (click)="item.selected=!item.selected;lastSelectedBlock = 'RIGHT';setLastActivatedItem(item)"
                [ngClass]="item.selected?'selected':''"><span
                    class="selectable-item-name">{{item.name}} </span>{{item.description}} <span class="item-units"> - (R{{item.price?? '0'}})</span></li>
        </div>
    </div>
</section>
