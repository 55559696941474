import { Directive } from '@angular/core';
import { ElementRef } from '@angular/core';

import { HostListener, Input } from '@angular/core';
import { ApiKeys } from '@app/_alchemint/alchemint_dm';
import { AuthenticationService } from '@app/_services';
import { ApiInterfaceService } from '@app/_services/alchemint.apiinterface.service';
import { Environment, EnvService } from '@app/_services/environment.service';
import { eWebAppLocalSettings, SettingsService } from '@app/_services/settings-service.service';


enum eHtmlElemTypes
{
  Unknown,
  Anchor,
  Label,
  Div
}

@Directive({
  selector: '[appBetaFeature]', 
})
export class BetaFeatureDirective  {

  constructor(private el: ElementRef, private envService : EnvService, private api: ApiInterfaceService, private settings: SettingsService) { }
  
  isBetaFeature : boolean = false;

  @Input() set appBetaFeature (value : boolean) 
  {
      this.isBetaFeature = value;


      this.api.getPreLoadDataAndSetGlobals().subscribe(
        res=> {

          this.setVisibility(this.el, res.apiKeyDetails, this.settings.getLocalSetting(eWebAppLocalSettings.EnableBetaFeatures, 'BOOL', false));
          
        }
      );



  }

  private setVisibility (el : ElementRef, apiKey : ApiKeys, betaFeaturesSwitchedOnLocally: boolean) 
  {

    var isBetaSiteOrEnvironment : boolean = 
      (apiKey?.includeBetaFetures === true) || 
      // (this.envService.env === Environment.Dev) || 
      (this.envService.env === Environment.Staging) || 
      (this.envService.env === Environment.Test) || 
      (betaFeaturesSwitchedOnLocally === true)
      ;

    if ((this.isBetaFeature === true) && (isBetaSiteOrEnvironment===true))
    {
      FeatureDirectivesHelper.setElementProperties(el, false, 'red');
    }
    else if (this.isBetaFeature === true)
    {
      FeatureDirectivesHelper.setElementProperties(el, true, null);
    }
  }
}

@Directive({
  selector: '[appAlphaFeature]'
})
export class AlphaFeatureDirective  {

  constructor(private el: ElementRef, private envService : EnvService, private api: ApiInterfaceService) { }
  
  isAlphaFeature : boolean = false;

  @Input() set appAlphaFeature (value : boolean) 
  {
      this.isAlphaFeature = value;
      this.api.getPreLoadDataAndSetGlobals().subscribe(
        res=> {
          
          this.setVisibility(this.el, res.apiKeyDetails);
        }
      );
  }

  private setVisibility (el : ElementRef, apiKey : ApiKeys) 
  {

    var isAlphaSiteOrEnvironment : boolean = this.envService.isAlphaSiteOrEnvironment(apiKey);
      
    if ((this.isAlphaFeature === true) && (isAlphaSiteOrEnvironment === true))
    {
      FeatureDirectivesHelper.setElementProperties(el, false, 'green');
    }
    else if (this.isAlphaFeature === true)
    {
      FeatureDirectivesHelper.setElementProperties(el, true, null);
    }
  }
}

class FeatureDirectivesHelper
{
  private getElementType (el: ElementRef) : eHtmlElemTypes
  {
    var htmlElem = <HTMLElement> el.nativeElement; 
    if (htmlElem.nodeName === 'A')
    {
      return eHtmlElemTypes.Anchor;
    }
    else if (htmlElem.nodeName === 'LABEL')
    {
      return eHtmlElemTypes.Label;
    }
    else if (htmlElem.nodeName === 'DIV')
    {
      return eHtmlElemTypes.Div;
    }
    else
    {
      return eHtmlElemTypes.Unknown;
    }

  }

  public static setElementProperties (el: ElementRef, hide : boolean, color : string = null)
  {
    var htmlElem = <HTMLElement> el.nativeElement; 
    if (hide)
    {
      htmlElem.hidden = true;  
    }
    if (color != null)
    {
      htmlElem.style.color = color;
    }

  }
}