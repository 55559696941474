


<div [innerHtml]="userSeletInfo.prompt"> </div>


<!-- <p>{{userSeletInfo.prompt}}</p> -->

<div mat-dialog-actions>


    <button *ngIf="userSeletInfo.showOkOnly===true" (click)="onNoClick()" class="btn btn-primary btn-spacing">Ok</button>

    <button *ngIf="userSeletInfo.showOkOnly!==true" style="margin: 2px;" [mat-dialog-close]="true" class="btn btn-primary btn-spacing" [class]="(userSeletInfo.yesIsGood === true) ? '' : 'btn-bad'">Yes</button>

    <button *ngIf="userSeletInfo.showOkOnly!==true" (click)="onNoClick()" class="btn btn-secondary btn-spacing btn-no" [class]="(userSeletInfo.yesIsGood === true) ? 'btn-bad' : ''">No</button>

</div>
