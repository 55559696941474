export class UiErrorHelper 
{
    private static isString(value) : boolean
    {
      return typeof value === 'string' || value instanceof String;
    }
  

    public static getErrorReason (errorMessage : any) : string
    {
      try {
        if ((errorMessage) && (errorMessage?.error) && (errorMessage?.error?.message) && (errorMessage?.error?.message?.errors))
        {
          if (errorMessage?.error?.message?.errors.length > 0)
          {
            var err  =  errorMessage?.error?.message?.errors[0]?.errorMessage;
            return err;
          }
          else
          {
            return null;
          }
       }
       else
       {
        return null;
       }
          
      } catch (error) {
        return null;        
      }

      
    }

    public static buildErrror ( errorMessage : any, 
      errorTitle : string) : ErrorDetails
    {
      var status : string = null;
      var statusText : string = null;
      var errorDetails : ErrorDetails = null; 
  
      var reason = this.getErrorReason(errorMessage);

      if (!(errorMessage))
      {
        errorMessage = "Undefined Error";
      }
      else if (reason)
      {
        errorMessage = reason;
      }
      else if (errorMessage?.status == 550)
      {
        errorMessage = 'iHealth api could not be reached.';
      }
      else if (this.isString(errorMessage))
      {
      }
      else if (errorMessage?.error && (this.isString(errorMessage.error)))
      {
        statusText = null;
        errorMessage = `${errorMessage?.error}`;
      }
      else if (errorMessage?.status)
      {
        status = errorMessage.status;
        statusText = errorMessage?.statusText;
        errorMessage = `${statusText} (${status})`;
      }
      else if (errorMessage?.statusText)
      {
        errorMessage = errorMessage.statusText;
      }
      else if (errorMessage?.message)
      {
        errorMessage = errorMessage?.message;
      }
      else
      {
        errorMessage = JSON.stringify(errorMessage);
      }
  
      if ((errorMessage == "401") || (errorMessage == "403") || (status == '401') || status == '403')
      {
  
        errorDetails = new ErrorDetails("You are not authorized to perform the given operation", "Access Denied", true);
  
      }
      else if ((errorMessage == "409") || (status == '409'))
      {
  
        errorDetails = new ErrorDetails("The entity you are trying to create already exists.", "Conflict");
  
      }
      else if ((errorMessage == "404") || (status == '404'))
      {
  
        errorDetails = new ErrorDetails("The entity could not be found.", "Not Found");
  
      }

      else if (errorMessage == "500")
      {
        errorDetails = new ErrorDetails("Something went wrong trying to perform the operation. Please try again. If the problem persists please contact Alchemed.", "Something went wrong");
      }
      else
      {
        errorDetails = new ErrorDetails(errorMessage, errorTitle);
      }
      return errorDetails;
    }
  

    public static isNotFoundEntityError(errorMessage : any,) : boolean
    {
        if (errorMessage?.status)
        {
          if (this.isStatusEquivalent(errorMessage?.status , 404))
          {
            return true;
          }
        }
        return false;
    }
    
    private static isStatusEquivalent(status : any, checkValue: number) : boolean
    {
      if (isNaN(status))
      {
        return false;
      }
      else
      {
        return (checkValue === +status );
      }
    }


}

export class ErrorDetails 
{
    constructor(public message: string, public title: string, public authError : boolean = false)
    {
  
    }
}