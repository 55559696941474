import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WindowCloseButtonComponent } from './window-close-button.component';
import { MatButtonModule } from '@angular/material/button';
import { WindowCloseButtonRoutingModule } from '../window-close-button-routing/window-close-button-routing.module';
import { AngularSvgIconModule } from 'angular-svg-icon';



@NgModule({
  declarations: [WindowCloseButtonComponent],
  imports: [
    CommonModule, 
    AngularSvgIconModule,
    MatButtonModule, 
    WindowCloseButtonRoutingModule
  ], 
  exports: [WindowCloseButtonComponent]
})
export class WindowCloseButtonModule { }
