import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HtmlFileViewerComponent } from './html-file-viewer.component';



@NgModule({
  declarations: [HtmlFileViewerComponent],
  imports: [
    CommonModule
  ],
  exports: [HtmlFileViewerComponent]
})
export class HtmlFileViewerModule { }
