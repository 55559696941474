import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Input } from '@angular/core';

@Component({
  selector: 'app-window-close-button',
  templateUrl: './window-close-button.component.html',
  styleUrls: ['./window-close-button.component.scss']
})
export class WindowCloseButtonComponent implements OnInit {

  constructor() { }
  @Input()
  public dialogRef : MatDialogRef<any>;

  ngOnInit(): void {
  }
  
  onNoClick(): void {
    this.dialogRef.close();
  }

  
  
}
