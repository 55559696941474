import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';


import MimeBuilder from 'emailjs-mime-builder';
import { base64Encode } from 'emailjs-mime-codec';

@Injectable()
export class EmailMsgService {
  constructor() {}

//   generateMsgFile(subject: string, body: string, recipient: string): void {
//     const message = new Message();

//     message.setSubject(subject);
//     message.setBody(body);
//     message.addRecipient(recipient);

//     const msgFile = message.toBuffer();

//     const blob = new Blob([msgFile], { type: 'application/vnd.ms-outlook' });
//     saveAs(blob, 'email.msg');
//   }

//   generateMsgFile(): void {
//     const content = 'This is a sample content for the .msg file';

//     // Create a blob with the content and correct MIME type
//     const blob = new Blob([content], { type: 'application/vnd.ms-outlook' });
    
//     // Create a link element
//     const link = document.createElement('a');
    
//     // Create a URL for the blob and set it as the href attribute
//     link.href = window.URL.createObjectURL(blob);
    
//     // Set the download attribute with a filename
//     link.download = 'sample.msg';
    
//     // Append the link to the document body
//     document.body.appendChild(link);
    
//     // Programmatically click the link to trigger the download
//     link.click();
    
//     // Remove the link from the document
//     document.body.removeChild(link);
//   }


saveEmlFile(content: string, filename: string) {
    const blob = new Blob([content], { type: 'message/rfc822' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

generateEmlFileold(
  from: string,
  to: string,
  cc: string[] = [],
  subject: string,
  text: string,
  attachments: { filename: string; content: string | File; mimeType?: string }[] = []
) {
  // Create a new MIME builder instance
  const builder = new MimeBuilder('multipart/mixed')
    .setHeader('From', from)
    .setHeader('To', to)
    .setHeader('Subject', subject)
    .setHeader('X-Unsent', '1'); // Mark email as unsent

  // Add CCs if provided
  if (cc.length > 0) {
    builder.setHeader('Cc', cc.join(', '));
  }

  // Add a text part
  builder.createChild('text/plain').setContent(text);

  // Function to finalize and save the EML file
  function finalizeEmlFile() {
    // Build the MIME message
    const mimeMessage = builder.build();

    // Create the EML content
    const emlContent = mimeMessage.toString();

    // Save the .eml file
    const blob = new Blob([emlContent], { type: 'message/rfc822' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'email.eml';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  // Add attachments if provided
  if (attachments.length > 0) {
    let attachmentsProcessed = 0;
    attachments.forEach((attachment) => {
      const mimeType = attachment.mimeType || 'application/octet-stream';
      const attachmentPart = builder.createChild(mimeType, {
        filename: attachment.filename,
        'Content-Disposition': `attachment; filename="${attachment.filename}"`,
      });

      if (attachment.content instanceof File) {
        const reader = new FileReader();
        reader.onload = function () {
          const base64Content = base64Encode(reader.result as string);
          attachmentPart.setContent(base64Content);
          attachmentPart.setHeader('Content-Transfer-Encoding', 'base64');
          attachmentsProcessed++;
          if (attachmentsProcessed === attachments.length) {
            finalizeEmlFile();
          }
        };
        reader.readAsBinaryString(attachment.content);
      } else {
        if (mimeType.startsWith('text/')) {
          // For text attachments, do not base64 encode the content
          attachmentPart.setContent(attachment.content);
          attachmentPart.setHeader('Content-Transfer-Encoding', '7bit');
        } else {
          // For binary attachments, base64 encode the content
          const base64Content = base64Encode(attachment.content);
          attachmentPart.setContent(base64Content);
          attachmentPart.setHeader('Content-Transfer-Encoding', 'base64');
        }
        attachmentsProcessed++;
        if (attachmentsProcessed === attachments.length) {
          finalizeEmlFile();
        }
      }
    });
  } else {
    // If no attachments, finalize immediately
    finalizeEmlFile();
  }
}


generateEmlFileWorksForText(
  from: string,
  to: string,
  cc: string[] = [],
  subject: string,
  text: string,
  attachments: { filename: string; content: File; mimeType?: string }[] = []
) {
  // Create a new MIME builder instance
  const builder = new MimeBuilder('multipart/mixed')
    .setHeader('From', from)
    .setHeader('To', to)
    .setHeader('Subject', subject)
    .setHeader('X-Unsent', '1'); // Mark email as unsent

  // Add CCs if provided
  if (cc.length > 0) {
    builder.setHeader('Cc', cc.join(', '));
  }

  // Add a text part
  builder.createChild('text/plain').setContent(text);

  // Function to finalize and save the EML file
  function finalizeEmlFile() {
    // Build the MIME message
    const mimeMessage = builder.build();

    // Create the EML content
    const emlContent = mimeMessage.toString();

    // Save the .eml file
    const blob = new Blob([emlContent], { type: 'message/rfc822' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'email.eml';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  // Function to base64 encode content
  function base64Encode(content: ArrayBuffer): string {
    let binary = '';
    const bytes = new Uint8Array(content);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  }

  // Add attachments if provided
  if (attachments.length > 0) {
    let attachmentsProcessed = 0;

    attachments.forEach((attachment) => {
      const mimeType = attachment.mimeType || attachment.content.type || 'application/octet-stream';
      const attachmentPart = builder.createChild(mimeType, {
        filename: attachment.filename,
        'Content-Disposition': `attachment; filename="${attachment.filename}"`,
      });

      const reader = new FileReader();
      reader.onload = function () {
        const arrayBuffer = reader.result as ArrayBuffer;
        if (mimeType.startsWith('text/')) {
          // For text attachments, use plain text encoding
          const textContent = new TextDecoder().decode(arrayBuffer);
          attachmentPart.setContent(textContent);
          attachmentPart.setHeader('Content-Transfer-Encoding', '7bit');
        } else {
          // For binary attachments, base64 encode the content
          const base64Content = base64Encode(arrayBuffer);
          attachmentPart.setContent(base64Content);
          attachmentPart.setHeader('Content-Transfer-Encoding', 'base64');
        }
        attachmentsProcessed++;
        if (attachmentsProcessed === attachments.length) {
          finalizeEmlFile();
        }
      };
      reader.readAsArrayBuffer(attachment.content);
    });
  } else {
    // If no attachments, finalize immediately
    finalizeEmlFile();
  }
}


generateEmlFileForTextAttachments(
  from: string,
  to: string,
  cc: string[] = [],
  subject: string,
  text: string,
  attachments: { filename: string; content: File; mimeType?: string }[] = []
) {
  const builder = new MimeBuilder('multipart/mixed')
    .setHeader('From', from)
    .setHeader('To', to)
    .setHeader('Subject', subject)
    .setHeader('X-Unsent', '1'); // Mark email as unsent

  if (cc.length > 0) {
    builder.setHeader('Cc', cc.join(', '));
  }

  builder.createChild('text/plain').setContent(text);

  function finalizeEmlFile() {
    const mimeMessage = builder.build();
    const blob = new Blob([mimeMessage], { type: 'message/rfc822' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'email.eml';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  if (attachments.length > 0) {
    let attachmentsProcessed = 0;

    attachments.forEach((attachment) => {
      const mimeType = attachment.mimeType || attachment.content.type || 'application/octet-stream';
      const attachmentPart = builder.createChild(mimeType, {
        filename: attachment.filename,
        'Content-Disposition': `attachment; filename="${attachment.filename}"`,
      });

      const reader = new FileReader();
      reader.onload = function () {
        const arrayBuffer = reader.result as ArrayBuffer;
        handleTextAttachment(attachmentPart, arrayBuffer);
        attachmentsProcessed++;
        if (attachmentsProcessed === attachments.length) {
          finalizeEmlFile();
        }
      };
      reader.readAsArrayBuffer(attachment.content);
    });
  } else {
    finalizeEmlFile();
  }
}


generateEmlFileForBinaryAttachments(
  from: string,
  to: string,
  cc: string[] = [],
  subject: string,
  text: string,
  attachments: { filename: string; content: Blob; mimeType?: string }[] = []
) {
  const builder = new MimeBuilder('multipart/mixed')
    .setHeader('From', from)
    .setHeader('To', to)
    .setHeader('Subject', subject)
    .setHeader('X-Unsent', '1'); // Mark email as unsent

  if (cc.length > 0) {
    builder.setHeader('Cc', cc.join(', '));
  }

  builder.createChild('text/plain').setContent(text);

  function finalizeEmlFile() {
    const mimeMessage = builder.build();
    const blob = new Blob([mimeMessage], { type: 'message/rfc822' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'email.eml';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  if (attachments.length > 0) {
    let attachmentsProcessed = 0;

    attachments.forEach((attachment) => {
      const mimeType = attachment.mimeType || attachment.content.type || 'application/octet-stream';
      const attachmentPart = builder.createChild(mimeType, {
        filename: attachment.filename,
        'Content-Disposition': `attachment; filename="${attachment.filename}"`,
        'Content-Transfer-Encoding': 'base64'
      });

      const reader = new FileReader();
      reader.onload = function () {
        const arrayBuffer = reader.result as ArrayBuffer;
        handleBinaryAttachment(attachmentPart, arrayBuffer);
        attachmentsProcessed++;
        if (attachmentsProcessed === attachments.length) {
          finalizeEmlFile();
        }
      };
      reader.onerror = function (error) {
        console.error(`Error reading file ${attachment.filename}:`, error);
      };
      reader.readAsArrayBuffer(attachment.content);
    });
  } else {
    finalizeEmlFile();
  }
}

}




function handleBinaryAttachment(attachmentPart: any, arrayBuffer: ArrayBuffer) {
  const base64String = arrayBufferToBase64(arrayBuffer);
  console.log(`Base64 string for attachment:`, base64String);
  attachmentPart.setContent(base64String);
}

function arrayBufferToBase64(buffer: ArrayBuffer): string {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  for (let i = 0; i < bytes.byteLength; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  const base64String = btoa(binary);
  console.log(`Converted ArrayBuffer to Base64:`, base64String);
  return base64String;
}
// function handleBinaryAttachment(attachmentPart: any, arrayBuffer: ArrayBuffer) {
//   attachmentPart.setContent(arrayBuffer);
// }


function handleTextAttachment(attachmentPart: any, arrayBuffer: ArrayBuffer) {
  const text = new TextDecoder().decode(arrayBuffer);
  attachmentPart.setContent(text);
}
