import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UiHelper } from '@app/_helpers/ui.helpers';
import { WebApiInterfaceService } from '@app/_services/alchemint.webapiinterface.service';
import { PatientService } from '@app/_services/patient.service';
import { RecordingService } from '@app/_services/recording.service';

@Component({
  selector: 'app-training-recorder',
  templateUrl: './training-recorder.component.html',
  styleUrls: ['./training-recorder.component.scss'],
  providers: [RecordingService]
})
export class TrainingRecorderComponent implements OnInit {

  public recordMode: boolean = false;
  @Input() public replayMode: boolean = false;
  @Input() loadedModuleName: string = null;
  
  constructor(private recordingService: RecordingService, route: ActivatedRoute, private patientService: PatientService, private webapi: WebApiInterfaceService) 
  { 
    route.queryParams.subscribe(params => {
      this.recordMode = params['recordmode'] === 'true';
    });
  }

  ngOnInit(): void {
  }

  recording = false;
  replaying = false;
  saveFileName: string;
  recordingTitle: string;

  toggleRecording() {
    if (this.recording) {
      this.recordingService.stopRecording();
      this.recording = !this.recording;
    } else {

      var fileName = this.loadedModuleName?.replace('Component','');

      this.patientService.userEnterTextWithPrompt('Please enter a name for the events file', `${fileName}.json`).subscribe((fileName) => {
        if (fileName) {
          this.saveFileName = fileName;
          this.recordingTitle = UiHelper.camelCaseToReadable (fileName?.replace('.json', ''));

          this.recordingService.setFileName(fileName);
          this.recordingService.startRecording();
          this.recording = !this.recording;
        }
      });
    }

  }

  replay() {
    const events = this.recordingService.getEvents();
    const replayContainer = document.getElementById('replayContainer');
    this.replaying = true;
    if (replayContainer) {
      this.recordingService.replayEvents(events, replayContainer, this.onReplayFinish.bind(this));
    }
  }

  replayForText(text: string) {
    const events = JSON.parse(text);
    const replayContainer = document.getElementById('replayContainer');
    this.replaying = true;
    if (replayContainer) {
      this.recordingService.replayEvents(events, replayContainer, this.onReplayFinish.bind(this));
    }
  }
  public saveRecording() {
    this.recordingService.downloadEvents();
  }

  onReplayFinish() {
    this.replaying = false;
  }


  public loadEventsFromFile(file: File): void {
    this.recordingService.loadEventsFromFile(file);
  }
  
  public onFileChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.loadEventsFromFile(input.files[0]);
    }
  }
  public triggerFileInput() {
    // Programmatically trigger the file input click event
    document.getElementById('fileInput').click();
  }

  // public async getFileContentsFromFile(filename: string): Promise<any[]>
  // {
  //   return await this.recordingService.getFileContentsFromFile(filename);g
  // }

  public replayNamedFile (fileName: string)
  {


    this.recordingService.getFileContentsFromFile(fileName).subscribe
      (
        (res) => {
          
          const replayContainer = document.getElementById('replayContainer');
          this.replaying = true;
          if (replayContainer) {
          try {
            this.recordingService.replayEvents(res, replayContainer, this.onReplayFinish.bind(this));
          } catch (error) {
            alert(error);
          }
            
          }
        },
        (err) => {
          alert(err);
        } 
      );

    // var res: string = await this.recordingService.getFileContentsFromFile(fileName);
    
    // var events = JSON.parse(res);
    // const replayContainer = document.getElementById('replayContainer');
    // this.replaying = true;
    // if (replayContainer) {
    //   this.recordingService.replayEvents(events, replayContainer, this.onReplayFinish.bind(this));
    // }
    
    
   
  }


  storeInApi()
  {
    var events = JSON.stringify(this.recordingService.getEvents(), null, 4);
    this.webapi.createArtifactWithTextdataForSpecificApiKey("HD", "REPLAY", this.saveFileName, "PAT", JSON.stringify(this.recordingService.getEvents())).subscribe(
      (res) => {
        alert("Stored");
      },
      (err) => {
        alert(err);
      }
    );
  }


}
