<style>
  mat-dialog-container {
    background: grey !important;
  }

  .dialog-mode {
    background: grey !important;
    height: 70vh;
    overflow-y: auto;
    max-height: 17cm;
  }

  .app-window-close-button {
    padding-bottom: 1em;
    margin-bottom: 1em;
  }
</style>




<div (paste)="handlePaste($event)">



  <div>
    <app-window-close-button [dialogRef]="modal" *ngIf="inDialogMode===true">

    </app-window-close-button>

  </div>

  <div *ngIf="cameraVisible===true">
    <app-camera style="width : 100%;"></app-camera>
  </div>



  <app-svg [hidden]="aiPasting===true"
    *ngIf="(isClipboardContentImage===true) && (inDialogMode===true)" color="green"
    (click)="magicClipBoardPaste()"
    title="Use Patient Record AI Image Extraction from File on clipboard">auto_fix_high</app-svg>

  <app-svg *ngIf="(inDialogMode===true)" color="green"
    (click)="magicCameraPatientCreation()"
    title="Use Patient Record AI Image Extraction from Photo">photo_camera</app-svg>


  <app-thinking-bubble [thinking]="aiPasting===true"></app-thinking-bubble>
  <div [hidden]="cameraVisible===true">



    <BR *ngIf="inDialogMode===true">

    <div>
      <BR *ngIf="inDialogMode===true">

      <mat-button-toggle-group *ngIf="tabbedMode" name="fontStyle" aria-label="Font Style">
        <mat-button-toggle [value]="PATIENT_TAB_SELECTOR"
          (change)="onTabValChange($event.value)">
          
          <app-svg *ngIf="mobileDevice===true" color="green">person</app-svg>
          <span *ngIf="mobileDevice===false">Patient</span>
          
        
        </mat-button-toggle>
        <mat-button-toggle [value]="CONTACT_TAB_SELECTOR" (change)="onTabValChange($event.value)">
          
          <app-svg *ngIf="mobileDevice===true" color="green">phone_iphone</app-svg>
          <span *ngIf="mobileDevice===false">Contact Details</span>

          
        </mat-button-toggle>
        <mat-button-toggle [value]="BIO_TAB_SELECTOR" (change)="onTabValChange($event.value)">
        
          <app-svg *ngIf="mobileDevice===true" color="green">account_circle</app-svg>
          <span *ngIf="mobileDevice===false">Biographical Details</span>

        </mat-button-toggle>
        <mat-button-toggle [value]="PERS_RESP_TAB_SELECTOR" (change)="onTabValChange($event.value)">
        
          <app-svg *ngIf="mobileDevice===true" color="green">supervisor_account</app-svg>
          <span *ngIf="mobileDevice===false">Person Responsible For Account</span>

          
        </mat-button-toggle>
      </mat-button-toggle-group>


      <div [ngClass]="dynamicClass">
        <app-external-person [hidden]="visible==false" (patientCreated)="addOrEditCompleted($event)"
          (patientUpdated)="addOrEditCompleted($event)" style="width: 100%;"></app-external-person>

      </div>





    </div>




  </div>





</div>