<div class="otp-form-conatiner">
  <p>Enter your one-time password below</p>
  <form #otpForm="ngForm" class="otp-inputs" #otpInputs>
    <input type="text" #digit1 maxlength="1" ngModel pattern="[0-9]" required name="digit1" required
      (keyup)="handleDigitInput($event, null,   digit2)">
    <input type="text" #digit2 maxlength="1" pattern="[0-9]" required name="digit2" ngModel required
      (keyup)="handleDigitInput($event,digit1,   digit3)">
    <input type="text" #digit3 maxlength="1" pattern="[0-9]" required name="digit3" ngModel required
      (keyup)="handleDigitInput($event, digit2,  digit4)">
    <input type="text" #digit4 maxlength="1" pattern="[0-9]" required name="digit4" ngModel required
      (keyup)="handleDigitInput($event, digit3, digit5)">
    <input type="text" #digit5 maxlength="1" pattern="[0-9]" required name="digit5" ngModel required
      (keyup)="handleDigitInput($event,digit4,  digit6)">
    <input type="text" #digit6 maxlength="1" pattern="[0-9]" required name="digit6" ngModel required
      (keyup)="handleDigitInput($event, digit5, null)">
  </form>
  <div>
    <button class="btn btn-primary submit-otp" [disabled]="otpForm.invalid" (click)="verify(otpForm)"> Verify</button>
  </div>

  <div style="margin-top: 15px;" *ngIf="allowOtpResending">
    <p style="display: flex; align-items: center;">Didn't receive code?  <a class="resend-otp" (click)="resendCode()">Resend code</a>
      <mat-spinner *ngIf="resending" [diameter]="15" style="margin: 0 4px;" ></mat-spinner>
    </p>
  </div>

  <div *ngIf="showRecoveryOptions" style="margin-top: 5px;">
    <p>Unable to complete verification? <a class="recovery-link"
        (click)="showRecoveryOptionForm = !showRecoveryOptionForm"> Show recovery options</a> </p>
  </div>

  <div *ngIf="showRecoveryOptionForm" style="display: flex; flex-direction: row;">
    <mat-radio-group aria-label="Select recovery option" [(ngModel)]="recoveryOption">
      <mat-radio-button value="EMAIL">Email</mat-radio-button>
      <mat-radio-button value="SMS">SMS</mat-radio-button>
      <mat-radio-button value="SUPPORT">Contact Support</mat-radio-button>
    </mat-radio-group>
    <button class="btn btn-primary" style="margin: 0 12px;" *ngIf="canRequestRecoveryCode" (click)="requestRecoveryOtp()"> Request Recovery
      Code</button>

  </div>

  <div *ngIf="showSupportContactLink">
    <label >
      You can contact a support consultant via the <a href="https://www.alchemed.com" target="_blank" >Alchemed website</a>
    </label>

  </div>

  <div *ngIf="error" class="alert alert-danger mt-3 mb-0">
    {{error}}
  </div>
</div>
