import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router'
import  *  as Alchemint from '@app/_alchemint/alchemint_dm';
import { ApiInterfaceService } from '@app/_services/alchemint.apiinterface.service'

@Component({
  selector: 'app-entityroot',
  templateUrl: './entityroot.component.html',
  styleUrls: ['./entityroot.component.scss']
})
export class EntityrootComponent implements OnInit {

  entityId : string = null;
  entity : Alchemint.Patient = null;
  loading : boolean = true;
  
  constructor(private activatedRoute : ActivatedRoute, private apiInterfaceService : ApiInterfaceService) { 
    this.activatedRoute.params.subscribe(params =>
      {
        this.loading = false;
        this.entityId = params['id'];
        this.apiInterfaceService.getEntity<Alchemint.Patient>(new Alchemint.Patient(), this.entityId).subscribe(entity => {this.entity = entity});
      });

  }

  ngOnInit(): void {
  }

}
