import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { Button , Buttons} from "./message-box.module";

@Component({
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.scss']
})
export class MessageBoxComponent {

  title = '';
  message = '';
  buttons: Buttons = this.Buttons.Ok;
  videoLink: string = '';
  moreInfoLink: string = '';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.title = data.title;
    this.message = data.message
    this.buttons = data.buttons;
    this.videoLink = data.videoLink;
    this.moreInfoLink = data.moreInfoLink;
  }

  dialogResultSubject = new Subject<Button>();
  dialogResult$ = this.dialogResultSubject.asObservable();

  public get Buttons(): typeof Buttons {
    return Buttons;
  }

  public get Button(): typeof Button {
    return Button;
  }

  click(button: Button) {
    this.dialogResultSubject.next(button);
  }
}
