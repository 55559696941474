import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeUrl } from '@angular/platform-browser';
import { AppUpdateService } from '@app/_services/app-update.service';

@Component({
  selector: 'app-html-file-viewer',
  templateUrl: './html-file-viewer.component.html',
  styleUrls: ['./html-file-viewer.component.scss'], 
  encapsulation: ViewEncapsulation.None  // This will allow external styles to be applied
})
export class HtmlFileViewerComponent implements OnInit {

  constructor(private appUpdateService: AppUpdateService, private sanitizer: DomSanitizer) { }


  ngOnInit(): void {
  }
  
  public htmlData: SafeHtml;
  public urlSafe: SafeUrl;
  @Input() set htmlFileold (value : string) 
  {
    this.appUpdateService.getServerHtmlFile(value).subscribe(
      res=> {
        this.htmlData = this.sanitizer.bypassSecurityTrustHtml(res);
        
      },
      err => {
        alert(err);
      }	
    );

  }


  @Input() set htmlFile(value: string) {
    this.appUpdateService.getServerHtmlFile(value).subscribe(
      res => {
        const blob = new Blob([res], { type: 'text/html' });
        const url = window.URL.createObjectURL(blob);
        this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      }
    );
  }
}
