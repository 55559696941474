import { Injectable } from '@angular/core';
import { WebApiInterfaceService } from './alchemint.webapiinterface.service';
import { AlchemintSharedService } from '@app/alchemint-shared.service';

@Injectable({
  providedIn: 'root'
})
export class IntegrationService {

  constructor(private webApiInterfaceService: WebApiInterfaceService, private alchemintSharedService: AlchemintSharedService) { }


  public synchronizeIntegratedUsers() : void
  {

    var loader : HTMLElement = document.getElementById("alchemed_app_loader_id");
    loader.hidden = false;

    this.webApiInterfaceService.doSynchronizeIntegratedUsers().subscribe(
      res => {
        loader.hidden = true;
        if (res[0] === true)
        {
          this.alchemintSharedService.openOkMessageBoxSync("Success", "Synchronization of users completed successfully");
        }
        else
        {
          this.alchemintSharedService.oppenErrDialogForObjectErr(res[1]);
        }
      }, 
      err => {
        loader.hidden = true;
        this.alchemintSharedService.oppenErrDialogForObjectErr(err);
      }, 
      () => { 
        loader.hidden = true;
      }
    );
  }
  
}
