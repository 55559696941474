import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes} from '@angular/router';
import { FormsModule } from '@angular/forms';
import { AuthGuard } from '@app/_models';
import { HtmlFileViewerComponent } from './html-file-viewer.component';
import { HtmlFileViewerModule } from './html-file-viewer.module';


const routes: Routes = [
  { path: '', component: HtmlFileViewerComponent, loadChildren: () => import('./html-file-viewer.module').then(m => HtmlFileViewerModule), canActivate: [AuthGuard]  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule], 

})
export class HtmlFileViewerRoutingModule { }



