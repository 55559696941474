import { Component, ViewChild, OnInit, AfterViewInit, EventEmitter, OnDestroy, Renderer2 } from '@angular/core';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationStart } from '@angular/router'
import { AuthenticationService, eSpecialFunctionalOrganizations } from './_services';
import { UserAlchemint } from './_models/user';
import * as Alchemint from '@app/_alchemint/alchemint_dm';
import { Observable, Subject, Subscription, catchError, first, from, map, take, throwError } from "rxjs";
import { ApiInterfaceService } from "./_services/alchemint.apiinterface.service"
import { WhiteLabellingService } from "@app/_alchemint/white-labelling.service"
import { Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { Location } from '@angular/common';
import { InitialSupportTicketDTO, ISupportReadOnlyMode, UIConfig } from './_alchemint/alchemint_composite_requests';
import { Environment, EnvService } from './_services/environment.service';
import { SettingsService } from './_services/settings-service.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { UtilityService } from './utility.service';
import { BillingRequestService } from './billing-request/billing-request.service';
import { eBillingMethods } from './_alchemint/alchemint_billing';
import { ProgressService } from './_services/progress.service';
import { UiHelper } from './_helpers/ui.helpers';
import { IAdvertiseDisplayableName } from './interfaces/interfaces';
import { WebApiInterfaceService } from './_services/alchemint.webapiinterface.service';
import { AlchemintSharedService } from './alchemint-shared.service';
import { IntegrationService } from './_services/integration.service';
import { UnhandledErrorLoggingService } from './_services/unhandled-error-logging-service';
import { UserAssistanceService } from './_services/user-assistance.service';
import { Buttons } from './message-box/message-box.module';
import { MultiFactorAuthenticationService } from './2fa/muti-factor-auth.service';
import { GuidedTourService } from './_services/guided-tour.service';
import { PayAsYouGoService } from './pay-as-you-go.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SwUpdate } from '@angular/service-worker';
import { HttpClient } from '@angular/common/http';
import { AppUpdateService } from './_services/app-update.service';
import { tr } from 'date-fns/locale';
import { RecordingService } from './_services/recording.service';
import { TrainingRecorderComponent } from './training-recorder/training-recorder.component';
import { ClickHighlightService } from './_services/click-highlight.service';
import { HelpDeskService } from './help-desk.service';
import { SpeechService } from './_services/speech-service';
import { BackofficeService } from './_services/backoffice.service';
import { ControlPanelComponent } from './control-panel/control-panel.component';
import { re } from 'mathjs';




@Component({ selector: 'app', templateUrl: 'app.component.html', styleUrls: ['app.component.css'], providers: [AppUpdateService, RecordingService, ClickHighlightService, HelpDeskService]})
export class AppComponent implements OnInit, ISupportReadOnlyMode, AfterViewInit, OnDestroy {
  //user: User;
  user: UserAlchemint;
  alchemintApplicationMetaData: Alchemint.AlchemintApplicationMetaData = new Alchemint.AlchemintApplicationMetaData();
  //backColor = '#' + this.alchemintApplicationMetaData.brandColorLight.toString(16);
  backColor = '#0000ff';

  companyHomeUrl: string;
  isAlchemintHosted: boolean;
  navItems: Alchemint.WebAppItem[];
  publicContentAccessKey: string;
  showAppFooter: boolean = false;
  showSideNav: boolean = false;
  showLogin: boolean = true;
  showBack: boolean = true;
  showSettings: boolean;
  showHome: boolean = true;
  tempHideDropDown: boolean = false;

  loadedComponentName: string
  loadedComponentNameObservable: Observable<string>;
  // _fullScreenEditMode : boolean = false;
  loggedIn: boolean = false;
  orgNameObservable$: Observable<string>;
  public get uiConfig(): UIConfig
  {
    return this.authenticationService?.UIConfig;
  }

  public get fullScreenEditMode(): boolean {
    return (this.utilityService?.fullScreenMode);
  }

  public get winAppIntegratedMode(): boolean {
    return (this.authenticationService.integratedLoggedIn === true)
  }


  public get cameraMode(): boolean {
    return this.utilityService?.cameraMode;
  }
    public get isLoggedInToBackOffice () : boolean
    {
      return this.backOfficeService.isLoggedOn;

    }
  public logoutBackOffice()
  {
    this.backOfficeService.logoutOfControlCentre();
  }
  
  public launchTaskManagerForUser()
  {
    if (this.authenticationService.userIdFromToken)
    {
      this.router.navigate([`taskmanagement/${this.authenticationService.userIdFromToken}`]);
    }
    
  }

  toggleVoiceCommandMode()
  {
    this.speechService.startListening();
    // if (this.speechService.isListening)
    // {
    //   this.speechService.stopListening();
    // }
    // else
    // {
    //   this.speechService.startListening();
    // }
    
  }

  @ViewChild('extendedMenu') extendedMenu: any;


  @ViewChild('sidenav') sidenav: MatSidenav;
  // @ViewChild(ExternalComponent) childExternalComponent: ExternalComponent;


  public controlPanelComponent: ControlPanelComponent;

  isExpanded = true;
  showSubmenu: boolean = false;
  isShowing = false;
  showSubSubMenu: boolean = false;
  isExternalApp: boolean = false;
  isBetaVersion: boolean = false;

  updateRequired: boolean = false;

  error: any;

  mouseenter() {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }

  mouseleave() {
    if (!this.isExpanded) {
      this.isShowing = false;
    }
  }

  back() {
    this._location.back();
  }
  public clientCodeVersion: string = "";

  reloadPageOrStopRecording(): void {

    if (this.trainingRecorderComponent?.recording) 
    {
      this.trainingRecorderComponent?.toggleRecording();
    }
    else
    {
      location.reload();
    }
    
  }


  public get showCommandCentre(): boolean
  {
    return (this.helpDeskService.getSupportDeckApiKey()?.length > 0);
  }



  


  options = [{ label: "One", id: "1" }, { label: "Two", id: "2" }, { label: "Three", id: "3" }];
  doSearch($event) {
    console.log($event)
  }

  onSelection(item) {
    console.log("ITEM", item)
  }


  public get shoDemoTools(): boolean {
    if (this.guidedTourService.isConferenceOrg === true) {
      return this.isLoggedIn && this.isDemoCompany && this.authenticationService.isMasterUser;
    }
    else {
      return this.isLoggedIn && this.isDemoCompany
    }


  }


  public showTraining: boolean = true;
  public routing: boolean = false;

  public resellerCode: string = null;

  subs: Subscription[] = [];
  @ViewChild(TrainingRecorderComponent)
  public trainingRecorderComponent: TrainingRecorderComponent;

  public recordMode: boolean = false;
  public aiIsHappening: boolean = false;
  constructor(
    private router: Router,
    public authenticationService: AuthenticationService, public api: ApiInterfaceService,
    private _location: Location, whiteLabellingService: WhiteLabellingService,
    public envService: EnvService,
    private utilityService: UtilityService,
    private billingRequestService: BillingRequestService,
    public progressService: ProgressService,
    private integrationService: IntegrationService,
    private unhandledErrorLoggingService: UnhandledErrorLoggingService,
    public userAssistanceService: UserAssistanceService,
    private alchemintSharedService: AlchemintSharedService,
    private multiFactorService: MultiFactorAuthenticationService,
    private settingsService: SettingsService,
    private guidedTourService: GuidedTourService,
    private deviceDetectorService: DeviceDetectorService,
    private webApiInterfaceService: WebApiInterfaceService,
    private swUpdate: SwUpdate,
    private http: HttpClient,
    public appUpdateService: AppUpdateService,
    private route: ActivatedRoute,
    private recordingService: RecordingService,
    private renderer: Renderer2, private clickHighlightService: ClickHighlightService, 
    private helpDeskService: HelpDeskService,
    private speechService : SpeechService, 
    private backOfficeService: BackofficeService
  ) {


    var subApi = api.orgApiKeyBehaveSubject$.subscribe(apiKey => {
      if (apiKey) {
        this.resellerCode = apiKey.category2;
      }
    },
      error => {
        console.error(error);
        subApi.unsubscribe();
      },
      () => {
        subApi.unsubscribe();
      }
    );

    route.queryParams.subscribe(params => {
      this.recordMode = params['recordmode'] === 'true';
      if (this.recordMode) {
        this.renderer.listen('document', 'click', (event) => {
          this.clickHighlightService.highlightElement(event.target);
        });
      }
    });

    var subPreloadUpdates = this.api.getPreLoadDataUpdates()
    .subscribe({
      next: (status) => {
        
        if (status) {
          this.loggedInUserId = authenticationService.userIdFromToken;
          this.debugSupportComponent = this.envService.isRunningInDev;

          var apiKey = this.authenticationService.getStoredApiKey();
          if (apiKey)
          {
          

            if (this.authenticationService.isiHealthIntegratedModeFromToken === true)
            {
              this.integratedMode = true;
            }
            else
            {
              this.integratedMode = false;
            }



            // this.showInAppSupport = (
            //   apiKey.startsWith('f231cc51-1eb1') || //Dale
            //   apiKey.startsWith('f3c634b7-6932') || //NM VDB
            //   apiKey.startsWith('18122b88') || // GRANT
            //   apiKey.startsWith('128fc378-19a5') || // GRANT also Web Help Storage
            //   apiKey.startsWith('f30904fe-d7b8') || // Grant Demo Companuy
            //   apiKey.startsWith('30e8c93a-6a83') || // WH
            //   apiKey.startsWith('49982f92')  || // Alchmed Deemo Practice for doing demos
            //    apiKey.startsWith('a0d2aec8-e74b')); // Ortho Conference
          }
          

        } else {
          console.error('Failed to load data');
        }
      },
      error: (err) => {
        this.alchemintSharedService.oppenErrDialogForObjectErr(err);
      }
    });
    this.webApiInterfaceService.aiEvent$.subscribe(event => {
      if (event) {
        this.aiIsHappening = true;
        
        if (this.webApiInterfaceService.userHasBeenWarnedAboutAI === false)
        {
          this.alchemintSharedService.openMessageBoxSync("AI Event", "Please be aware that all AI functionalities are provided to help perform better and safer medicine. Please be aware that any information provided by AI is not guaranteed to be accurate and all medical decisions remain yours.", Buttons.Ok);
          this.webApiInterfaceService.userHasBeenWarnedAboutAI = true;
        }




      }
    });

    this.webApiInterfaceService.aiEventFinished$.subscribe(event => {
      if (event) {
        this.aiIsHappening = false;
      }
    });

    authenticationService.requestAppLogout?.subscribe(x => {
      this.logout();
    });

    var sub1 = authenticationService.logInSuccess.subscribe(x => {
      
      this.loggedIn = true;
      if (this.authenticationService.useNewAuthenticationMethod) {
        this.user = this.authenticationService.getStoreLogidInUser();
      }
      // this.fireupPreLoadData();
    }
    );
    this.subs.push(sub1);

    this.isBetaVersion = envService.runningFromBetaFolder;

    if (this.authenticationService.useNewAuthenticationMethod) {
      this.user = this.authenticationService.getStoreLogidInUser();
    }
    else {
      var sub2 = this.authenticationService.user.subscribe(x => {
        this.user = x;
      }
      );
      this.subs.push(sub2);
    }

    if (whiteLabellingService.isAlchemint()) {
      this.showLogin = false;
      this.isAlchemintHosted = true;
      this.companyHomeUrl == "www.alchemint.com";
      var subs3 = api.getAlchemintSettings().subscribe(data => {
        if (data) {
          this.publicContentAccessKey = data.publicKey;
          var sub5 = api.getWebAppItems(this.publicContentAccessKey, 'nav').subscribe(webvappitems => {
            this.navItems = webvappitems;

          });

          this.subs.push(sub5);
        }
        else {
          alert("Settings NotFound");
        }
      });
      this.subs.push(subs3);
    }
    else {
      this.isAlchemintHosted = false;
      this.companyHomeUrl == "www.alchemed.com";

      this.UpdateIfRequired();

      this.startCheckingForUpdates(); 
    }

    
    this.orgNameObservable$ = this.api.orgName$();
    // TinyHelper.TinyInit();





    var sub4 = this.router.events.subscribe((event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.routing = true;
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel: {
          this.routing = false;
          break;
        }
      }
    });

    this.subs.push(sub4);

   
  }
  
  public loggedInUserId: string;
  public debugSupportComponent: boolean = false;
  public showSupportChat: boolean = false;
  
  
  
  private integratedMode : boolean = false;
  public get showInAppSupport (): boolean
  {
    if (this.integratedMode === false)
    {
      return true;
    }
    else
    {
      if (this.resellerCode === 'PracticePlus')
      {
        return true;
      }
      else
      {
        return false;
      }
    }

      
  }



  public currentSupportItem: Alchemint.SupportTicket = null;
  
  public openSupportChat(supportTicketChatItemDTO: InitialSupportTicketDTO): void
  {


    if (supportTicketChatItemDTO === null)
    {
      this.showSupportChat = true;
    }
    else
    {

      if (this.currentSupportItem?.id === supportTicketChatItemDTO?.supportTicket?.id)
      {
        this.showSupportChat = true;
      }
      else{
        this.currentSupportItem = supportTicketChatItemDTO.supportTicket;
        this.showSupportChat = true;
      }
      
      
    }
    
  }


  public get shouldShowSplashScreen(): boolean {
      return true;
  }

  public get isConfApiKey(): boolean
  {
    return this.authenticationService.getStoredApiKey()?.startsWith('a0d2aec8-e74b');
  }

  async startCheckingForUpdates() {
    
    var hoursToCheck = this.settingsService.getCheckForUpdateIntervalHours();
    var checkInterval = hoursToCheck * 60 * 60 * 1000; // 24 hours
    setInterval(() => {
     
      this.doBackGroundCheckForUpdates();
      
    }, checkInterval);
  }

  async doBackGroundCheckForUpdates()
  {
    var updateAvailable = await this.appUpdateService.isUpdateAvailable();
  }


  ngOnDestroy(): void {

    this.subs.forEach(sub => {
      console.log('Unsubscribing from ' + sub);
      sub.unsubscribe();
    });


  }

  // preloadDone : boolean = false;
  // dataLakeLicensed: boolean = false;
  // fireupPreLoadData()
  // {
  //   if ((this.loggedIn===true) && (this.preloadDone ===false))
  //   {

  //     this.preloadDone = true;
  //     var sub = this.api.getPreLoadDataAndSetGlobals().subscribe(
  //       data => {
  //         // this.api.orgNameBehaveSubject$.next(data.organisations[0].name);
  //         this.dataLakeLicensed = data.apiKeyDetails.dataLakeLicensed;
  //       }
  //     );
  //     this.subs.push(sub);
  //   }


  // }
  // private sizeScreenForCamera () : void
  // {
  //  this.fullscreen = true;
  //   this._fullScreenEditMode = true;
  // }

  // private sizeScreenForFull () : void
  // {
  //   this.fullscreen = true;
  //   this._fullScreenEditMode = true;
  // }

  // private sizeScreenForNormal () : void
  // {
  //   this.fullscreen = false;
  //   this._fullScreenEditMode = false;
  // }

  private UpdateIfRequired(): void {
    const lastUpdateTimeString = localStorage.getItem("lastupdatetime");
    const currentTime = new Date();

    if (lastUpdateTimeString) {
      const lastUpdate = new Date(lastUpdateTimeString);
      const minutesSinceLastUpdate = (currentTime.getTime() - lastUpdate.getTime()) / 1000 / 60;

      // If more than 1 minute has passed since the last update, refresh the page
      if (minutesSinceLastUpdate > 5) {
        localStorage.setItem("lastupdatetime", currentTime.toISOString());
        //window.location.reload();
        //window.location.href = window.location.href + "?reload=" + new Date().getTime();
        this.appUpdateService.checkForUpdates(true);

      }
      else
      {
        this.appUpdateService.checkForUpdates(false);
      }
    } else {
      // No last update time found, set the current time and do not force a reload.
      localStorage.setItem("lastupdatetime", currentTime.toISOString());
      this.appUpdateService.checkForUpdates(true);

      //window.location.reload();
      //window.location.href = window.location.href + "?reload=" + new Date().getTime();

    }
  }


  checkForUpdatesOld(forceUpdateIfRequired: boolean) {
    // Extract version from the currently loaded HTML document
    const currentVersion = this.appUpdateService.extractVersionFromMeta(document);
    this.appUpdateService.currentVersion = currentVersion;
    const baseUrl = window.location.href.split('#')[0]; // Remove fragment identifier
    
     this.http.get(baseUrl, { responseType: 'text' })
    
      .pipe(take(1))
      .subscribe(response => {
        // Convert the response into a DOM object
        const parser = new DOMParser();
        const fetchedDoc = parser.parseFromString(response, 'text/html');

        // Extract version from the fetched HTML document
        const fetchedVersion = this.appUpdateService.extractVersionFromMeta(fetchedDoc);
        this.appUpdateService.serverVersion = fetchedVersion;

        this.appUpdateService.versionCheckComplete = true;
        // Compare the fetched version with the current version
        if (fetchedVersion !== currentVersion) {
          console.log('New update found! Reloading...');
          if (forceUpdateIfRequired === true)
          {
            window.location.reload(); // Force reload from server, not from cache
          }
          //window.location.href = window.location.href + "?reload=" + new Date().getTime();
        } else {
          console.log('No updates found.');
        }
      }, error => {
        console.error('Error checking for updates:', error);
      });
  }
  
  forceUpdate()
  {
    // window.location.href  = window.location.href + "?reload=" + new Date().getTime();

    this.appUpdateService.checkForUpdates(true);
  }
  
  simulateUpdateRequired ()
  {
    this.appUpdateService.currentVersion = "1.0.0";
  }

  // private UpdateIfRequiredOld(): void {

  //   var prevUpgradedVersion: string = localStorage.getItem("disable_auto_update");

  //   if (prevUpgradedVersion) {
  //     //Skip auto update
  //     return;
  //   }

  //   var sub = this.api.getClientVersionInfo().subscribe(data => {

  //     this.clientCodeVersion = data.clientVersion;
  //     this.api.clientCodeVersion = data.clientVersion;

  //     var sub2 = this.api.clientVersionInSyncWithServerWebGuiApiCall(data.clientVersion).subscribe(
  //       result => {
  //         if (result == false) {
  //           this.updateRequired = true;
  //           var settingName: string = 'last_version_auto_updated';


  //           var prevUpgradedVersion: string = localStorage.getItem(settingName);


  //           if (!prevUpgradedVersion) {

  //             localStorage.setItem(settingName, data.clientVersion);
  //             console.log(`Updating client version from ${data.clientVersion}`);
  //             this.doUpdate();
  //           } else if (prevUpgradedVersion != data.clientVersion) {
  //             localStorage.setItem(settingName, data.clientVersion);
  //             this.doUpdate();
  //           }
  //           else {
  //             console.log('Previous client upgrade failed so not attempting to upgrade');
  //             localStorage.removeItem(settingName);
  //           }
  //         }
  //         else {
  //           // alert('Up to date');
  //           this.updateRequired = false;
  //           //  console.log('Client version in sync with server');
  //         }
  //       },
  //       error => {

  //         console.warn(JSON.stringify(error));
  //       }
  //     );
  //     this.subs.push(sub2);
  //   },
  //     err => {
  //       alert('Missing version file');
  //     }
  //   );

  //   this.subs.push(sub);
  // }


  doUpdate() {
    // location.reload();
    window.location.href = window.location.href;
  }

  ngAfterViewInit(): void {
    this.webApiInterfaceService.getRequestPlayObservable().subscribe(fileName => {
      if (fileName) {

        this.trainingRecorderComponent.replayNamedFile(fileName);
        // Logic to replay the rrweb event file using the fileName
        console.log('Replaying file:', fileName);
      }
    });


    this.webApiInterfaceService.getRequestPlayForTextObservable().subscribe(text => {
      if (text) {
        this.trainingRecorderComponent.replayForText(text);
      }
    });


    // if (this.envService.env === Environment.Dev)
    // {
    //   this.getPullZoneOrigin();
    // }


  }
  public showWhatsNew: boolean = false;
  ngOnInit() {

    var loader: HTMLElement = document.getElementById("alchemed_app_loader_id");
    if (loader) {
      loader.hidden = true;
    }
    try {
      this.buildVersion();
    }
    catch (e) {
      this.error = e;
    }

    try {
      var sub = this.router.events.subscribe((data) => {

        var url: any = data;

        if (url.url) {

          var urlString: string = url.url;
          if (urlString.startsWith('/external') || urlString.startsWith('/content')) {
            this.showWhatsNew = false;
            this.showSideNav = false;
            this.showLogin = false;
            this.showBack = false;
            this.isExternalApp = false;
            this.showSettings = false;
            this.showHome = false;
          }
          if (urlString.startsWith('/external')) {
            this.isExternalRequest = true;
          }
        }

      });
      this.subs.push(sub);



    } catch (error) {
      this.error = error;
    }





  }


  getDomZize(): number {
    return document.querySelectorAll('*').length;
  }



  // public get fullScreenEditMode () : boolean
  // {
  //   return this.fullscreen;
  // }


  public get eSpecialFunctionalOrganizationsType(): eSpecialFunctionalOrganizations {
    return this.authenticationService.eSpecialFunctionalOrganizationsType;
  }

  public eSpecialFunctionalOrganizationsTypeName(mode: eSpecialFunctionalOrganizations) {
    return this.authenticationService.eSpecialFunctionalOrganizationsTypeName(mode);
  }




  public get ReadOnlyMode(): boolean {
    return this.isReadOnlyLogin;
  }

  public get loggedInUserName(): string {

    if (this.authenticationService?.integratedLoggedIn) {
      return this.authenticationService?.integratedUserName;
    }
    else {
      return this.authenticationService?.userValue?.userName;
    }

  }

  public get isLoggedIn(): boolean {
    if (this.user) {
      return true;
    }
    else if (this.authenticationService?.integratedLoggedIn) {
      if (this.authenticationService?.integratedUserName) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  }

  public get isReadOnlyLogin(): boolean {

    if (this.authenticationService?.integratedLoggedIn) {
      return this.authenticationService?.integratedLoginIsReadOnly;
    }
    else {
      return this.authenticationService?.userValue?.readOnlyLogon;
    }

  }


  get isConferenceOrg(): boolean {
    return this.authenticationService.isConferenceOrg;
  }

  get isConferenceOrgBiotech(): boolean {
    return this.authenticationService.isBioteckConferenceOrg;
  }


  logout() {

    this.webApiInterfaceService.clearCache();

    try {

      this.api.clearCache();
      this.envService.orgNameBehaveSubject$.next(null);
    }
    catch (e) {

    }
    this.authenticationService.logout(true, true, true, true, true, true);

    if (this.authenticationService.useNewAuthenticationMethod) {
      this.user = null;
      this.loggedIn = false;
    }

    this.doUpdate();


    // if ((this.authenticationService?.userValue != null) || (this.authenticationService.integratedLoggedIn))
    // {
    //   this.authenticationService.logout(true, true);
    // }
  }

  public get showConsoleLogVisible(): boolean {
    return this.unhandledErrorLoggingService.consoleLoggingToCacheEnabled;
  }


  public get canManageUsers(): boolean {
    return (this.user?.masterPriveledges || this.user?.userName === 'master');
  }

  showConsoleLog() {
    this.unhandledErrorLoggingService.displayLogHistory();
    // Retrieve the console history and store it in a variable
    // var consoleHistory = console.history();

    // Display the console history in an alert message
    // alert(consoleHistory);
  }
  title = 'Alchemed';


  buyMessageBundle(): void {
    this.router.navigate(['/payasyougo']);
  }



  // artifacts : Alchemint.Artifact[];
  // patients : Alchemint.Patient[];
  // artifact : Alchemint.Artifact;
  // createdPatient : Alchemint.Patient;

  gotoSettings(): void {
    this.router.navigate(['settings']);
  }

  gotoInpatients(): void {
    this.router.navigate(['di/1']);
  }
  gotoClinicalNotes(): void {
    this.router.navigate(['di/0']);
  }

  getConfigMenuText() {
    var isMobileDevice: boolean = this.deviceDetectorService.isMobile();
    if (isMobileDevice === true) {
      return 'CONFIG';
    }
    else {
      return 'CONFIGURATION';
    }
  }



  launchAdvancedSearch(): void {

    this.router.navigate(['bisearch']);

    // if (this.dataLakeLicensed === true)
    // {
    //   this.router.navigate(['bisearch']);
    // }
    // else
    // {
    //   this.alchemintSharedService.openOkMessageBoxSync('Feature not Licensed', 'This feature is not enabled for your organisation. Please contact Alchemed to enable this feature.');
    // }

  }


  routerLink = ""



  async hideDropDown(): Promise<void> {
    if (this.extendedMenu) {
      this.tempHideDropDown = true;

      //this.extendedMenu.nativeElement.style.display = "none";
      this.sleep(500).then(
        () => {
          this.tempHideDropDown = false;
          //this.extendedMenu.nativeElement.style.display = "";
        }
      );

      //this.extendedMenu.nativeElement.hidden = true;
      //this.extendedMenu.nativeElement.hidden = false;
    }
  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  gotoAdminControlPanel(): void {

    this.hideDropDown();
    this.router.navigate(['admincp']);

  }


  public buildVersion(): void {
    var sub = this.api.getClientVersionInfo().subscribe(
      ver => {
        this.version = ver.clientVersion;
      }

    );
    this.subs.push(sub);
  }

  public updateClient(): void {
    location.reload();
  }

  version: string;


  public checkServerVersion(): void {
    var sub = this.api.clientVersionAtServerWebGuiApiCall().subscribe(
      version => {
        alert(version);
      }
    );

    this.subs.push(sub);
  }

  public get useDocker(): boolean {
    return this.envService.usingDocker;
  }
  public set useDocker(value: boolean) {
    this.envService.usingDocker = value;;
  }

  public get runningInDevMode(): boolean {
    return this.envService.env == Environment.Dev;
  }

  public get hostName(): string {
    var hostname: string = window && window.location && window.location.hostname;
    return hostname;
  }
  public showDemoTools(): boolean {
    if (this.guidedTourService.isConferenceOrg) {
      return (this.isLoggedIn) && (this.isDemoCompany) && (this.authenticationService.isMasterUser)
    }
    else {
      return (this.isLoggedIn) && (this.isDemoCompany)
    }

  }


  public setuseDockerSetting($event): void {
    var m: MatSlideToggleChange = $event;
    this.useDocker = m.checked;
  }

  public environmentName(): string {
    return this.envService?.environmnetName;
  }

  public get showEnvironmentName(): boolean {
    if (this.envService?.env == Environment.Prod) {
      return false;
    }
    else {
      return true;
    }

  }

  public get showHttpErrorCount(): boolean {
    if (this.envService?.env === Environment.Dev) {
      return true;
    }
    else {
      return false;
    }

  }

  public get httpErrorCount(): number {
    return this.envService?.httpErrorCount;
  }

  public get isBillingEnabled(): boolean {
    return ((this.billingRequestService?.billingMethod === eBillingMethods.eCoreBilling));
    ;
  }

  public get isIntegratedBillingEnabled(): boolean {
    return this.billingRequestService?.isIntegratedBillingEnabled;
  }


  public get IsRetinaAssistLicense(): boolean
  {
    
    if (this.authenticationService.IsRetinaAssistLicense)
    {
      return true;
    }
    else 
    {
      return false;
    }
  }



  public get showConfigureDiary(): boolean {
    var show: boolean = true;

    if (this.isIntegratedBillingEnabled === true) {
      show = false;
    }

    return show;
  }



  public mustHideTitleBar: boolean
  public mustHideFullScreenButton: boolean;
  // public get isBillingEnabled () : boolean
  // {
  //   return ((this.billingRequestService?.billingMethod === eBillingMethods.eCoreBilling) ||
  //    (this.billingRequestService?.billingMethod === eBillingMethods.eIntegratedBillingModule))
  //    ;
  // }
  public isSnapin: boolean = false;
  public routerOutletLoadedComponentName: string;
  componentAdded($event: EventEmitter<any>) {
    



    
    this.routerOutletLoadedComponentName = $event.constructor.name;
    
    if (this.routerOutletLoadedComponentName = 'ControlPanelComponent')
    {
      this.controlPanelComponent = (<ControlPanelComponent> (<any>$event));
    }


    if ((<any>$event)?.IsAdvertiseInterface === true) {
      this.isSnapin = true;
      
      var component: IAdvertiseDisplayableName= (<IAdvertiseDisplayableName>(<any>$event));
      
      if (component)
      {
        if (component?.DisplayableName !== null) {
          //this.loadedComponentName = (<IAdvertiseDisplayableName>(<any>$event)).DisplayableName;
          this.loadedComponentNameObservable = component?.DisplayableNameObservable;
        }
        this.mustHideTitleBar = component?.mustHideTitleBar;
        
        if (component?.onMustHideTitleBar)
        {
          component.onMustHideTitleBar.subscribe(x => {
            this.mustHideTitleBar = x;
          });
        }

        if (component?.onMustHideFullScreenButton)
          {
            component.onMustHideFullScreenButton.subscribe(x => {
              this.mustHideFullScreenButton = x;
            });
          }
  
          
      }

    }
    else {
      this.isSnapin = false;
      this.loadedComponentName = UiHelper.readableComponentName($event?.constructor?.name)
    }

  }
  isElectron(): boolean {
    return !!(window && window.process && window.process.type);
  }

  toggleFullScreenMode(): void
  {
    this.mustHideTitleBar = !this.mustHideTitleBar;
  }
  public get loggedInUserName2(): string {
    return this.authenticationService.GetStoredUserWithNoPassword()?.userName;
  }
  public gotoInvoicing(): void {
    //this.router.navigate(['billing/capture-invoice/1']);
  }

  public gotoReceipting(): void {
    this.router.navigate(['billing/receipt']);
  }

  public gotoDebtorsLedger(): void {
    this.router.navigate(['billing/debtorsledger'], { queryParams: { type: 'detailed' } });
  }

  public gotoDebtorsLedgerSummary() {
    this.router.navigate(['billing/debtorsledger'], { queryParams: { type: 'summary' } });
  }

  // public gotoStatement(customerId : string): void {
  //   this.router.navigate(['billing/statements/' + customerId]);
  // }
  public gotoStatements(): void {
    this.router.navigate(['billing/statements']);
  }

  public gotoCreditNotes(): void {
    this.router.navigate(['billing/creditnotes']);
  }

  public gotoReAllocatePayment(): void {
    this.router.navigate(['billing/payment-allocation']);
  }

  public synchronizeIntegratedUsers(): void {
    this.integrationService.synchronizeIntegratedUsers();
  }


  public isExternalRequest: boolean = false;

  public get isDemoCompany(): boolean {
    return this.authenticationService.isDemoCompanyFromToken;
  }

  toggleAssistedMode() {
    this.userAssistanceService.inUserAssistanceMode = !this.userAssistanceService.inUserAssistanceMode;
    if (this.userAssistanceService.inUserAssistanceMode) {
      this.alchemintSharedService.openMessageBoxSync("Assisted mode activated",
        "You are now in assisted mode which is great when you starting out with the product or are working in a module you have not used before. In this mode you will be guided through the process of using the product. " +
        "In this mode you will see little question mark icons next to various elements. Hover over these for an explanation of what the item does or how to use them. " +
        "You can turn this off at any time by clicking on the 'Assisted Mode' icon again.",
        Buttons.Ok)
    }

  }


  public get blurOn(): boolean {
    return this.settingsService.useBlurMode;
  }


  public configureMFA() {
    this.multiFactorService.suggestMFA();
  }
}
