import { Injectable } from '@angular/core';
import { AlchemintSharedService } from '@app/alchemint-shared.service';
import { WebApiInterfaceService } from './alchemint.webapiinterface.service';
import { Observable, firstValueFrom, from, take } from 'rxjs';

@Injectable()
export class AppUpdateService {
  
  


  constructor(private alchemintSharedService: AlchemintSharedService, private webApiInterfaceService: WebApiInterfaceService) 
  { }


  public currentVersion: string;
  public serverVersion: string;
  public versionCheckComplete: boolean = false;
  public updating: boolean = false;
  private softReloadKey = 'isAttemptingSoftReload';
  public lastUpdateTimeKey: string = 'lastUpdateTime';
  public async isUpdateAvailable(): Promise<boolean> {
    const currentVersion = this.extractVersionFromMeta(document);
    this.currentVersion = currentVersion;
    
    var responsestring = await firstValueFrom(this.getServerHtmlFile('index.html').pipe(take(1)));
    const parser = new DOMParser();
    const fetchedDoc = parser.parseFromString(responsestring, 'text/html');
    const fetchedVersion = this.extractVersionFromMeta(fetchedDoc);
    this.serverVersion = fetchedVersion;
    return (fetchedVersion !== currentVersion)
  }


  doUpdate(forceUpdateIfRequired: boolean)
  {
    console.log('New update found! Attempting reload...');
    if (forceUpdateIfRequired === true)  {
      
      if (this.wasTryingSofReload())
      {
        window.localStorage.removeItem(this.softReloadKey);
        alert('App update found. Please refresh the page to get the latest version.')
      }
      else
      {
        this.updating = true;
        setTimeout(() => {
          this.updating = false;
          
          
          window.localStorage.setItem(this.lastUpdateTimeKey, Date.now().toString()); // Mark that a soft reload was attempted
          window.localStorage.setItem(this.softReloadKey, 'true'); // Mark that a soft reload was attempted
          window.location.reload(); // Force reload from server, not from cache
          
        }, 1000);
        
        

      }
    }
  }

  public get lastAutoUpdateTime(): string{
    var lastUpdateTime = window.localStorage.getItem(this.lastUpdateTimeKey);
    if (lastUpdateTime)
    {
      return new Date(parseInt(lastUpdateTime)).toLocaleString();
    }
    else
    {
      return 'Never';
    }
  }


  // checkForUpdates(forceUpdateIfRequired: boolean): void {
  //   const currentVersion = this.extractVersionFromMeta(document);
  //   this.currentVersion = currentVersion;
  //   this.getServerIndexHtmlFile()
  //     .pipe(take(1))
  //     .subscribe(responsestring => {
  //       const parser = new DOMParser();
  //       const fetchedDoc = parser.parseFromString(responsestring, 'text/html');
  //       const fetchedVersion = this.extractVersionFromMeta(fetchedDoc);
  //       this.serverVersion = fetchedVersion;

  //       if (fetchedVersion !== currentVersion) {
  //         this.doUpdate(forceUpdateIfRequired);
  //       } else {
  //         console.log('No updates found.');
  //         window.localStorage.removeItem(this.softReloadKey); // Clean up if no update needed or after hard reload
  //       }
  //     }, error => {
  //       console.error('Error checking for updates:', error);
  //     });
  // }
  async checkForUpdates(forceUpdateIfRequired: boolean)  {
    
    try {
      var updateAvailable = await this.isUpdateAvailable();
    
      if (updateAvailable) {
        this.doUpdate(forceUpdateIfRequired);
      } else {
        console.log('No updates found.');
        window.localStorage.removeItem(this.softReloadKey); // Clean up if no update needed or after hard reload
      }

      this.versionCheckComplete = true;
    }
    catch (error) {
      console.error('Error checking for updates:', error);
    }
    
  }
  public getServerHtmlFile (fileName: string): Observable<string>
  {
    var baseUrl = window.location.href.split('#')[0]; // Remove fragment identifier
    
    baseUrl = baseUrl.replace('index.html', '')
    
    if (baseUrl.includes(fileName) === false)
    {
      baseUrl += (baseUrl.endsWith('/') ? `${fileName}` : `/${fileName}`);
    }

    var promise = fetch(baseUrl, 
      { method: 'GET',
        cache: 'no-store', // Ensure the response is not fulfilled by the cache
        headers: {
          'Content-Type': 'text/html'
        }
      });
      const myObservable = from(promise);  

      return new Observable<string>(observer => {
        
        myObservable.subscribe(response => {
          if (response.ok) {
            response.text().then(html => {
              observer.next(html);
              observer.complete();
            });
          } else {
            observer.error(response.statusText);
          }
        });
        
      });
      
  }



  extractVersionFromMeta(doc: Document): string {
    const metaTag = doc.querySelector('meta[name="app-version"]');
    return metaTag ? metaTag.getAttribute('content') : '';
  }



  private wasTryingSofReload(): boolean {
    return window.localStorage.getItem(this.softReloadKey) === 'true';
  }


}
