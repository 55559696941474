// floating-container.service.ts
import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable()
export class FloatingContainerService {
  private container: HTMLElement;
  private renderer: Renderer2;
s
  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.createContainer();
  }

  private createContainer() {
    this.container = this.renderer.createElement('div');
    this.renderer.setStyle(this.container, 'position', 'fixed');
    this.renderer.setStyle(this.container, 'top', '0');
    this.renderer.setStyle(this.container, 'left', '0');
    this.renderer.setStyle(this.container, 'right', '0');
    this.renderer.setStyle(this.container, 'z-index', '1000');
    this.renderer.setStyle(this.container, 'pointer-events', 'none');
    this.renderer.appendChild(document.body, this.container);
  }

  getContainer(): HTMLElement {
    return this.container;
  }
}
