import { Component, OnInit , Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogClose, MatDialogContent, MatDialogActions, MatDialogRef} from '@angular/material/dialog'; 
import { Artifact, ArtiForm, IAlchemintEntity } from '@app/_alchemint/alchemint_dm';
import { UserSeletInfo } from '@app/_alchemint/alchemint_composite_requests';
import { AuthenticationService, eSpecialFunctionalOrganizations } from '@app/_services';
import { GuidedTourService } from '@app/_services/guided-tour.service';

// export const UserSelectEntityNoneSelectionValue : string = "NONE_SELECTED";


export const RQ_PAT_DETAILS : string = "RQ_PAT_DETAILS";
export const RQ_PAT_SIGN_CONTRACTS : string = "RQ_PAT_SIGN_CONTRACTS";

@Component({
  selector: 'app-user-select-entity',
  templateUrl: './user-select-entity.component.html',
  styleUrls: ['./user-select-entity.component.scss'], 
 
})

export class UserSelectEntityComponent implements OnInit {


  public entities : any [];
  public selectedEntityId : string;
  public selectedEntity : any;
  public selectedEntities : any [] = [];
  public entType : any;
  public makeNoSelectionOptionAvailable : boolean = false;
  public allowMultiSelect : boolean = false;
  public includePatientSignContracts: boolean = false;
  public includePatientDetailsRequest: boolean = false;

  public requestPatientSignContracts: boolean = true;
  public requestPatientCompleteDetails: boolean = true;

  public matIconForEntity: string = 'article';
  public allowFiltering: boolean = true;
  public filterText: string = '';

  constructor(@Inject(MAT_DIALOG_DATA) public userSeletInfo: UserSeletInfo, 
    public dialogRef: MatDialogRef<UserSelectEntityComponent>, 
    public authenticationService: AuthenticationService,
    public guidedTourService: GuidedTourService) 
  { 
    this.entities = userSeletInfo.entities;
    this.makeNoSelectionOptionAvailable = userSeletInfo.makeSelectNonOptionAvailable;
    this.allowMultiSelect = userSeletInfo.allowMultiSelect;

    if (userSeletInfo.matIconForEntity)
    {
      this.matIconForEntity = userSeletInfo.matIconForEntity;
    }
    
    this.includePatientSignContracts = userSeletInfo.includePatientSignContracts
    this.includePatientDetailsRequest = userSeletInfo.includePatientDetailsRequest

    this.entities.forEach(
      x=> {
        this.checkboxDictionary[x.id] = false;
      }
    )
    
  }

  ngOnInit(): void {
    this.expandAllCategories();
  }
 
  
  selectEntity(entity : any)
  {
    if (this.allowMultiSelect === false)
    {
      this.selectedEntity = entity;
      this.selectedEntityId = entity.id;
      this.dialogRef.close(this.selectedEntity);
    }
    //this.dialogRef.close();
  }

  checkboxArray : boolean [] = [];
  
  checkboxDictionary: { [key: string]: boolean; } = {};
  submitForMultiselect()
  {

    // var formSelectionDetails: FormSelectionDetails = new FormSelectionDetails();

    this.selectedEntityId = '';
    for (let key in this.checkboxDictionary) {
      let value = this.checkboxDictionary[key];
      if (value===true)
      {
        this.selectedEntities.push (this.entities.find (x => x.id === key));
        //this.selectedEntityId += (this.selectedEntityId.length > 0 ? ',' : '') + key;
      }
    }

    if (this.requestPatientCompleteDetails===true)
    {
      var dummyFrm = new ArtiForm();
      dummyFrm.id = RQ_PAT_DETAILS;
      this.selectedEntities.push(dummyFrm)
    }

    if (this.requestPatientSignContracts===true)	
    {
      var dummyFrm = new ArtiForm();
      dummyFrm.id = RQ_PAT_SIGN_CONTRACTS;
      this.selectedEntities.push(dummyFrm)
    }

     this.dialogRef.close(this.selectedEntities);

    //this.dialogRef.close();
  }

  isBillableForm(entity : any) : boolean
  {
    if (entity.isBillableForm)
    {
      return (entity.isBillableForm === true);
    }
    else
    {
      return false;
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  explodeAtStart: boolean = true;


  getCategorise() : string []
  {
    
    if (this.entities?.length > 0)
    {
      this.entType  = typeof this.entities[0];

      if (this.entType == typeof(Artifact))
      {
        cats = ['Templates'];
        return cats;
      }
      else
      {
        var cats : string[] =  this.entities.map(item => (item.category) ? item.category : 'Uncategorized')
        .filter((value, index, self) => self.indexOf(value) === index)
        //.filter(x => (x.isGeneralForm ?? false) === false);

        if (this.filterText)
        {
          var filteredEntities = this.entities.filter(x => x.name.toLowerCase().indexOf(this.filterText.toLowerCase()) > -1);
          cats = cats.filter(x => filteredEntities.find(y => y.category === x));
          cats.push('Uncategorized');
        }


        if (cats)
        {
          // cats =  cats.sort((one, two) => (one > two ? -1 : 1));
          cats= cats.sort();
          return cats;
        }
        else
        {
          return cats;
        }

      }
      
    }
    else 
    {
      var dummy : string[] = []; 
      return dummy;
    }


    


  }
 
  moreThanOneCategory() : boolean
  {
    if (this.getCategorise().length > 1)
    {
      return true;
    }
    else
    {
      return false;
    }

  }


  expandedCategories: string[] = [];

  isCategoryExpanded(category: string): boolean {
    return this.expandedCategories.includes(category);
  }
  
  toggleCategory(category: string): void {
    if (this.isCategoryExpanded(category)) {
      this.expandedCategories = this.expandedCategories.filter(cat => cat !== category);
    } else {
      this.expandedCategories.push(category);
    }
  }
  

  expandOrCollapseAll()
  {
    if (this.expandedCategories.length > 0)
    {
      this.collapseAllCategories();
    }
    else
    {
      this.expandAllCategories();
    }
  }

  public get areCategoryCollapsed() : boolean
  {
    return (this.expandedCategories.length === 0);
  }


  expandAllCategories()
  {
    this.expandedCategories = this.getCategorise();

  }

  collapseAllCategories()
  {
    this.expandedCategories = [];
  }

  // expandCategory(category: string) {
  //   this.expandedCategories.push(category);
  // }
  
  // collapseCategory(category: string) {
  //   const index = this.expandedCategories.indexOf(category);
  //   if (index !== -1) {
  //     this.expandedCategories.splice(index, 1);
  //   }
  // }

  entitiesOfCategoryOfCategory (category : string) : any[]
  {
    
    if (this.entType == typeof(Artifact))
    {
      return this.entities;
    }
    else
    {
      if (this.entities != null)
      {
        var filt =  this.entities.filter(item => ((item.category) ? item.category : 'Uncategorized') == category);
        //filt = filt.filter(x => (x.isGeneralForm ?? false) === false);
        if (this.filterText)
        {
          filt = filt.filter(x => x.name.toLowerCase().indexOf(this.filterText.toLowerCase()) > -1);
        }
        
        return filt;
      }
      else
      {
        return null;
      }
    }
    
  }
  selectNone () : void 
  {


    if (this.requestPatientCompleteDetails===true)
    {
      var dummyFrm = new ArtiForm();
      dummyFrm.id = RQ_PAT_DETAILS;
      this.selectedEntities.push(dummyFrm)
    }

    if (this.requestPatientSignContracts===true)	
    {
      var dummyFrm = new ArtiForm();
      dummyFrm.id = RQ_PAT_SIGN_CONTRACTS;
      this.selectedEntities.push(dummyFrm)
    }

    this.dialogRef.close(this.selectedEntities);
  }

  get selectionsMade () : boolean
  {
    for (let key in this.checkboxDictionary) {
      let value = this.checkboxDictionary[key];
      if (value===true)
      {
        return true;
      }
    }  

    if ((this.requestPatientSignContracts===true) && (this.includePatientSignContracts===true))
    {
      return true;
    }
    else if ((this.requestPatientCompleteDetails===true) && (this.includePatientDetailsRequest===true))
    {
      return true;
    }
    else
    {
      return false;
    }
    
  }

  shouldGlow(ent: ArtiForm)
  {
    // this.au
    return ((this.authenticationService.isConferenceOrg) &&  (ent?.name?.startsWith(this.guidedTourService.tourOpForm) ? true : false));
  }


}


// export class FormSelectionDetails
// {
//   constructor()
//   {
//     this.selectedForms = [];
//   }
//   public requestPatientSignContracts: boolean = false;
//   public requestPatientCompleteDetails: boolean = false;  

//   public selectedForms: ArtiForm [] = [];

//   public userSelectEntityNoneSelectionValue: boolean = false;

// }