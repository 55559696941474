import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, RendererFactory2, Renderer2, Input} from '@angular/core';
import  *  as Alchemint from '@app/_alchemint/alchemint_dm';
import { ApiInterfaceService } from '@app/_services/alchemint.apiinterface.service'
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { CompositeExternalRequest,  ExternalRequestDetails, ExternalRqDetails,
   RequestType, PropertyDescriber, CompositePatient, 
   ContentFileTypes, AggreementDTO, 
   TermsAndConditionsFunctionalCode, PaymentPolicyFunctionalCode, PopiActFunctionalCode, PersonConsentPOPIFunctionalCode, 
   OtherContract1FunctionalCode, OtherContract2FunctionalCode, OtherContract3FunctionalCode,
   eStandardFunctionalContent, 
   FormFieldDescriber, formInputhChangeExtender, customFormFieldError, FieldCustomizer, FieldAlias, MedicalAidScheme
  }     from '@app/_alchemint/alchemint_composite_requests';



import { throwError } from 'rxjs';
import {ExternalRequestrService} from '@app/_services/external.request.service'
import { ErrorComponentComponent } from '@app/error-component/error-component.component';

import { EntityEditorComponent } from '@app/entity-editor/entity-editor.component'
import { ContentViewComponent } from '@app/content-view/content-view.component';
import { HelperFunctions } from '@app/_helpers/helperfunctions';
import { UiHelper } from '@app/_helpers/ui.helpers';
import { WebApiInterfaceService } from '@app/_services/alchemint.webapiinterface.service';
import { number } from 'mathjs';

@Component({
  selector: 'app-external-person',
  templateUrl: './external-person.component.html',
  styleUrls: ['./external-person.component.scss']
})
export class ExternalPersonComponent implements OnInit {

  @ViewChild('addOrSaveButton') public addOrSaveButton: any;

  @Output() completedEvent = new EventEmitter<string>();

  @Output() patientCreated = new EventEmitter<CompositePatient>();

  @Output() patientUpdated = new EventEmitter<CompositePatient>();

  @Input()
  public inDialogMode : boolean = false;
  
  // @ViewChild(ErrorComponentComponent)
  @ViewChild(ErrorComponentComponent)
  private errorComponentComponent: ErrorComponentComponent;

  @ViewChild('entityEditorComponentPatient')
  public entityEditorComponentPatient: EntityEditorComponent;

  @ViewChild('entityEditorComponentBiographical')
  public entityEditorComponentBiographical: EntityEditorComponent;

  @ViewChild('entEditContact')
  public entEditContact: EntityEditorComponent;

  @ViewChild('entEditPersRes')
  public entEditPersRes: EntityEditorComponent;
  
  @ViewChild('fillPersonResponsibleButton')
  public fillPersonResponsibleButton: any;
  

  @ViewChild('contentViewTermsAndConditions')
  private contentViewTermsAndConditions: ContentViewComponent;

  @ViewChild('contentViewPaymentPolicy')
  private contentViewPaymentPolicy: ContentViewComponent;
  
  @ViewChild('contentViewPersonConsentPOPI')
  private contentViewPersonConsentPOPI: ContentViewComponent;
  
  @ViewChild('contentViewPopiAct')
  private contentViewPopiAct: ContentViewComponent;

  @ViewChild('contentViewOtherContract1')
  private contentViewOtherContract1: ContentViewComponent;

  @ViewChild('contentViewOtherContract2')
  private contentViewOtherContract2: ContentViewComponent;

  @ViewChild('contentViewOtherContract3')
  private contentViewOtherContract3: ContentViewComponent;

  public useIdNumberToDeduceDateOfBirth : boolean = false;
  
  public fieldCustomizers: FieldCustomizer [] = null;
  
  public fieldCustomizerErrors: string = null;

  public visible : boolean = false;

  isExternalRequest : boolean = true;

  collectionRequestType : RequestType; 
  collectPersonalInfo : boolean = false;
  collectAgreements : boolean = false;
  sharingOrganization : Alchemint.Organisation;
  aggreeToTermsAndConditions : boolean = false;
  aggreeToPaymentPolicy : boolean = false;
  aggreeToPersonConsentPOPI : boolean = false;
  aggreeToPopiAct : boolean = false;
  aggreeToOtherContract1 : boolean = false;
  aggreeToOtherContract2 : boolean = false;
  aggreeToOtherContract3 : boolean = false;

  signedByName : string = null;

  requestDetails : string;
  requestId : string;
  webUIControllerapiKey: string;
  loading : boolean = true;

  patient : Alchemint.Patient;

  patientFields : any;
  contactFields : any;
  biographicalDetailsFields : any;
  personResponsibleForFields : any;

  biographicalDetails : Alchemint.BiographicalDetails;
  contactDetails : Alchemint.ContactDetails;
  personResponsibleForAccount : Alchemint.PersonResponsibleForAccount;

  patientProps : PropertyDescriber [];
  contactProperties  : PropertyDescriber [];
  biographicProperties  : PropertyDescriber [];
  personResponsibleProperties  : PropertyDescriber [];

  externalRequest : Alchemint.ExternalRequest;

  submitted = false;
  
  showrecreationaldrugsdetails = false;

  createdPreanaesthesia : Alchemint.PreAnaesthesia;  

  activeStep : number = 0;
  
  uiTestMode : boolean = false;
  noSubmitMode : boolean = false;

  incompleteUserActions : string = '';

  publicContentKey : string;

  contratsToCollect : string [] = null;
  submitAllowKey : string;

  compositePatient : CompositePatient = null;

  addNewPatientMode : boolean = false;

  isExternalMode : boolean = true;
  invalidationMessage: string;

  renderer: Renderer2;
    
  
  constructor(
    private webApiInterfaceService: WebApiInterfaceService,
    private apiInterfaceService : ApiInterfaceService,  
    private formBuilder: FormBuilder,
    private externalRequestrService : ExternalRequestrService, 
    private el: ElementRef, 
    rendererFactory: RendererFactory2) { 

      this.renderer = rendererFactory.createRenderer(null, null);
    }

  // convenience getter for easy access to form fields
  // public get f() { return this.personQuestionaire.controls; }

  ngOnInit(): void {
    
  }

  public set medicalAidSchemes (val: MedicalAidScheme[])
  {
    this.entEditPersRes.medicalAidSchemes = val;
    //this.entityEditorComponentPatient.personResponsibleForAccount
    //this.entityEditorComponentPatient.medicalAidSchemes = val;
  }

  public tryPopulatePatient(json: string)
  {
    this.entityEditorComponentPatient.tryPopulate(json);
  }

  public tryPopulateBiographicalDetails(json: string)
  {
    this.entityEditorComponentBiographical.tryPopulate(json);
  }

  public tryPopulateContactDetails(json: string)
  {
    this.entEditContact.tryPopulate(json);
  }

  public tryPopulatePersonResponsibleForAccount(json: string)
  {
    this.entityEditorComponentBiographical.tryPopulate(json);
  }

  public buildForm () 
  {

    this.errorComponentComponent?.Reset();

    if (this.isExternalRequest)
    {
      if ((this.collectionRequestType == RequestType.DetailsCollection) || (this.collectionRequestType == RequestType.ContractsCollectionOnly))
      {
        this.collectAgreements = true;
      }
      if ((this.collectionRequestType == RequestType.DetailsCollection) || (this.collectionRequestType == RequestType.DetailsCollectionButNoContracts))
      {
        this.collectPersonalInfo = true;   
      }
    }
    else 
    {
      this.collectPersonalInfo = true;   
      this.collectAgreements = false;
    }

    this.visible = true;

    if (true) 
    {
      this.entityEditorComponentPatient.visible = true;
      this.entityEditorComponentPatient.fieldCustomizers = this.fieldCustomizers;
      this.entityEditorComponentPatient.setEntity(this.patient, (new Alchemint.Patient()).entityTypeName);
      this.entityEditorComponentPatient.entityProps = this.patientProps;
      this.entityEditorComponentPatient.submitAllowKey = this.submitAllowKey;
      this.entityEditorComponentPatient.isExternalMode =  this.isExternalMode;
      this.entityEditorComponentPatient.buildForm("Personal Details", ['Title']);
  
      this.entityEditorComponentBiographical.visible = true;
      this.entityEditorComponentBiographical.fieldCustomizers = this.fieldCustomizers;
      this.entityEditorComponentBiographical.setEntity(this.biographicalDetails, (new Alchemint.BiographicalDetails()).entityTypeName);
      this.entityEditorComponentBiographical.entityProps = this.biographicProperties;
      this.entityEditorComponentBiographical.submitAllowKey = this.submitAllowKey;
      this.entityEditorComponentBiographical.isExternalMode =  this.isExternalMode;
      
      if (this.useIdNumberToDeduceDateOfBirth)
      {
        this.entityEditorComponentBiographical.formInputChangeExtender = this.idNumberToDateOfBirthMapper;
      }
      else
      {
        this.entityEditorComponentBiographical.formInputChangeExtender = null;
      }
      
      this.entityEditorComponentBiographical.buildForm("Biographical Details", ['IdNumber', 'DateOfBirth']);
  
      this.entEditContact.visible = true;
      this.entEditContact.fieldCustomizers = this.fieldCustomizers;
      this.entEditContact.setEntity(this.contactDetails, (new Alchemint.ContactDetails()).entityTypeName);
      this.entEditContact.entityProps = this.contactProperties;
      this.entEditContact.submitAllowKey = this.submitAllowKey;
      this.entEditContact.isExternalMode =  this.isExternalMode;
      this.entEditContact.buildForm("Contact Details",[]);
  
      this.entEditPersRes.visible = true;
      
      var fieldCustomizerForPersResp : FieldCustomizer[];
      if (this.fieldCustomizers?.length > 0)
      {
        fieldCustomizerForPersResp = this.fieldCustomizers;
      }
      else
      {
        fieldCustomizerForPersResp = [];
      }
      var nameFieldCustomizer = new FieldCustomizer();
      nameFieldCustomizer.forObjectType = "PersonResponsibleForAccount"
      nameFieldCustomizer.fieldAliases = [];
      var fldAlias = new FieldAlias();
      fldAlias.alias = "Full Name";
      fldAlias.fieldName = "name";
      fldAlias.hide = false;
      nameFieldCustomizer.fieldAliases.push(fldAlias)


      fieldCustomizerForPersResp.push(nameFieldCustomizer);
      this.entEditPersRes.fieldCustomizers = fieldCustomizerForPersResp;



      this.entEditPersRes.setEntity(this.personResponsibleForAccount, (new Alchemint.PersonResponsibleForAccount()).entityTypeName);
      this.entEditPersRes.entityProps = this.personResponsibleProperties;
      this.entEditPersRes.submitAllowKey = this.submitAllowKey;
      this.entEditPersRes.isExternalMode =  this.isExternalMode;
      this.entEditPersRes.buildForm("Person Responsible for Account (Main member of Medical aid)", ['IdNumber', 'FirstName', 'LastName']);
  
    }
    this.loading = false;

    if (this.collectAgreements)
    {
      if (this.contratsToCollect)
      {
        if (this.contratsToCollect.indexOf(eStandardFunctionalContent.eTermsAndConditions.toString()) > -1)
        {
          this.contentViewTermsAndConditions.SetDocument(TermsAndConditionsFunctionalCode, this.publicContentKey, ContentFileTypes.ePdf, 10, "download" );
        }
        if (this.contratsToCollect.indexOf(eStandardFunctionalContent.ePaymentPolicy.toString()) > -1)
        {
          this.contentViewPaymentPolicy.SetDocument(PaymentPolicyFunctionalCode, this.publicContentKey, ContentFileTypes.ePdf, 10, "download" );
        }
        if (this.contratsToCollect.indexOf(eStandardFunctionalContent.ePersonConsentPOPI.toString()) > -1)
        {
          this.contentViewPersonConsentPOPI.SetDocument(PersonConsentPOPIFunctionalCode, this.publicContentKey, ContentFileTypes.ePdf, 10, "download" );
        }
        if (this.contratsToCollect.indexOf(eStandardFunctionalContent.ePopiAct.toString()) > -1)
        {
          this.contentViewPopiAct.SetDocument(PopiActFunctionalCode, this.publicContentKey, ContentFileTypes.ePdf, 10, "download" );
        }
        if (this.contratsToCollect.indexOf(eStandardFunctionalContent.eOtherContract1.toString()) > -1)
        {
          this.contentViewOtherContract1.SetDocument(OtherContract1FunctionalCode, this.publicContentKey, ContentFileTypes.ePdf, 10, "download" );
        }
        if (this.contratsToCollect.indexOf(eStandardFunctionalContent.eOtherContract2.toString()) > -1)
        {
          this.contentViewOtherContract2.SetDocument(OtherContract2FunctionalCode, this.publicContentKey, ContentFileTypes.ePdf, 10, "download" );
        }
        if (this.contratsToCollect.indexOf(eStandardFunctionalContent.eOtherContract3.toString()) > -1)
        {
          this.contentViewOtherContract3.SetDocument(OtherContract3FunctionalCode, this.publicContentKey, ContentFileTypes.ePdf, 10, "download" );
        }


      }
      else
      {
        this.contentViewTermsAndConditions.SetDocument(TermsAndConditionsFunctionalCode, this.publicContentKey, ContentFileTypes.ePdf, 10, "download" );
        this.contentViewPaymentPolicy.SetDocument(PaymentPolicyFunctionalCode, this.publicContentKey, ContentFileTypes.ePdf, 10, "download" );
        this.contentViewPersonConsentPOPI.SetDocument(PersonConsentPOPIFunctionalCode, this.publicContentKey, ContentFileTypes.ePdf, 10, "download" );
        this.contentViewPopiAct.SetDocument(PopiActFunctionalCode, this.publicContentKey, ContentFileTypes.ePdf, 10, "download" );
        this.contentViewOtherContract1.SetDocument(OtherContract1FunctionalCode, this.publicContentKey, ContentFileTypes.ePdf, 10, "download" );
        this.contentViewOtherContract2.SetDocument(OtherContract2FunctionalCode, this.publicContentKey, ContentFileTypes.ePdf, 10, "download" );
        this.contentViewOtherContract3.SetDocument(OtherContract3FunctionalCode, this.publicContentKey, ContentFileTypes.ePdf, 10, "download" );

      }
    }

  }


  public buildFields (editObject : object, propDetails : PropertyDescriber []) : any
  {
    if (editObject == null)
    {
      return;
    }
     var fields = new Array(Object.entries(editObject).length);
     var i  = 0;

     Object.entries(editObject).forEach(
       ([key, value]) => 
       {
         var ffd : FormFieldDescriber  = new FormFieldDescriber();
         ffd.label = key;
         ffd.type = "textBox";
         ffd.value = value;
         ffd.description = this.FieldDesc(key, propDetails);
         ffd.hidden = this.IsHidden(key, propDetails);
         ffd.required = this.IsRequired(key, propDetails)
         fields[i] = ffd;
         
         i ++;
       }
     );
 
     return fields;

  }

  public buildFormForObj (fields : any) : FormGroup
  {

    if (fields == null)
    {

    }
    let group={};   

    fields.forEach(input_template=>{
      group[input_template.label]=new FormControl(input_template.value);  
    })

    return new FormGroup(group); 

  }

  onSubmit() {

    if (this.errorComponentComponent)
    {
      this.errorComponentComponent?.Reset();
    }
    
    this.entityEditorComponentPatient?.erroroComponent?.Reset();
    this.entityEditorComponentBiographical?.erroroComponent?.Reset();
    this.entEditContact?.erroroComponent?.Reset();
    this.entEditPersRes?.erroroComponent?.Reset();

    var persDetails : boolean =  this.entityEditorComponentPatient.validateUserInput();
    this.invalidationMessage = '';

    var invalidMessages : string [] = [];

    if (persDetails===false)
    {
      invalidMessages.push(this.entityEditorComponentPatient.formInvalidationString());
    }

    var bioDetails : boolean =  this.entityEditorComponentBiographical.validateUserInput();
    if (bioDetails===false)
    {
      invalidMessages.push(this.entityEditorComponentBiographical.formInvalidationString());
    }

    var contactDetails : boolean =  this.entEditContact.validateUserInput();
    if (contactDetails===false)
    {
      invalidMessages.push(this.entEditContact.formInvalidationString());
    }

    var persResValid : boolean =  this.entEditPersRes.validateUserInput();
    if (persResValid===false)
    {
      invalidMessages.push(this.entEditPersRes.formInvalidationString());
    }
    
    this.invalidationMessage =  invalidMessages?.length > 0 ? invalidMessages.join(', ') : '';
    

    this.invalidationMessage = HelperFunctions.replaceAllTextOccurrencesWith(this.invalidationMessage, " * (required)","");

    if (this.invalidationMessage .indexOf('ID Number') > -1)
    {
      this.invalidationMessage  += ' Please note that Id Numbers are required to be entered for the Patient and Person Responsible for Account.'
    }
    // var persDetailsNoBlanks : boolean =  (this.entityEditorComponentPatient.checkForBlankRequiredFields().length == 0);
    // var bioDetailsNoBlanks : boolean =  (this.entityEditorComponentBiographical.checkForBlankRequiredFields().length ==0);
    // var contactDetailsNoBlanks : boolean =  (this.entEditContact.checkForBlankRequiredFields().length == 0);
    // var persResValidNoBlanks : boolean =  (this.entEditPersRes.checkForBlankRequiredFields().length == 0);

    if ((persDetails && bioDetails && contactDetails && persResValid) == false)
    {
      this.scrollToFirstInvalidControl();
      return;
    }

    // if ((persDetailsNoBlanks && bioDetailsNoBlanks && contactDetailsNoBlanks && persResValidNoBlanks) == false)
    // {
    //   return;
    // }

    if (this.formErrors)
    {
      // alert(this.entityEditorComponentBiographical.entityForm.controls['dateOfBirth'].value);
      return;
    }
    
    this.compositePatient = new CompositePatient();

    this.submitted = true;

    if (this.collectPersonalInfo)
    {
      const utcDate = new Date(Date.now());
      this.compositePatient.patient = this.entityEditorComponentPatient.entityForm.getRawValue();
      this.compositePatient.patient.modDate = utcDate;
      this.compositePatient.biographicalDetails = this.entityEditorComponentBiographical.entityForm.getRawValue();
      this.compositePatient.biographicalDetails.modDate = utcDate;
      this.compositePatient.contactDetails = this.entEditContact.entityForm.getRawValue();
      this.compositePatient.contactDetails.modDate = utcDate;
      this.compositePatient.personResponsibleForAccount = this.entEditPersRes.getObjectForSubmit(); 
      this.compositePatient.personResponsibleForAccount.modDate = utcDate;
    }
    
    if (this.collectAgreements)
    {
      this.compositePatient.aggreementDTO = this.GetAgreements (); 
    }
    

    //let formObj = this.personQuestionaire.getRawValue(); 
    //let serializedForm = JSON.stringify(formObj, null, 2);

    let serializedCompositePatient = JSON.stringify(this.compositePatient, null, 2);

    if (this.noSubmitMode == true)
    {
      this.completedEvent.emit(serializedCompositePatient);
      return;
    }
    else
    {
        this.loading = true;

        if (this.isExternalRequest)
        {
          this.webApiInterfaceService.postExternalWebRequestApiCall<any>(this.requestDetails, this.requestId, this.webUIControllerapiKey, serializedCompositePatient, this.submitAllowKey).subscribe(
            (createdObject : any) => {
              this.observerFunc(createdObject);
            }, 
            (error : any) => {
              this.errorFunc(error);
            }
          );
        } 
        else  
        {
          if (this.addNewPatientMode)
          {
            this.apiInterfaceService.postPatientWebGuiApiCall(this.compositePatient.patient.id, serializedCompositePatient).subscribe(
              (createdObject : any) => {
                this.observerFunc(createdObject);
              }, 
              (error : any) => {
                this.errorFunc(error);
              }
            );
          }
          else
          {
            this.apiInterfaceService.putPatientWebGuiApiCall(this.compositePatient.patient.id, serializedCompositePatient).subscribe(
              (createdObject : any) => {
                this.observerFunc(createdObject);
              }, 
              (error : any) => {
                this.errorFunc(error);
              }
            );
          }

          // public putPatientWebGuiApiCall (patientId : string, postObject : any): Observable<CompositePatient>
        }


        


    }

  }


  errorFunc (error : any)
  {
    this.patient = null;
    
    if (error.status == '409')
    {
      this.errorComponentComponent.SetErrorDetails('That patient already exists', null, true, false);
    }
    else
    {
      this.errorComponentComponent.SetErrorDetailsDefTitle(error.status, true);
    }
    
    this.loading = false;
  }
  observerFunc  (createdObject : any)  {

    if (this.collectPersonalInfo)
    {
      var verificationFailureResults : string = this.externalRequestrService.verifyFormAgainstStoredResult(this.compositePatient.patient, (createdObject as CompositePatient).patient,  ['id', 'termsAndConditionsAccepted', 'dateAccepted', 'name', 'modDate', 'addDate', 'patientName'])
      if (verificationFailureResults)
      {
         this.DisplayInconsistenciesInResults(verificationFailureResults, "Personal details");
      }

      var verificationFailureResultsBio : string = this.externalRequestrService.verifyFormAgainstStoredResult(this.compositePatient.biographicalDetails, (createdObject as CompositePatient).biographicalDetails, ['id', 'patientId','age', 'modDate', 'addDate', 'bodyMassIndex', 'dateOfBirth' ])
      if (verificationFailureResultsBio)
      {
        this.DisplayInconsistenciesInResults(verificationFailureResultsBio, "Biographical details");
      }

      var verificationFailureResultsContact : string = this.externalRequestrService.verifyFormAgainstStoredResult(this.compositePatient.contactDetails, (createdObject as CompositePatient).contactDetails, ['id', 'patientId', 'modDate', 'addDate'])
      if (verificationFailureResultsContact)
      {
        this.DisplayInconsistenciesInResults(verificationFailureResultsContact, "Contact details");
      }

      var verificationFailureResultsPersRes : string = this.externalRequestrService.verifyFormAgainstStoredResult(this.compositePatient.personResponsibleForAccount, (createdObject as CompositePatient).personResponsibleForAccount, ['id', 'patientId', 'modDate', 'addDate'])
      if (verificationFailureResultsPersRes)
      {
        this.DisplayInconsistenciesInResults(verificationFailureResultsPersRes, "Person Responsible Details (Main member of Medical aid)");
      }
    }
    this.loading = false;
    if (this.errorComponentComponent)
    {
      this.errorComponentComponent?.Reset();
    }
    
    if (this.isExternalRequest)
    {
      if (this.completedEvent)
      {
        this.completedEvent.emit(createdObject);
      }
    }
    else
    {
      if (this.addNewPatientMode)
      {
        if (this.patientCreated)
        {
          this.patientCreated.emit(createdObject);
        }
      }
      else
      {
        if (this.patientUpdated)
        {
          this.patientUpdated.emit(createdObject);
        }
      }
      
    }
    

  }

  VerifyResults (createdObject : any)
  {

  }


  DisplayInconsistenciesInResults (inconsistencies : string, entityName : string)
  {
    alert(`Difference in submitted and stored results for ${entityName} : ${inconsistencies}`)
  }

  IsHidden(propnam : string, props : PropertyDescriber []) : boolean
  {
   
    var hidden : boolean = false;

    for(let value of props)
    {
      if ((propnam.toLowerCase() == 'id') || (propnam.toLowerCase() == 'deceased'))
      {
        hidden = true;
        break;
      }
      else if (value.name.toLowerCase() == propnam.toLowerCase())
      {
        hidden =  (value.isHidden || value.isComputed || value.isInternal);
        break;
      }
    }

    return hidden;
  }

  IsRequired(propnam : string, props : PropertyDescriber []) : boolean
  {
   
    var req : boolean = false;

    for(let value of props)
    {
      if (value.name.toLowerCase() == propnam.toLowerCase())
      {
        req = value.isRequired;
        break;
      }
    }

    return req;
  }

  

FieldDesc(propnam : string, props : PropertyDescriber []) : string
  {
    var desc : string = 'NOT found';
    props.forEach(
      (value) => 
      {
        if (value.name.toLowerCase() == propnam.toLowerCase())
        {
          desc = value.label;
        }
      }
    );

    return desc;
  }



  get formErrors () : string
  {
    if (this.loading)
    {
      return null;
    }
    var userErrors : string = null;
    var dobControl = this.entityEditorComponentBiographical.entityForm.controls['dateOfBirth'];
    
    if (dobControl.invalid)
    {
      var dobVal = dobControl.value;
      var validDob : boolean = this.externalRequestrService.isValidDate(dobVal);
  
      if (validDob == false)
      {
        //alert(this.biographicalDetailsQuestionaire.controls['dateOfBirth'].value);
  
          userErrors = 'Invalid Date of Birth. Please use the format mm/dd/yyyy'
          
      }
  
    }

    if (this.isExternalMode === true)
    {
      if (this.entityEditorComponentBiographical?.entityForm?.invalid === true)
      {


        userErrors += 'Bio Invalid ' +  
          String(this.getFormValidationErrors(this.entityEditorComponentBiographical?.entityForm, this.entityEditorComponentBiographical));
      }
    }

    return userErrors;
  }


  get formErrorsDetail () : string []
  {


    if (this.isExternalMode === true)
    {
      var errs = this.messagesWithScrollTo;
      if (errs?.length > 0)
      {
        return errs?.map(x => x[0]) ;
      }
      else
      {
        return [];
      }
    }
    else
    {
      return []
    }

  }

  public get messagesWithScrollTo () : [string,HTMLElement,HTMLElement][]
  {
   
    var out : [string,HTMLElement,HTMLElement][] = [];
   
    var pers = this.getFormValidationErrors(this.entityEditorComponentPatient?.entityForm, this.entityEditorComponentPatient)
    var bio = this.getFormValidationErrors(this.entityEditorComponentBiographical?.entityForm, this.entityEditorComponentBiographical);
    var cont = this.getFormValidationErrors(this.entEditContact?.entityForm, this.entEditContact);
    var persResp = this.getFormValidationErrors(this.entEditPersRes?.entityForm, this.entEditPersRes);

   
    if (pers?.length > 0)
    {
      out = out.concat(pers);
    }
    
    if (bio?.length > 0)
    {
      out = out.concat(bio);
    }

    if (cont?.length > 0)
    {
      out = out.concat(cont);
    }
    if (persResp?.length > 0)
    {
      out = out.concat(persResp);
    }

    return out;
  }





  getFormValidationErrors(form: FormGroup, editor: EntityEditorComponent):  [string,HTMLElement,HTMLElement][]  {
    
    if (form == null)
    {
      return null;
    }
    var messagesWithScrollTo: [string,HTMLElement,HTMLElement][] = [];
    //this.messagesWithScrollTo = [];
    let message = '';
    let messages : string [] = [];
    f: FormControl; 
    
    Object.keys(form.controls).forEach(key => {
      const controlErrors: ValidationErrors = form.get(key)?.errors;
  
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          
          if (keyError == 'required')
          {
            var readableFieldName : string = UiHelper.camelCaseToReadable(key);
            var msg : string = `Required field: ${readableFieldName}`;
            messages.push(msg);
            var ctl: AbstractControl =  form.get(key);
            
            
            var ctlId = ctl['elementId']
            //var nativeControl : HTMLElement =  editor.getControlOfId(ctlId);
            
            var nativeControl = null;
            var sscrollToControl: HTMLElement = editor.getControlOfId("_scroll" + key);;
            
            messagesWithScrollTo.push([msg,nativeControl,sscrollToControl]);
            
          }
          else
          {
            var msg : string = `- ${key} ${keyError}: ${controlErrors[keyError]}\n`;
            messages.push(msg);
          }
          
        });
      }
    });
  
    return messagesWithScrollTo;
  }

  scrollTo (errCtl: [string,HTMLElement, HTMLElement])
  {
    errCtl[2].scrollIntoView();
  }
  
  scrollToFirstInvalid () : void
  {
    this.messagesWithScrollTo[0][2].scrollIntoView();
  }
  
  
  get orgWebSiteLink(): string {  
    if (this.sharingOrganization)
    {
      if (this.sharingOrganization.website)
      {
        if (this.sharingOrganization.website.toLocaleLowerCase().startsWith("http"))
        {
          return this.sharingOrganization.website
        }
        else
        {
          return "http://" + this.sharingOrganization.website;
        }
      }
      else
      {
        return null;
      }
    }
    else 
    {
      return null;
    }
    
  }



  get signatureStillRequired(): boolean {
    
    if (this.collectAgreements)
    {
      if (this.signedByName)
      {
        return false;
      }
      else
      {
        return true;
      }
    }
    else
    {
      return false;
    }
  }

  

  get allTermsAgreed(): boolean {
    
    this.incompleteUserActions = '';

    var allTermsAgreed : boolean = true;
    
    if (this.loading)
    {
      return false;
    }

    if (this.collectAgreements == false)
    {
      return true;
    }

    if (this.contentViewTermsAndConditions.documentLoaded)
    {
      if (this.aggreeToTermsAndConditions == false)  
      {
        allTermsAgreed = false;
        this.incompleteUserActions += ', Terms & Conditions';
      }
    }

    if (this.contentViewPaymentPolicy.documentLoaded)
    {
      if (this.aggreeToPaymentPolicy == false)  
      {
        allTermsAgreed = false;
        this.incompleteUserActions += ', Payment Policy';
      }
    }

    if (this.contentViewPersonConsentPOPI.documentLoaded)
    {
      if (this.aggreeToPersonConsentPOPI == false)  
      {
        allTermsAgreed = false;
        this.incompleteUserActions += ', Consent to Electronic Communication';
      }
    }
    
    if (this.contentViewPopiAct.documentLoaded)
    {
      if (this.aggreeToPopiAct == false)  
      {
        this.incompleteUserActions += ', Familiar with POPI';
        allTermsAgreed = false;
      }
    }

    if (this.contentViewOtherContract1.documentLoaded)
    {
      if (this.aggreeToOtherContract1 == false)  
      {
        this.incompleteUserActions += ', Other Contract 1';
        allTermsAgreed = false;
      }
    }

    if (this.contentViewOtherContract2.documentLoaded)
    {
      if (this.aggreeToOtherContract2 == false)  
      {
        this.incompleteUserActions += ', Other Contract 2';
        allTermsAgreed = false;
      }
    }

    if (this.contentViewOtherContract3.documentLoaded)
    {
      if (this.aggreeToOtherContract3 == false)  
      {
        this.incompleteUserActions += ', Other Contract 3';
        allTermsAgreed = false;
      }
    }


    if (this.incompleteUserActions.length > 1)
    {
      this.incompleteUserActions = this.incompleteUserActions.substring(2);
    }

    return allTermsAgreed;

  }


  GetAgreements () : AggreementDTO []
  {
    var agreements : AggreementDTO [] = []; 
    if (this.aggreeToTermsAndConditions)
    {
      agreements.push(new AggreementDTO (TermsAndConditionsFunctionalCode, this.signedByName));
    }

    if (this.aggreeToPaymentPolicy)
    {
      agreements.push(new AggreementDTO (PaymentPolicyFunctionalCode, this.signedByName ));
    }

    if (this.aggreeToPersonConsentPOPI)
    {
      agreements.push(new AggreementDTO (PersonConsentPOPIFunctionalCode, this.signedByName ));
    }

    if (this.aggreeToPopiAct)
    {
      agreements.push(new AggreementDTO (PopiActFunctionalCode, this.signedByName ));
    }

    if (this.aggreeToOtherContract1)
    {
      agreements.push(new AggreementDTO (OtherContract1FunctionalCode, this.signedByName ));
    }

    if (this.aggreeToOtherContract2)
    {
      agreements.push(new AggreementDTO (OtherContract2FunctionalCode, this.signedByName ));
    }
    
    if (this.aggreeToOtherContract3)
    {
      agreements.push(new AggreementDTO (OtherContract3FunctionalCode, this.signedByName ));
    }


    return agreements;

  }
  
  get submitAllowKeyInValid(): boolean {
    if (this.submitAllowKey)
    {
      if (this.submitAllowKey.length == 10)
      {
        return true;
      }
      else
      {
        return false;
      }
    }
    else
    {
      return false;
    }
  }

  private _sizeClass: string = "col-md-6 offset-md-3 mt-5";

  get sizeClass(): string {
      return this._sizeClass;
  }
  set sizeClass(value: string) {
      
    this._sizeClass = value; 
    if (this.entityEditorComponentPatient)
    {
      this.entityEditorComponentPatient.sizeClass = value;
    }
    if (this.entityEditorComponentBiographical)
    {
      this.entityEditorComponentBiographical.sizeClass = value;
    }
    if (this.entEditContact)
    {
      this.entEditContact.sizeClass = value;
    }
    if (this.entEditPersRes)
    {
      this.entEditPersRes.sizeClass = value;
    }
     
  }

  idNumberToDateOfBirthMapper (input: string, f : FormFieldDescriber, allFields : FormFieldDescriber[], entityForm: FormGroup) : void 
  {
   
   if (f.label=='idNumber')
   {

    var idNumField = entityForm.controls['idNumber'];
    var dob = entityForm.controls['dateOfBirth'];
    var sex = entityForm.controls['sex'];


    var idNum : string = idNumField.value;

    if (idNum.length == 0)
    {
      idNumField.setErrors(null);
      return;
    }

    var derivedDOB : string = idNum;
    derivedDOB = derivedDOB.substring(0,6);

    if (idNum.length != 13)
    {
      idNumField.setErrors(new customFormFieldError('Id number must be 13 characters long.'));
    }
    
    else if (derivedDOB.length > 5)
    {

      var year : string = derivedDOB.substring(0,2);
      var currentYear: number = new Date().getFullYear()
      
      if (year)
      {
        if (number('20' + year) > currentYear) 
        {
          year = '19' + year;
        }
        else
        {
          year = '20' + year;
        }
      }
      
      var month : string = derivedDOB.substring(2,4);
      var day : string = derivedDOB.substring(4,6);

      var yearInt : number = parseInt(year);
      var monthInt : number = parseInt(month);
      var dayInt : number = parseInt(day);


      if (monthInt > 12)
      {
        idNumField.setErrors(new customFormFieldError(monthInt.toString() + ' is not a valid month. Month must be less than equal to 12'));
      }
      else if (dayInt > 31)
      {
        idNumField.setErrors(new customFormFieldError(dayInt.toString() + ' is not a valid day.'));
      }
      else 
      {
        idNumField.setErrors(null);
        var daysInMonth : number = new Date(yearInt, monthInt, 0).getDate();  
        derivedDOB  = month + "/" + day + "/" + year;

        if (dayInt > daysInMonth)
        {
          idNumField.setErrors(new customFormFieldError(`${dayInt.toString()} is not a valid day for month ${monthInt.toString()}. Please specify a valid ID Number.`));
        }
        else if (Date.parse(derivedDOB))
        {
          var derivedDateOfBirth = new Date(yearInt, monthInt - 1, dayInt);

          if (this.externalRequestrService.isValidDate(derivedDateOfBirth))
          {
            dob.setValue(derivedDateOfBirth);
          }
        }
        else
        {
          idNumField.setErrors(new customFormFieldError(derivedDOB + ' derived from first 6 umbers of ID Number is not a valid date. Please specify a valid ID Number.'));
        }

      }

    }
    else
    {
      //dob.setErrors({'incorrect': true});
    }
    


    // Try derive the sex from digits 7 to 10 (Females 0 to 4999 and Males 5000 to 9999)
    if (idNum.length >= 10)
    {
      var sexDeterminerNumber = idNum.substring(6,10);
      var intSexDeterminerNumber: number = +sexDeterminerNumber;
      if (intSexDeterminerNumber < 5000)
      {
        sex.setValue('F');
      }
      else
      {
        sex.setValue('M');
      }

    }


    console.log(derivedDOB);
   }
    
  }

  private scrollToFirstInvalidControl() {
    
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      "form .ng-invalid"
    );

    firstInvalidControl?.focus(); //without smooth behavior
  }

  fillPersResponseFromPatientDetails () : void 
  {
    var persRespForm = this.entEditPersRes.entityForm.controls;
    var patForm = this.entityEditorComponentPatient.entityForm.controls;
    var bioForm = this.entityEditorComponentBiographical.entityForm.controls;
    var contForm = this.entEditContact.entityForm.controls;

    var name = (patForm["firstName"].value ?? '') + ' ' + (patForm["lastName"].value ?? '');
    persRespForm["name"].setValue(name?.trim());

    persRespForm["title"].setValue(patForm["title"].value ?? '');
    persRespForm["firstName"].setValue(patForm["firstName"].value ?? '');
    persRespForm["lastName"].setValue(patForm["lastName"].value ?? '');

    persRespForm["telephoneWork"].setValue(contForm["telephoneWork"].value);
    persRespForm["telephoneHome"].setValue(contForm["telephoneHome"].value);
    persRespForm["telephoneCell"].setValue(contForm["telephoneCell"].value);
    persRespForm["emailAddress"].setValue(contForm["emailAddress"].value);


    persRespForm["address"].setValue(contForm["address"].value);
    persRespForm["address2"].setValue(contForm["addressLine2"].value);
    persRespForm["suburb"].setValue(contForm["suburb"].value);
    persRespForm["town"].setValue(contForm["town"].value);
    persRespForm["postalCode"].setValue(contForm["postalCode"].value);

    persRespForm["postalAddress1"].setValue(contForm["postalAddress"].value);
    persRespForm["postalAddress2"].setValue(contForm["postalAddress2"].value);

    persRespForm["postalAddressSuburb"].setValue(contForm["postalAddressSuburb"].value);
    persRespForm["postalAddressTown"].setValue(contForm["postalAddressTown"].value);
    persRespForm["postalAddressPostalCode"].setValue(contForm["postalAddressPostalCode"].value);

    persRespForm["idNumber"].setValue(bioForm["idNumber"].value);
    
    
  }

  fillContactPostalAddressFromHome
  () : void 
  {

    var contForm = this.entEditContact.entityForm.controls;

    contForm["postalAddress"].setValue(contForm["address"].value);
    contForm["postalAddress2"].setValue(contForm["addressLine2"].value);

    contForm["postalAddressSuburb"].setValue(contForm["suburb"].value);
    contForm["postalAddressTown"].setValue(contForm["town"].value);
    contForm["postalAddressPostalCode"].setValue(contForm["postalCode"].value);
    
  }

  fillPersResponsiblePostalAddressFromHome
  () : void 
  {
    var persRespForm = this.entEditPersRes.entityForm.controls;

    persRespForm["postalAddress1"].setValue(persRespForm["address"].value);
    persRespForm["postalAddress2"].setValue(persRespForm["address2"].value);
    persRespForm["postalAddressSuburb"].setValue(persRespForm["suburb"].value);
    persRespForm["postalAddressTown"].setValue(persRespForm["town"].value);
    persRespForm["postalAddressPostalCode"].setValue(persRespForm["postalCode"].value);
    
    
  }

}

