import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InLineAssistanceComponent } from './in-line-assistance.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipDefaultOptions, MatTooltipModule, MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { FormsModule } from '@angular/forms';

import { InLineHelpDirective } from './in-line-help.directive';
import { AngularSvgIconModule } from 'angular-svg-icon';

export const OtherOptions: MatTooltipDefaultOptions = {
  showDelay: 0,
  hideDelay: 0,
  touchGestures: 'auto',
  position: 'before',
  touchendHideDelay: 0,
  disableTooltipInteractivity: true,
}

@NgModule({
  declarations: [InLineAssistanceComponent],
  imports: [
    CommonModule, 
    AngularSvgIconModule,
    MatTooltipModule,
    FormsModule
    
  ],
  exports: [InLineAssistanceComponent],
  providers: [
    {provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: OtherOptions}
  ],
})
export class InLineAssistanceModule { }
