import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SupportChatComponent } from './support-chat.component';
import { FormsModule } from '@angular/forms';
import { SvgModule } from '@app/svg/svg.module';



@NgModule({
  declarations: [SupportChatComponent],
  imports: [
    CommonModule,
    FormsModule,
    SvgModule
  ],
  exports: [SupportChatComponent]
})
export class SupportChatModule { }
