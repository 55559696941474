import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WhiteLabellingService {

  constructor() { }

  public isAlchemint () : boolean
  {
    if (window.location.hostname == "www.alchemint.com")
    {
      return true;
    }
    else
    {
      return false;
    }
  }

}
