
<style>
    .video-container {
   position: absolute;
  top: 0;
  bottom: 0; 
  top:8em;
  width: 100%;
  height: 100%; 
  overflow: hidden;
}
.video-container webcam {
  /* Make video to at least 100% wide and tall */
  min-width: 100%; 
  min-height: 100%; 

  /* Setting width & height to auto prevents the browser from stretching or squishing the video */
  width: auto;
  height: auto;

  /* Center the video */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.camerasnapcontainer {
  /* position: absolute;  */
  /* top: 3em;  */
  left: 50%;
  transform: translate(-50%,-50%);
  /* border: solid;
  color: aqua; */
  /* transform: translate(-50%,-50%); */

}

.camerasnap {
    /* position: absolute;
  top: 0; 
  left: 50%;
  transform: translate(-50%,-50%); */
  border-radius: 50%;
  height:4em; 
  width:4em;
                /* display:inline-block; */
                /* additional code */
  /* transform: translate(-50%,-50%); */

}
.button2 {
	position: absolute;
	left: 50%;
	transform: translate(-0%, -50%);
}

.centercamerabuttons {
  margin: auto;
  /* width: 60%; */
  border: 3px solid #73AD21;
  padding: 10px;
}

.wrapper {
    background-color: black;
    float: left;
}
.img {
    display: block;
}

.alc_vid_div {
  border: solid;
  border-color: white;
  border-radius: 1em;
  margin-bottom: 2em;
  padding: 2em;
  color: white;
}

</style>

<div [hidden]="visible==false" style="margin: 0px;">
  <div class="wrapper">
      <!-- Your buttons and other elements here -->
  </div>

  <div class="wrapper" style="margin: 0px;" [hidden]="showSettings===true">
      <webcam blurElement [blurOn]="blurOn" [blurAmount]="'10px'" *ngIf="cameraConnected && showWebcam"
          [videoOptions]="{ deviceId: selectedCamera?.deviceId, width: selectedImageQuality.width, height: selectedImageQuality.height }"
          [captureImageData]="true"
          [trigger]="triggerObservable"
          (imageCapture)="handleImage($event)">
      </webcam>

      <div style="float: left;  margin: 1em;">
          <p>
              <app-svg color="primary" [hidden]="(videoVisible==false) || (errorOnVideoInit==true)" (click)="cancel()" id="cancel" title="Close">cancel</app-svg>
          </p>
          <p>
              <app-svg color="primary" [hidden]="(videoVisible==false) || (errorOnVideoInit==true)" (click)="showSettings=!showSettings" id="settings" title="Camera Settings">settings</app-svg>
          </p>
      </div>

      <button class="camerasnap btn btn-primary btn-spacing" style="float: right;  margin-right: 1em;" [hidden]="(videoVisible==false) || (errorOnVideoInit==true)" (click)="triggerSnapshot()" id="capture" title="Capture">
          <app-svg color="white" style="vertical-align:middle;">photo_camera</app-svg>
      </button>
  </div>

  <div [hidden]="showSettings===false" class="settings-container" [style]="cameraSettingsContainerStyle">
      <h1 class="black">Camera Settings</h1>

      <label class="black">Resolution</label>
      <select class="form-control" [(ngModel)]="selectedImageQuality" (change)="changeResolution(selectedImageQuality)">
          <option *ngFor="let res of availableResolutions" [ngValue]="res">
              {{res.width}}x{{res.height}} ({{getResolutionName(res)}})
          </option>
      </select>

      <label class="black">Select Camera</label>
      <select [(ngModel)]="selectedCamera" (change)="changeCamera(selectedCamera)" class="form-control">
          <option *ngFor="let camera of cameras" [ngValue]="camera">
              {{camera.label || 'Camera'}}
          </option>
      </select>

      <label class="black">Default Camera</label>
      <select class="form-control" [(ngModel)]="defaultCameraPosition" (change)="setDefaultCameraPosition(defaultCameraPosition)">
          <option value="front">Front Camera</option>
          <option value="back">Back Camera</option>
      </select>

      <button class="btn btn-primary" (click)="showSettings=false">Ok</button>
  </div>
</div>

<!-- <div [hidden]="visible==false" style="margin: 0px;">
    

    <div class="wrapper">

      
   
      
        
      </div>
      
        <div class="wrapper" style="margin: 0px;" [hidden]="showSettings===true">


            <webcam blurElement [blurOn]="blurOn" [blurAmount]="'10px'" *ngIf="cameraConnected && showWebcam"
            [videoOptions]="{ deviceId: selectedCamera?.deviceId, width: selectedImageQuality.width, height: selectedImageQuality.height }"
            [captureImageData]="true"
            [trigger]="triggerObservable"
            (imageCapture)="handleImage($event)">
            </webcam>


            <div style="float: left;  margin: 1em; " >

                <p>
                    <app-svg color="primary" [hidden]="(videoVisible==false) || (errorOnVideoInit==true)" (click)="cancel()" id="cancel" title="Close">cancel</app-svg>
                </p>
                

                <p>
                    <app-svg color="primary" [hidden]="(videoVisible==false) || (errorOnVideoInit==true)" (click)="showSettings=!showSettings" id="settings" title="Camera Settings">settings</app-svg>
                </p>
                
    
            </div>


            <button class="camerasnap btn btn-primary btn-spacing" style="float: right;  margin-right: 1em;" [hidden]="(videoVisible==false) || (errorOnVideoInit==true)" (click)="triggerSnapshot()" id="capture" title="Capture" >
                <app-svg color="white" style="vertical-align:middle;">photo_camera</app-svg>
            </button>


        </div>

        <div [hidden]="showSettings===false" class="settings-container" [style]="cameraSettingsContainerStyle">
            <h1 class="black">
                Camera Settings
            </h1>

            <label class="black">Resolution</label>
            
            <select class="form-control" [(ngModel)]="selectedImageQuality"  (change)="changeResolution(selectedImageQuality)">
                <option *ngFor="let res of availableResolutions" [ngValue]="res">
                  {{res.width}}x{{res.height}} ({{getResolutionName(res)}})
                </option>
            </select>
    
            <label class="black">Select Camera</label>
            <select [(ngModel)]="selectedCamera" (change)="changeCamera(selectedCamera)" class="form-control" >
                <option *ngFor="let camera of cameras" [ngValue]="camera">
                  {{camera.label || 'Camera'}}
                </option>
            </select>

            <button class="btn btn-primary" (click)="showSettings=false">Ok</button>
        </div>

</div> -->