import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { Subject } from 'rxjs';
import { AlchemintSharedService } from '@app/alchemint-shared.service';

@Injectable({
  providedIn: 'root'
})
export class GuidedTourService {
  conferenceChallengeMode: boolean = false;


  public tourPatient: string = 'James Bond';
 
  public tourDiagnosis: string = 'Displaced Metacarpal Fracture';
  public tourOpForm: string = 'Metacarpal Fracture';

  constructor(public authenticationService: AuthenticationService, private alchemintSharedService: AlchemintSharedService) { 

    this.initialiseChallengeSteps();
  }

  public currentStep: number = -1;

  public get isConferenceOrg(): boolean 
  {
    return false;
    // return ((this.authenticationService.isConferenceOrg) || (this.conferenceChallengeMode === true));
  }

  public showConferenceModeEditsDisabledMessage()
  {
    this.alchemintSharedService.messageDialogSync("Changes will not be saved as you are in a conference organization.");
  }


  initialiseChallengeSteps() 
  {
    this._conferenceChallengeSteps.push(new challengeStep(1, '1. Select James Bond from the interactive diary below.', false));
    this._conferenceChallengeSteps.push(new challengeStep(2, '2. Study his Clinical Notes carefully. You can view his x-rays that have been saved in the RADIOLOGY folder.', false));
    this._conferenceChallengeSteps.push(new challengeStep(3, `3. You decide that there is a ${this.tourDiagnosis} that requires surgery. Click on the FORM button to select the relevant operation note.`, false));    
    
    
    // this._conferenceChallengeSteps.push(new challengeStep(4, '3. Select the timeline and have a look at the xrays', false));

    this._conferenceChallengeSteps.push(new challengeStep(4, '3. Select the relevant operation form from the list.', false));
    this._conferenceChallengeSteps.push(new challengeStep(5, '4. Please complete your operation note. The green fields are interactive. At the end of the form, please be sure to enter your name, email address, and cell number so as to be eligible for our wine tasting on Wednesday evening..', false));
    // this._conferenceChallengeSteps.push(new challengeStep(4, '5. Your operation form has generated a free form document that you can edit if you like. It can be emailed, downloaded or printed.', false));
    
    this._conferenceChallengeSteps.push(new challengeStep(6, 'Please press the finish button.', false));
    //this._conferenceChallengeSteps.push(new challengeStep(6, 'Please review the generated operation document and make any changes you wish to. Then press the finish button.', false));
    this._conferenceChallengeSteps.push(new challengeStep(7, '🍷 Thank you. The challenge is all done. 🍷 Please feel free to wonder around in the app. There will be people at our stand to answer any of your questions. ', false));
  }
  _conferenceChallengeSteps: challengeStep [] = [];
  public get conferenceChallengeSteps(): challengeStep[]
  {
    return this._conferenceChallengeSteps;
  }



  
  public get conferenceChallengeCurrentStep (): number
  {
    return this.currentStep;
  }

  _challengeStarted: boolean = false;
  public get challengeStarted(): boolean
  {
    return this._challengeStarted;
  }

  public set challengeStarted(val: boolean)
  {
    this._challengeStarted = val;
  }

  _challengeExited: boolean = false;
  public get challengeExited(): boolean
  {
    return this._challengeExited;
  }
  exitChalengeMode ()
  {
    this._challengeExited = true;
  }
  redoChalenge()
  {
    this.currentStep = 1;
    this.challengeStarted = false;
  }



  public currentStepChanged: Subject<number> = new Subject<number>();

  incrementStep() {
    this.currentStep += 1;
    this.currentStepChanged.next(this.currentStep);
    window.scrollTo(0, 0);
  }
}




export class challengeStep 
{
  constructor(public index: number, public html: string, public complete: boolean) 
  {

  }
}