<h4 class="title">Select Print Preview Template</h4>
<hr>
<div class="templates" *ngIf="templates$ | async">
    <div class="template" *ngFor="let t of templates$ | async" (click)="dialogRef.close(t)">
        <span>{{t.name}}</span>
    </div>
</div>
<hr>
<div style="display:flex; justify-content: flex-end;">
    <button class="btn btn-danger mb-3 my-3" (click)="dialogRef.close(null)" >Cancel</button>
</div>