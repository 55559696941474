import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface DropdownInpt{
  id: string;
  label: string;
}

@Component({
  selector: 'app-input-popup',
  templateUrl: './input-popup.component.html',
  styleUrls: ['./input-popup.component.scss']
})
export class InputPopupComponent implements OnInit {

  public inputText= "";
  private _temp :  DropdownInpt[] = [];

  options : DropdownInpt[] = [];
  @Input()
  set dropDownOptions(options: DropdownInpt[] ) {
    this.options = options;
    this._temp = options;
  };

  @Output()
  public onChange = new EventEmitter();

  @Output()
  public onItemSelection = new EventEmitter();

  showDropDownOptions = false;

  constructor() { }

  ngOnInit(): void {
  }

  filterOptions(){
    if(this.inputText && this.options.length > 0){
       this.options = this._temp.filter(opt => opt.label.toLocaleLowerCase().startsWith(this.inputText.toLocaleLowerCase()));
    }
  }
}
