
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError } from 'rxjs';
import { PropertyDescriber } from '@app/_alchemint/alchemint_composite_requests';

@Injectable({ providedIn: 'root' })
export class ExternalRequestrService {
    constructor(private http: HttpClient) { }


    public verifyFormAgainstStoredResult (formObj : any, createdObject: any, dontCheckFields : string[] = []) : string
    {
  
      if (createdObject == null)
      {
        return "No created object was returned";
      }


      var verified : boolean = false;
      var unMatchedPropDetails : string = null;

      Object.entries(formObj).forEach(
        ([key, value]) => 
        {
  
          var storedVal : any = this.getPropValueByName(createdObject, key);
          var noMatched : boolean = false;
          

          if (dontCheckFields.includes(key))
          {
            noMatched = false;
          }
          else if (Object.prototype.toString.call(value) === '[object Date]')
          {

            var dtVal : any = value;

            var currDate : Date = new Date();
            var timeZoneHoursOffset : number = Math.round((-1) * ((currDate.getTimezoneOffset() / 60)));

            if((new Date(storedVal)) != (new Date(dtVal)))
            {
                var difHours = this.getDifferenceInHours(new Date(storedVal), new Date(dtVal));

                if ((difHours != timeZoneHoursOffset) && (difHours != 0))
                {
                    noMatched = true;    
                }
            }
          }
          else if (storedVal != value)
          {
              if ((!storedVal) && (value == ''))
              {
                noMatched = false;    
              }
              else 
              {
                noMatched = true;
              }
            
          }

          if (noMatched == true)
          {
            if (unMatchedPropDetails == null)
            {
                unMatchedPropDetails = '';
            }

            unMatchedPropDetails += `Property: ${key} Stored Value : ${storedVal} Submitted Value : ${value}`;
          }
  
        }
      );
  
      return unMatchedPropDetails;
  
    }
  
    private getPropValueByName (obj : any, propName : string ) : any
    {
      var val : any = null;
      var propertyFound : boolean = false;

      var searchString : string; 
      
      searchString = propName.toLocaleLowerCase().replace(/\s/g,'');

      Object.entries(obj).forEach(
        ([key, value]) => 
        {
           if (key.toLocaleLowerCase().replace('p_', '') === searchString)
           {
             val =  value;
             propertyFound = true;
            //  console.log(`Found Prop ${propName}`);
           }
        }
      );
  
      if (propertyFound === false)
      {
        console.warn(`Missing Prop ${searchString}`);
        return null;
      }
      else
      {
        return val;
      }
      
    }
  
    private getDifferenceInHours(date1, date2) {
        const diffInMs = Math.abs(date2 - date1);
        return diffInMs / (1000 * 60 * 60);
      }


    isValidDateYYYY_MM_DD(dateString: string): boolean {
      const date = new Date(dateString);
      return !isNaN(date.getTime());
    }

    public isValidDate (value : any) : boolean
      {
        var validDob : boolean = false;
    
        if (value)
        {
          if (Object.prototype.toString.call(value) === "[object Date]") {
            if (isNaN(value.getTime())) 
            {  
              validDob = false;
            } else {
              validDob = true;
            }
          } else {
            validDob = false;
          }
        }
        else
        {
          validDob = false;
        }
    
        return validDob;
      }
    
    public isValidNumber (value : any) : boolean
      {
        var validNumber : boolean = false;
    
        if (value)
        {
          if ((isNaN(value)) || (value == null)) 
          {
            validNumber = false;
          }
          else 
          {
            validNumber = true;
          }
        }
        else
        {
          validNumber = false;
        }
    
        return validNumber;
      }
    
    
      IsHidden(propnam : string, props : PropertyDescriber []) : boolean
      {
    
        var hidden : boolean = false;
    
        props.forEach(
          (value) => 
          {
            if (value.name.toLowerCase() == 'id')
            {
              hidden = true;
            }
            else if (value.name.toLowerCase() == propnam.toLowerCase())
            {
              hidden =  (value.isHidden || value.isComputed || value.isInternal);
            }
          }
        );
    
        return hidden;
      }
    
    FieldDesc(propnam : string, props : PropertyDescriber []) : string
      {
        var desc : string = 'NOT found';
        props.forEach(
          (value) => 
          {
            if (value.name.toLowerCase() == propnam.toLowerCase())
            {
              desc = value.label;
            }
          }
        );
    
        return desc;
      }
    
    
}