import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { SupportDeskUser } from '@app/_alchemint/alchemint_dm';
import { AlchemintSharedService } from '@app/alchemint-shared.service';
import { HelpDeskService } from '@app/help-desk.service';

@Component({
  selector: 'app-support-desk-logon',
  templateUrl: './support-desk-logon.component.html',
  styleUrls: ['./support-desk-logon.component.scss'],
  providers: [HelpDeskService]
})
export class SupportDeskLogonComponent {
  loginForm: FormGroup=null;
  loginFailed: boolean = false;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<SupportDeskLogonComponent>,
    private helpDeskService: HelpDeskService,
    private alchemintSharedService: AlchemintSharedService
  ) {

    var masterApiKey = this.helpDeskService.getSupportDeckApiKey()


    this.loginForm = this.fb.group({
      commandCentreKey: [masterApiKey, Validators.required],
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  onLogin() {
    const { commandCentreKey, username, password } = this.loginForm.value;
    var sub = this.helpDeskService.logonSupportDeskUser(commandCentreKey, username, password).subscribe((result : SupportDeskUser) => {
      if (result) {
        this.dialogRef.close(result);
      } else {
        this.loginFailed = true;
      }
    },
    (err) => {
      this.loginFailed = true;
      this.alchemintSharedService.oppenErrDialogForObjectErr(err);
      sub.unsubscribe();
    },
    () => {
      sub.unsubscribe();
    }
    );
  }

  onCancel() {
    this.dialogRef.close();
  }
}
