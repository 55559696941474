import { Component, OnInit, ViewChild, Output, EventEmitter, AfterViewInit, Inject, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExternalPersonComponent } from '@app/external-person/external-person.component';
import { IHideable } from '@app/_alchemint/alchemint-interfaces';
import { AlchemedPreLoadData, CompositePatient, FieldCustomizer, TextDTO, UserSeletInfo } from '@app/_alchemint/alchemint_composite_requests';
import { Patient } from '@app/_alchemint/alchemint_dm';
import { ApiInterfaceService } from '@app/_services/alchemint.apiinterface.service';
import { AuthenticationService } from '@app/_services';
import { eBillingMethods } from '@app/_alchemint/alchemint_billing';
import { WebApiInterfaceService } from '@app/_services/alchemint.webapiinterface.service';
import { firstValueFrom } from 'rxjs';
import { AlchemintSharedService } from '@app/alchemint-shared.service';
import { ImageManipulationService } from '@app/_services/image-manipulation.service';
import { CameraComponent } from '@app/camera/camera.component';
import { WebcamImage } from 'ngx-webcam';

@Component({
  selector: 'app-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.scss']
})
export class PatientComponent implements OnInit, IHideable, AfterViewInit, OnDestroy {

  @ViewChild(CameraComponent)
  public cameraComponent: CameraComponent;

  private _preLoadData: AlchemedPreLoadData
  @Input() set preLoadData(val: AlchemedPreLoadData) {
    this._preLoadData = val;
  }
  get preLoadData(): AlchemedPreLoadData {
    return this._preLoadData;
  }


  @ViewChild(ExternalPersonComponent)
  private externalPersonComponent: ExternalPersonComponent;

  public patient: Patient;

  private addMode: boolean = false;
  public useIdNumberToDeduceDateOfBirth: boolean = false;
  public fieldCustomizers: FieldCustomizer[] = null;
  public fieldCustomizerErrors: string;

  public tabbedMode: boolean = false;
  public ownAddButtonMode: boolean = false;
  public inDialogMode: boolean = false;
  @Output() patientCreated = new EventEmitter<CompositePatient>();

  @Output() patientUpdated = new EventEmitter<CompositePatient>();
  @Output() errorEvent = new EventEmitter<string>();
  public visible: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public userSeletInfo: UserSeletInfo,
    private apiInterfaceService: ApiInterfaceService,
    private webApi: WebApiInterfaceService,
    private authenticationService: AuthenticationService,
    public modal: MatDialogRef<PatientComponent>,
    private alchemintSharedService: AlchemintSharedService,
    private imageManipulationService: ImageManipulationService,
    private cdr: ChangeDetectorRef
  ) { }


  public allowPastingPatientImage: boolean = false;
  ngAfterViewInit(): void {

    if (this.modal?.componentInstance) {
      this.inDialogMode = true;
      this.tabbedMode = true;
      this.visible = true;

      if (this.userSeletInfo?.preLoadData) {
        this.preLoadData = this.userSeletInfo?.preLoadData;
        this.useIdNumberToDeduceDateOfBirth = this.userSeletInfo?.deriveDateOfBirthFromIdNumber ?? false;
      }
      if (this.userSeletInfo?.fullPatientDetails) {
        this.addMode = false;
        this.SetPatient(this.userSeletInfo?.fullPatientDetails?.patient);
      }
      else if (this.userSeletInfo?.patientId) {
        this.addMode = false;
        var pat = new Patient();
        pat.id = this.userSeletInfo?.patientId;
        this.SetPatient(pat);
      }
      else {
        this.addMode = true;
        this.SetPatient(null);
      }


      if (this?.userSeletInfo?.mobileDevice)
      {
        this.mobileDevice = this.userSeletInfo?.mobileDevice;
      }


      this.allowPastingPatientImage = this.userSeletInfo?.allowPastingPatientImage;



      //this._aiImagePastingEnabled = this.preLoadData?.apiKeyDetails?.licensedForAI;
      // const apiKey = this.authenticationService.getStoredApiKey();
      // if (apiKey) {
      //   this._aiImagePastingEnabled = apiKey.startsWith('30e8c93a-6a83') || apiKey.startsWith('ee808bab-5ef4-4b35') || apiKey.startsWith('915eff8e-9d23');
      // }
      // if (this.preLoadData?.apiKeyDetails?.licensedForTranscriptions === true) 
      if (this.userSeletInfo?.allowPastingPatientImage)
      {
        if ((this.inDialogMode === true))
        {
          this.checkIsClipboardContentImage();
          this.intervalId = setInterval(() => {
            this.checkIsClipboardContentImage();
          }, 3000);
        }
      }

    }

    if (this.externalPersonComponent) {
      this.externalPersonComponent.inDialogMode = this.inDialogMode;
    }

  }

  public mobileDevice: boolean = false;


  intervalId: any;
  ngOnInit(): void {

  }

  public SetPatient(patient: Patient): void {

    this.patient = patient;
    if (patient) {
      this.addMode = false;
      this.apiInterfaceService.getPatientWebGuiApiCall(patient.id).subscribe(
        e => {

          this.externalPersonComponent.useIdNumberToDeduceDateOfBirth = this.useIdNumberToDeduceDateOfBirth;
          this.externalPersonComponent.fieldCustomizers = this.fieldCustomizers;
          this.externalPersonComponent.fieldCustomizerErrors = this.fieldCustomizerErrors;
          this.externalPersonComponent.isExternalMode = false;
          this.externalPersonComponent.addNewPatientMode = false;
          this.externalPersonComponent.patient = e.patient;
          this.externalPersonComponent.biographicalDetails = e.biographicalDetails;
          this.externalPersonComponent.contactDetails = e.contactDetails;
          this.externalPersonComponent.personResponsibleForAccount = e.personResponsibleForAccount;

          this.externalPersonComponent.patientProps = e.patientProperties;
          this.externalPersonComponent.biographicProperties = e.biographicProperties;
          this.externalPersonComponent.contactProperties = e.contactProperties;
          this.externalPersonComponent.personResponsibleProperties = e.personResponsibleProperties;
          this.externalPersonComponent.isExternalRequest = false;

          try {
            this.externalPersonComponent.medicalAidSchemes = e.medicalAidSchemes;
          }
          catch (err) {

          }

          let counMedAidSchemes = e.medicalAidSchemes?.length;

          if ((this.preLoadData?.apiKeyDetails?.bilingMethod === eBillingMethods.eIntegratedBillingModule) && (counMedAidSchemes > 0)) {
            this.externalPersonComponent.entEditPersRes.useMedicalAidComponent = true;
          }
          else {
            this.externalPersonComponent.entEditPersRes.useMedicalAidComponent = false;
          }

          this.externalPersonComponent.buildForm();

          this.sizeClass = "";

          if (this.tabbedMode) this.setDefaultTeab();
        },
        error => { this.setError(error); }
      )
    }
    else {
      this.addMode = true;
      this.apiInterfaceService.getNewPatientWebGuiApiCall().subscribe(
        e => {

          if (this.externalPersonComponent) {
            this.externalPersonComponent.isExternalMode = false;
            this.externalPersonComponent.addNewPatientMode = true;
            this.externalPersonComponent.patient = e.patient;
            this.externalPersonComponent.biographicalDetails = e.biographicalDetails;
            this.externalPersonComponent.contactDetails = e.contactDetails;
            this.externalPersonComponent.personResponsibleForAccount = e.personResponsibleForAccount;

            this.externalPersonComponent.patientProps = e.patientProperties;
            this.externalPersonComponent.biographicProperties = e.biographicProperties;
            this.externalPersonComponent.contactProperties = e.contactProperties;
            this.externalPersonComponent.personResponsibleProperties = e.personResponsibleProperties;
            this.externalPersonComponent.isExternalRequest = false;
            this.externalPersonComponent.buildForm();

          }


          this.sizeClass = "";
          if (this.tabbedMode) this.setDefaultTeab();
        },
        error => {
          this.setError(error);
        }
      )



    }



  }

  private setDefaultTeab(): void {
    this.onTabValChange(this.PATIENT_TAB_SELECTOR);
  }
  private _sizeClass: string = "col-md-6 offset-md-3 mt-5";

  get sizeClass(): string {
    return this._sizeClass;
  }
  set sizeClass(value: string) {

    this._sizeClass = value;
    if (this.externalPersonComponent) {
      this.externalPersonComponent.sizeClass = value;
    }
  }


  addOrEditCompleted(compPat: CompositePatient): void {

    if (this.inDialogMode) {
      this.modal.close(compPat);
    }
    else if (this.addMode) {
      this.patientCreated?.emit(compPat);
    }
    else {
      this.patientUpdated?.emit(compPat);
    }

    this.SetPatient(compPat.patient);
    this.addMode = false;

  }

  private setError(error: any): void {
    this.errorEvent?.emit(error);
  }

  public get dynamicClass(): string {
    if (this.inDialogMode) {
      return "dialog-mode";
    }
    else {
      return null;
    }

  }

  onNoClick(): void {
    this.modal.close();
  }

  public PATIENT_TAB_SELECTOR: string = 'PAT';
  public BIO_TAB_SELECTOR: string = 'BIO';
  public CONTACT_TAB_SELECTOR: string = 'CONT';
  public PERS_RESP_TAB_SELECTOR: string = 'PERSRESP';

  onTabValChange(selector: string) {
    // this.externalPersonComponent.entityEditorComponentPatient.dynamicCardClass = "card-dk card-for-dialig-mode";
    if (this.externalPersonComponent) {
      this.externalPersonComponent.entityEditorComponentPatient.visible = (selector === this.PATIENT_TAB_SELECTOR);

      this.externalPersonComponent.entityEditorComponentBiographical.visible = (selector === this.BIO_TAB_SELECTOR);
      this.externalPersonComponent.entEditContact.visible = (selector === this.CONTACT_TAB_SELECTOR);
      this.externalPersonComponent.entEditPersRes.visible = (selector === this.PERS_RESP_TAB_SELECTOR);
      if (this.externalPersonComponent.fillPersonResponsibleButton) {
        this.externalPersonComponent.fillPersonResponsibleButton.nativeElement.hidden = (selector !== this.PERS_RESP_TAB_SELECTOR);
      }


    }
    // this.externalPersonComponent.addOrSaveButton.nativeElement.hidden = (selector !== this.PATIENT_TAB_SELECTOR);

    // else     if (selector == this.BIO_TAB_SELECTOR)
    // {

    // }
    // else if (selector == this.CONTACT_TAB_SELECTOR)s
    // {

    // }
    // else if (selector == this.PERS_RESP_TAB_SELECTOR)
    // {

    // }



  }


  file: File | null = null; // Variable to hold the file

  handlePaste(event: ClipboardEvent): void {


    
    const items = event.clipboardData?.items;
    if (items) {
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.type.indexOf('image') === 0) {
          event.preventDefault();
          const blob = item.getAsFile();
          if (blob) {
            this.file = new File([blob], `pasted-image.${blob.type.split('/')[1]}`, {
              type: blob.type
            });
            this.doPatientExtraction();
          }
          break; // Exit loop after handling the first image
        }
      }
    }
  }

  public aiPasting: boolean = false;
  async doPatientExtraction() {

    try {

      if (this.file) {

        if (this.allowPastingPatientImage === false) {
          this.alchemintSharedService.notLicensedForAITranscriptionFeaturesMessage();
          return;
        }

        this.aiPasting = true;

        var fileResized = this.file;
        //  await firstValueFrom(this.imageManipulationService.resizeFileUntilSmallerThan(this.file, 100000,10));


        var result: TextDTO = await firstValueFrom(this.webApi.imagetoPatientRecordUsingOpenAi(fileResized));

        var completePatient: any = JSON.parse(result.text);

        var patient = completePatient.Patient;
        var contactdetails = completePatient.ContactDetails;
        var biodetails = completePatient.BiographicalDetails;
        var persresp = completePatient.PersonResponsibleForAccount;

        patient.id = "NEW";
        biodetails.id = null;
        biodetails.patientId = null;
        contactdetails.id = null;
        contactdetails.patientId = null;

        this.externalPersonComponent.tryPopulateContactDetails(JSON.stringify(contactdetails));

        this.externalPersonComponent.tryPopulatePatient(JSON.stringify(patient));

        this.externalPersonComponent.tryPopulateBiographicalDetails(JSON.stringify(biodetails));

        //this.externalPersonComponent.tryPopulatePersonResponsibleForAccount(JSON.stringify(persresp));

        this.aiPasting = false;
      } else {
        alert('No image was pasted.');
        this.alchemintSharedService.messageDialogSync('No image was pasted.')
      }

    } catch (error) {
      this.aiPasting = false;
      this.alchemintSharedService.oppenErrDialogForObjectErr(error);
    }

  }


  isClipboardAvailable(): boolean {
    return !!navigator.clipboard;
  }

  isClipboardContentImage: boolean = false;

  async checkIsClipboardContentImage() {

    try {

      if (this.inDialogMode === false) 
      {
        this.isClipboardContentImage = false;
        return;
      }


      if (!this.isClipboardAvailable()) {
        this.isClipboardContentImage = false;
      }

      const clipboardItems = await navigator.clipboard.read();
      for (const item of clipboardItems) {
        if (item.types.includes('image/png') || item.types.includes('image/jpeg')) {
          this.isClipboardContentImage = true;
        }
        else {
          this.isClipboardContentImage = false;
        }
      }
    } catch (err) {

      this.isClipboardContentImage = false;
    }

    //this.isClipboardContentImage = true;
  }

  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  async magicClipBoardPaste() {
    if (this.allowPastingPatientImage === false)
    {
      this.alchemintSharedService.notLicensedForAITranscriptionFeaturesMessage();
      return;
    }
    this.file = await this.retrieveClipboardItemAsFile();
    this.doPatientExtraction();
  }

  public cameraVisible: boolean = false;

  async magicCameraPatientCreation() {

    if (this.allowPastingPatientImage === false)
    {
      this.alchemintSharedService.notLicensedForAITranscriptionFeaturesMessage();
      return;
    }


    if (this.cameraVisible === false) {
      this.cameraVisible = true

      this.cdr.detectChanges();

      // this.cameraComponent.cameraSettingsContainerStyle = "background-color: grey; "


      this.cameraComponent.cameraCancelled?.subscribe(x => {
        this.cameraVisible = false;
        this.cdr.detectChanges();
      }
      );

      this.cameraComponent.handleImage = (webcamImage: WebcamImage) => {
        //console.info('Saved webcam image', webcamImage);
        //this.webcamImage = webcamImage;

        this.cameraComponent.visible = false;
        this.cameraVisible = false;

        var imageData: ImageData = webcamImage.imageData;

        this.imageManipulationService.ImageDataToBlob(imageData).then(
          async blob => {

            var file: File = await this.imageManipulationService.blobToFile(<Blob>blob, 'image.jpg');
            var resized: File = await firstValueFrom(this.imageManipulationService.resizeFileUntilSmallerThan(file, 1000000, 95));



            this.file = resized;
            this.doPatientExtraction();
          }
        );
      }




      this.cameraComponent.visible = true;

      this.cameraComponent.promptPhoto(null, false);
      //this.cameraComponent.triggerSnapshot();
    }
    else {
      this.cameraVisible = false;
      this.cdr.detectChanges();
      this.cameraComponent.visible = false;
    }

  }






  async retrieveClipboardItemAsFile(): Promise<File | null> {

    try {
      if (!this.isClipboardAvailable() || document.hidden) {

        return null;
      }

      const clipboardItems = await navigator.clipboard.read();
      for (const item of clipboardItems) {
        if (item.types.includes('image/png') || item.types.includes('image/jpeg')) {
          const blob = await item.getType(item.types[0]);
          const file = new File([blob], `clipboard.${item.types[0].split('/')[1]}`, { type: blob.type });
          return file;
        }
      }
      return null;
    } catch (err) {
      // console.error('Could not read clipboard items: ', err);
      return null;
    }
  }

  // private _aiImagePastingEnabled: boolean = false;
  // public get aiImagePastingEnabled(): boolean {
  //   return this._aiImagePastingEnabled;
  // }


}

