import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { DomSanitizer } from '@angular/platform-browser';
import { EnvService, Environment } from '@app/_services/environment.service';
import { browser } from 'protractor';
import { delay } from 'rxjs';
import { InLineAssistanceItem, InLineAssistanceService } from './in-line-assistance.service';
import { AlchemintSharedService } from '@app/alchemint-shared.service';
import { Buttons } from '@app/message-box/message-box.module';


@Component({
  selector: 'app-in-line-assistance',
  templateUrl: './in-line-assistance.component.html',
  styleUrls: ['./in-line-assistance.component.scss']
})
export class InLineAssistanceComponent implements OnInit, AfterViewInit {

  constructor(public inLineAssistanceService: InLineAssistanceService, public envService : EnvService, public sanitizer: DomSanitizer, private alchemintSharedService: AlchemintSharedService) { }
  
  private _inLineAssistanceItem : InLineAssistanceItem;
  @Output() public videoOpened : EventEmitter<InLineAssistanceComponent> = new EventEmitter<InLineAssistanceComponent>();

  @Input ()
  public toolTipIconVisible : boolean = true;

  @Input ()
  public showVideoName : string = null;


  @Input ()
  public flagAsNewFeature : boolean = false;

  @Input ()
  public set flagAsNewStyling (value : string)
  {
    this._flagAsNewStyling = value;
  }
  public get flagAsNewStyling () : string
  {
    return this._flagAsNewStyling + this.flagAsNewColorStyle;
  }  

  public get isSafari(): boolean {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }


  private _flagAsNewStyling : string = "font-size: medium;   transform: rotate(340deg);";
  // private _flagAsNewStyling : string = "font-size: x-small;   transform: rotate(340deg);";
  @Input ()
  public flagAsNewColor : string  = "green";

  public get flagAsNewColorStyle () : string 
  {
    return `color:${this.flagAsNewColor};`
  } 


  @Input ()
  public flagAsNewText : string  = "🆕";

  @Input ()
  public flagAsNewHyperlink : string  = null;

  @Input ()
  public set videoLink (value:  string)
  {
    this._inLineAssistanceItem.videoLink = value;
  }
  public get videoLink () :  string
  {
    return this._inLineAssistanceItem.videoLink;
  }
  @Input ()
  public showVideoInDialogWindow : boolean  = false;

  @Input ()
  public autoStartVideo : boolean = false;

  @Input ()
  public hideOpenCloseIcon : boolean = false;


  public mouseEnter : number = 0;
  public mouseLeave : number = 0;
  public mouseOver : number = 0;
  public mouseOut : number = 0;

  @ViewChild('tooltip') matToolTip: MatTooltip;
  ngOnInit(): void {
  
    
  }

  ngAfterViewInit(): void {
    if (this.autoStartVideo===true)
    {
      this.showVideo();
    }
    
  }
  //private _helpContent : string; 
  private _uiItemIdentifier : string;

  public documentationMode : boolean = false;

  public get inLineAssistanceItem () : InLineAssistanceItem 
  {
    return this._inLineAssistanceItem;
  }
  

  public get HelpContent () : string 
  {
    // return (this._inLineAssistanceItem?.toolTip)?.replace('\n', '&#13;');
    return this._inLineAssistanceItem?.toolTip;
  }

  
  public set HelpContent (value : string)  
  {
    if (this._inLineAssistanceItem === null)
    {
      this._inLineAssistanceItem = new InLineAssistanceItem(this._uiItemIdentifier, null, null, null, null, null);
    }
    this._inLineAssistanceItem.toolTip = value;

    this.inLineAssistanceService.cacheInLineAssistanceItemToLocalStorage (this._inLineAssistanceItem);

  }


  public showHelp () : void
  {
    this.matToolTip.show();

    //alert (this.HelpContent ?? `No help available for ${this._uiItemIdentifier}`);
  }
  public hideHelp () : void
  {
    this.matToolTip.hide();

    //alert (this.HelpContent ?? `No help available for ${this._uiItemIdentifier}`);
  }

  public showWebHelp () : void
  {
    if (this._inLineAssistanceItem?.helpLink)
    {
      window.open(this._inLineAssistanceItem?.helpLink);
    }
    
  }

  public get uiItemIdentifier () : string 
  {
    return this._uiItemIdentifier;
  }
  

  _isBetaFeature : boolean = false;

  @Input ()
  public set IsBetaFeature (value : boolean)
  {
    this._isBetaFeature = value;
  }

  public get IsBetaFeature () : boolean
  {
    return this._isBetaFeature;
  }

  @Input ()
  public set content (value: string)
  {
    this._uiItemIdentifier = "NONE";
    this._inLineAssistanceItem =  new InLineAssistanceItem(this._uiItemIdentifier, value, null, null, null, null);
  }

  private _iconCode : string = 'help';
  @Input ()
  public set iconCode (value: string)
  {
    this._iconCode = value;
  }

  public get iconCode () : string 
  {
    return this._iconCode;
  }

  private _iconColor : string = '#8eba13';
  @Input ()
  public set iconColor (value: string)
  {
    this._iconColor = value;
  }

  public get iconColor () : string 
  {
    return this._iconColor;
  }

  public get iconColorStyle () : string 
  {
    return `color: ${this._iconColor}`;
  }

  public get svgColorClass () : string 
  {
    if (this._iconColor === 'orange')
    {
      return 'custom-svg-icon-orange';
    }
    else
    {
      return 'custom-svg-icon-green';
    }
  }

  _showAsInlineContent : boolean = false;

  @Input ()
  public set showAsInlineContent (value: boolean)
  {
    this._showAsInlineContent = value;
  }

  public get showAsInlineContent (): boolean
  {
    return this._showAsInlineContent;
  }



  @Input ()
  public set uiItemIdentifier (value: string)
  {
    this._uiItemIdentifier = value;
    if (this.inLineAssistanceService.AllowContentEditing)  
    {
      var find = this.inLineAssistanceService.generateHelpContent().filter(x => x.id === value);
      if (find.length > 0)
      {
        this._inLineAssistanceItem =  find[0];
      }
      else
      {
        this._inLineAssistanceItem =  this.inLineAssistanceService.getInLineAssistanceItem(value);
      }
      

    }
    else
    {
      this._inLineAssistanceItem =  this.inLineAssistanceService.getInLineAssistanceItem(value);
    }
  }

  public get OnlineHelpAvailable () : boolean
  {
    if (this._inLineAssistanceItem?.helpLink)
    {
      return true;
    }
    else
    {
      return false;
    }
  }

  public get OnlineHelpLink () : string
  {
    if (this.envService.env === Environment.Dev)
    {
      return this.inLineAssistanceItem?.helpLink?.replace("https://sys.alchemed.com/app", "http://localhost:4200");
    }
    else
    {
      return this.inLineAssistanceItem?.helpLink
    }
    

  }
  
  @Input() set useIFrmaeForVideos (value: boolean)
  {
    this._inLineAssistanceItem.useIFrmaeForVideos = value;
  }

  get  useIFrmaeForVideos () : boolean
  {
    return this._inLineAssistanceItem.useIFrmaeForVideos;
  }

  public get ToolTipAvailable () : boolean
  {
    if (this._inLineAssistanceItem?.toolTip)
    {
      return true;
    }
    else
    {
      return false;
    }
  }

  public creatDocumentationObjectOnClipBoard () : void
  {
    var itm = new InLineAssistanceItem(this._uiItemIdentifier, '', '', '', '', '')
    navigator.clipboard.writeText(JSON.stringify(itm)).then().catch(e => console.error(e));
  }

  public get editStyle () : string
  {
    if (this.HelpContent?.length > 0)
    {
      return "background-color: white;'"; 
    }
    else
    {
      return "background-color: yellow;'"; 
    }
    
  }
  shown : number = 0;

  showingToolTip : boolean = false;

  public NaviveToolTipElement () : HTMLElement
  {

    if (this.matToolTip)
    {
      return (<any>this.matToolTip)._elementRef.nativeElement;
    }
    else
    {
      return (<any>this.matToolTip)._elementRef.nativeElement;
    }
    
  }
  public showToolTip () 
  {


    if ((<any>this.matToolTip)._elementRef.nativeElement.hidden == false)
    {
      this.showingToolTip = false;
      this.shown++;
      this.matToolTip.show();

    }

    var interval = setInterval(() => {
      this.showingToolTip = false;
    }, 1000)

  //  console.log(this.matToolTip);
  }

  public hideToolTip () 
  {
    if (this.showingToolTip === false)
    {
      this.matToolTip.hide();
    }
  }

  @Input()
  public videoVisible : boolean = false;

  showVideo() 
  {
    
    if (this.showVideoInDialogWindow===true)
    {
     
      this.alchemintSharedService.openMessageBox(this._inLineAssistanceItem.title, null, Buttons.Ok, this._inLineAssistanceItem.videoLink).subscribe();
    }
    else
    {
      this.videoVisible =! this.videoVisible;
      if (this.videoVisible === true)
      {
        this.videoOpened?.emit(this);
      }
    }
  
  }


  public get sanitizedVideo () : any
  {
    if (this.inLineAssistanceItem?.videoLink)
    {
      // return this.inLineAssistanceItem?.videoLink;
      return this.sanitizer.bypassSecurityTrustResourceUrl(this.inLineAssistanceItem?.videoLink);
    }
    else
    {
      return null;
    }
    
  }

}





