import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class SimulatorInterfaceService {

  constructor(private http: HttpClient) { }

  public getRoutingModuleTemplate(): Observable<string> {
    return this.http.get('assets/template-routing.module.txt', { responseType: 'text' });
  }
  
  public getModuleTemplate(): Observable<string> {
    return this.http.get('assets/template.module.txt', { responseType: 'text' });
  }

}


export class CodeModifyInstruction {
  public directory: string;
  public fileName: string;
  public removeLineStart: number;
  public removeLineEnd: number;
  public insertionLine: number;
  public insertionCode: string;
}
