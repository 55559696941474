import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Route } from '@angular/router';
import { isDevMode } from '@angular/core';
import { AuthenticationService } from '@app/_services';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService, 
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const user = this.authenticationService.userValue;
        var okToActivate: boolean = false;

        if (this.authenticationService.useNewAuthenticationMethod)
        {
            if (this.authenticationService.getStoreLogidInUser())
            {
                okToActivate = true;
            }
            else
            {
                okToActivate = false;
            }
        }
        else
        {
            if (user)
            {
                okToActivate = true;
            }
            else
            {
                okToActivate = false;
            }
            
        }
        

        var rt = route;

        var rtcfg : Route [] = this.router.config;
        //console.log('Routes: ', JSON.stringify(router.config, replacer, 2));

        if (state.url.indexOf('login') == 1)
        {
            if ((window.location.hostname == "www.alchemint.net") || isDevMode())
            {
                return true;
            }
            else 
            {
                return true;
            }
            
        }
        else if (this.authenticationService.integratedLoggedIn)
        {
            return true;
        }
        else if (okToActivate) 
        {
            // logged in so return true
            return true;
        } 
        else {
            // not logged in so redirect to login page with the return url
            if (window.location.hostname == "www.alchemint.net")
            {
                this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            }
            else
            {
                this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            }
            return false;
        }
    }
}