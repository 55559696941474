import { Injectable, NgZone } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SpeechService {
  private recognition: any;
  public isListening = false;
  private _lastCommand: string = '';

  constructor(private zone: NgZone) {
    
    try {
      const SpeechRecognition = (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;
      this.recognition = new SpeechRecognition();
      this.recognition.continuous = true;
      this.recognition.lang = 'en-US';
      this.recognition.interimResults = false;
  
      this.recognition.onresult = (event: any) => {
        const transcript = event.results[event.resultIndex][0].transcript.trim();
        this.zone.run(() => {
          this._lastCommand = transcript;
          console.log(transcript); // Handle the recognized text
        });
      };
        
    } catch (error) {
      
    }

  }

  get lastCommand(): string {
    return this._lastCommand;
  }
  set lastCommand(val: string) {
     this._lastCommand = val;
  }

  startListening(): void {
    this.isListening = true;
    this.recognition.start();
  }

  stopListening(): void {
    this.isListening = false;
    this.recognition.stop();
  }


  

  getClosestMatch(input: string, names: string[], threshold: number = 4): string | null {
    let closestMatch = '';
    let smallestDistance = Infinity;

    for (let name of names) {
      const distance = this.levenshteinDistance(input.toLowerCase(), name.toLowerCase());
      if (distance < smallestDistance) {
        smallestDistance = distance;
        closestMatch = name;
      }
    }

    return smallestDistance <= threshold ? closestMatch : null;
  }

  private levenshteinDistance(a: string, b: string): number {
    const matrix = [];

    for (let i = 0; i <= b.length; i++) {
      matrix[i] = [i];
    }

    for (let j = 0; j <= a.length; j++) {
      matrix[0][j] = j;
    }

    for (let i = 1; i <= b.length; i++) {
      for (let j = 1; j <= a.length; j++) {
        if (b.charAt(i - 1) === a.charAt(j - 1)) {
          matrix[i][j] = matrix[i - 1][j - 1];
        } else {
          matrix[i][j] = Math.min(
            matrix[i - 1][j - 1] + 1,
            Math.min(matrix[i][j - 1] + 1, matrix[i - 1][j] + 1)
          );
        }
      }
    }

    return matrix[b.length][a.length];
  }
}
