import { Injectable } from '@angular/core';
import { AlchemintSharedService } from '@app/alchemint-shared.service';
import { WebApiInterfaceService } from './alchemint.webapiinterface.service';
import { SupportDeskLogonComponent } from '@app/support-desk-logon/support-desk-logon.component';
import { MatDialog } from '@angular/material/dialog';
import { filter, map, Observable, take, tap } from 'rxjs';
import { SupportDeskUser } from '@app/_alchemint/alchemint_dm';
import { Router } from '@angular/router';
import { re } from 'mathjs';

@Injectable(
  {
    providedIn: 'root'
  }
)
export class BackofficeService {

  public isLoggedOn: boolean = false;

  logoutOfControlCentre(): void {
    localStorage.removeItem('supportDeskUser');
    this.isLoggedOn = false;
    this.router.navigate(['/']);
  }

  getStoredBackofficeUser(): SupportDeskUser {
    var storedUser = localStorage.getItem('supportDeskUser');
    if (storedUser) {
      var user = JSON.parse(storedUser);
      return user;
    }
    else {
      return null;
    }
  }


  
  getBackOfficeUserClaimsToken(): any {

    const user = this.getStoredBackofficeUser();
    if (user) {
      if (user.jwtClaimsToken)
      {
        var jwtToken = JSON.parse(atob(user.jwtClaimsToken?.split('.')[1]));
        return jwtToken;
      }
      else
      {
        console.log('No claims token for support user');
      }
    }
    else {
      return null;
    }
  }

  getBackOfficeUserClaimsTokenString(): string {
    const user = this.getStoredBackofficeUser();
    return user?.jwtClaimsToken;
  }

  getBackOfficeUserClaimsTokenStringUriEncoded(): string {
    var token = this.getBackOfficeUserClaimsTokenString();
    
    if (!token) {
      return null;
    }
    else
    {
      var encodedClaimsToken = encodeURIComponent(token);
      return encodedClaimsToken;
    }
    
  }

  
  public getBAckOfficeUserClaimVendorFilter(): string {
    const jwtToken = this.getBackOfficeUserClaimsToken();
    if (jwtToken) {
      return jwtToken?.VendorFilters;
    }
    else {
      return null;
    }
  }

  public get canUserAddClients(): boolean {
    const jwtToken = this.getBackOfficeUserClaimsToken();
    if (jwtToken) {
      return (jwtToken?.CanAddClients?.toLocaleLowerCase() === 'true')
    }
    else {
      return false;
    }
  }

  public get canUserEditClients(): boolean {
    const jwtToken = this.getBackOfficeUserClaimsToken();
    if (jwtToken) {
      return (jwtToken?.CanEditClients?.toLocaleLowerCase() === 'true')
    }
    else {
      return false;
    }
  }

  public get canUserUseHelpDesk(): boolean {
    const jwtToken = this.getBackOfficeUserClaimsToken();
    if (jwtToken) {
      return (jwtToken?.CanUseHelpDesk?.toLocaleLowerCase() === 'true')
    }
    else {
      return false;
    }
  }

  public get canUserViewReports(): boolean {
    const jwtToken = this.getBackOfficeUserClaimsToken();
    if (jwtToken) {
      return (jwtToken?.CanViewReports?.toLocaleLowerCase() === 'true')
    }
    else {
      return false;
    }
  }
  
  public get canUserViewSensitiveReports(): boolean {
    return this.isRootBackofficeUser;
  }

  public get canUserViewReportsMenu(): boolean {
    return this.isRootBackofficeUser;
  }

  public get canUserChangeVendorCode(): boolean {
    return this.isRootBackofficeUser;
  }
  public get canUserManageUserAccess(): boolean {
    return this.isRootBackofficeUser;
  }
  

  public get canUseDevRobot(): boolean {
    return this.isRootBackofficeUser;
  }


  public get canViewCPDAttendanceRegister(): boolean {
    return this.isRootBackofficeUser;
  }


  public get canViewRecentFeaturesAdded(): boolean {
    return this.canUserViewReports;
  }

  public get canViewCustomerTakeOnDetails(): boolean {
    return this.isRootBackofficeUser;
  }

  

  public get canViewRegisteeredContacts(): boolean {
    return this.isRootBackofficeUser;
  }

  public get canViewRegisteeredPatients(): boolean {
    return this.isRootBackofficeUser;
  }

  public get isRootBackofficeUser(): boolean {
    const jwtToken = this.getBackOfficeUserClaimsToken();
    if (jwtToken) {
      return (jwtToken?.IsRootAlchemedUser?.toLocaleLowerCase() === 'true')
    }
    else {
      return false;
    }
  }

  




  logonToControlCentre(): Observable<SupportDeskUser> {

    var storedUser = localStorage.getItem('supportDeskUser');
    if (storedUser) {
      var user = JSON.parse(storedUser);
      this.isLoggedOn = true;
      return new Observable<SupportDeskUser>(observer => {
        observer.next(user);
        observer.complete();
      });
    }
    else {
      const dialogRef = this.dialog.open(SupportDeskLogonComponent, {
        width: '300px',
        autoFocus: true,
        disableClose: true
      });

      return dialogRef.afterClosed().pipe(
        filter(result => !!result), // Ensures the dialog was closed with a result
        tap((result: SupportDeskUser) => {
          this.isLoggedOn = true;
          localStorage.setItem('supportDeskUser', JSON.stringify(result));
        }),
        map((result: SupportDeskUser) => result) // Cast result to SupportDeskUser
      );
    }


  }



  constructor(private router: Router, public dialog: MatDialog, private alchemintSharedService: AlchemintSharedService, private webApiInterfaceService: WebApiInterfaceService) { }
}
