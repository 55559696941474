import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProcedureCode } from '@app/_alchemint/alchemint_dm';
import { Observable, shareReplay } from 'rxjs';
import { ApiInterfaceService } from './alchemint.apiinterface.service';
import { EnvService } from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class proceduresService {

  private apiUri: string;

  constructor(private http: HttpClient, private envService: EnvService, private apiInterfaceService: ApiInterfaceService) {
    this.apiUri = this.envService.deploymentSettings.apiUrl;
  }

  public fetchAllProcedureCodes$(specialityCode: string): Observable<ProcedureCode[]> {
    if (specialityCode)
    {
      return this.http.get<ProcedureCode[]>(`${this.apiUri}Billing/procedurecodes/${specialityCode}`).pipe(shareReplay());
    }
    else
    {
      return this.http.get<ProcedureCode[]>(`${this.apiUri}Billing/procedurecodes`).pipe(shareReplay());
    }
    
  }
}
