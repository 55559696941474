<style type="text/css">
        
    .selected {
        background-color: lightblue;
     
    }

    .right {
        float: right;
        /* width: 300px;
        border: 3px solid #73AD21;
        padding: 10px; */
    }
</style>

<h1 class="dialog-form-heading" mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>{{userSeletInfo.prompt}}</h1>


<div>
    <div>

        <app-window-close-button [dialogRef]="dialogRef"></app-window-close-button>
    
        <!-- <div style="padding-bottom: 2em;">
            <app-window-close-button [dialogRef]="dialogRef"></app-window-close-button>
        
        </div> -->
        
    
        
        
         
        <!-- <p>
            <b>
                {{userSeletInfo.prompt}}
            </b>
            <br>
            
        </p> -->
        
        <!-- <input type="text" class="form-control" [value]="userInputValue" (change)="changeVal($event.target)" >
        <br>
        <br> -->
        
        <input type="text" class="form-control" [(ngModel)]="userInputValue" (keydown.enter)="closeDialog()">
        <br><br>

        <div mat-dialog-actions>
            <!-- type="submit"  -->
            <button [disabled]="!userInputValue" style="margin: 2px;" [mat-dialog-close]="userInputValue" class="btn btn-primary btn-spacing" >
                <svg-icon src="assets/svgs/done_white_24dp.svg"></svg-icon>
            </button>
        </div>
        
        
        
        
    </div>
    
</div>
