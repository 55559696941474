import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from '@app/_services';
import { Router } from '@angular/router';
import { EnvService } from '@app/_services/environment.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {


    errorCount : number = 0;

    constructor(private authenticationService: AuthenticationService, private router : Router, private environmentService : EnvService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {

            this.environmentService.httpErrorCount ++;
            
            // Detailed error logging
            console.error('HTTP Error:', {
                status: err.status,
                statusText: err.statusText,
                url: err.url,
                message: err.message,
                error: err.error,
                name: err.name
            });



            if ([401].includes(err.status) && this.authenticationService.userValue) {
                // auto logout if 401 or 403 response returned from api


                if (this.router.url.includes('bisearch'))
                {
                    return throwError(err);
                }

                const isExtendedApiUrl  = (request.url.indexOf(this.environmentService.deploymentSettings.apiExtendedUrl) > -1);
                //const isExtendedApiUrlForDiary  = (request.url.indexOf(this.environmentService.deploymentSettings.apiExtendedUrl + 'diary') > -1);
                this.authenticationService.reloggingInWithRefreshToken = false;
                if (isExtendedApiUrl)
                {


                    

                    console.log('In interceptor 1');

                    var isBackupComponent = this.router.url.includes('backup');
                    if (this.router.url.includes('backup'))
                    {
                        return throwError(err);
                    }
                    else
                    {
                        //this.authenticationService.storedBearerToken = '';
                        //this.authenticationService.userLoggedOut = false;
                
                        this.authenticationService.userLoggedOut = true;
                        this.authenticationService.logout(false, true, false, false, false, false);
                        return throwError(err);

                    }

                }
                else if(this.router.url.includes('login') == false)
                {
                    console.log('In interceptor 2');
                    this.authenticationService.logout(false, true, false, false, false, false);
                    return throwError(err);
                }
                else
                {
                    console.log('In interceptor 3');
                    return throwError(err);
                }
                
            }
            else if (err.status == '401')
            {
                this.authenticationService.reloggingInWithRefreshToken = false;
                // var typedErr : HttpErrorResponse = <HttpErrorResponse> err;
                // typedErr.statusText = 'Unauthorized';
                // var typedErrNew : HttpErrorResponse = new HttpErrorResponse()
                // return throwError('Access denied');
                return throwError(err);
            }
            else if (err.status == '500')
            {

                console.log(err);

                if (err.error?.detail)
                {
                    return throwError( { message : err.error?.detail });
                }
                else
                {
                    return throwError({message : "A Server error occurred. If the problem persists please contact Alchemed."});                    
                }

            }
            else if (err.status == '0')
            {
                console.log(err);
                return throwError( { message : 'The server could not be reached. Please make sure you are connected to the internet and that the server is reachable.' });
            }
            else 
            {
                this.authenticationService.reloggingInWithRefreshToken = false;
                const error = (err && err.error && err.error.message) || err.statusText;
            
                console.log(err);
                //alert(err.message);
    
                return throwError(err);
            }
        }))
    }

}