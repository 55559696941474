import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { firstValueFrom, Observable } from 'rxjs';
import { BackofficeService } from './_services/backoffice.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuardBackoffice implements CanActivate {

  constructor(private backofficeService: BackofficeService, private router: Router) {}

   canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    
    return new Observable<boolean>(observer => {
      if (this.backofficeService.isLoggedOn)
      {
        observer.next(true);
        observer.complete();
      }      
      else
      {
        this.backofficeService.logonToControlCentre().subscribe(result => {
          observer.next(result !== null);
          observer.complete();
        });
      }

    });

  }
}

@Injectable({
  providedIn: 'root'
})
export class AuthGuardAddClients implements CanActivate {

  constructor(private backofficeService: BackofficeService, private router: Router) {}

   canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    
    return new Observable<boolean>(observer => {
      
      observer.next(this.backofficeService.canUserAddClients);
      observer.complete();
       
 
    });

  }
}

@Injectable({
  providedIn: 'root'
})
export class AuthGuardEditClients implements CanActivate {

  constructor(private backofficeService: BackofficeService, private router: Router) {}

   canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    
    return new Observable<boolean>(observer => {
      
      observer.next(this.backofficeService.canUserEditClients);
      observer.complete();
       
 
    });

  }
}

@Injectable({
  providedIn: 'root'
})
export class AuthGuardUseSupportDesk implements CanActivate {

  constructor(private backofficeService: BackofficeService, private router: Router) {}

   canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    
    return new Observable<boolean>(observer => {
      
      observer.next(this.backofficeService.canUserUseHelpDesk);
      observer.complete();
       
 
    });

  }
}

@Injectable({
  providedIn: 'root'
})
export class AuthGuardUseReports implements CanActivate {

  constructor(private backofficeService: BackofficeService, private router: Router) {}

   canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    
    return new Observable<boolean>(observer => {
      
      observer.next(this.backofficeService.canUserViewReports);
      observer.complete();
       
 
    });

  }
}

@Injectable({
  providedIn: 'root'
})
export class AuthGuardAddNewBackofficeUsers implements CanActivate {

  constructor(private backofficeService: BackofficeService, private router: Router) {}

   canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    
    return new Observable<boolean>(observer => {
      
      observer.next(this.backofficeService.canUserManageUserAccess);
      observer.complete();
 
    });

  }
}

@Injectable({
  providedIn: 'root'
})
export class AuthGuardSuperUsers implements CanActivate {

  constructor(private backofficeService: BackofficeService, private router: Router) {}

   canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    
    return new Observable<boolean>(observer => {
      
      observer.next(this.backofficeService.canUseDevRobot);
      observer.complete();
 
    });

  }
}



// new Claim("", canAddClients.ToString()),
// new Claim("CanEditClients", canEditClients.ToString()),
// new Claim("CanUseHelpDesk", canUseHelpDesk.ToString()),
// new Claim("ResellerFilters", resellerFilters ?? ""),
// new Claim("VendorFilters", vendorFilters ?? "")
