<div  >
    <app-item-selector [fromItems]="masterSet$ | async" [loading]="loadingMasterSets"    [toItems]="activeIcdCodes$ | async"
     [leftBlockLabel]="'Master Procedure Codes(Inactive)'" [rightBlockLabel]="'Activated Procedure Codes'"
     
     (onEdit)="launchEdit($event)"
     
     (onCreateNew)="launchCreate($event)"
    (onActivate)="activate($event)" (onDeactivate)="deactivate($event)" ></app-item-selector>
</div>
<!-- (onEdit)="launchEdit($event)" -->
<button class="btn btn-primary" appDoneButton>
    <app-svg color="white" class="btn-icon"> arrow_back </app-svg> Done
</button>
