<!-- app-svg.component.html -->
<span #textContent style="display: none;"><ng-content></ng-content></span>






<svg-icon #icon [hidden]="(!iconName) || (brokenImage===true)" [src]="svgUrl" [applyClass]="true" [class]="svgClass()" ></svg-icon>


<!-- <mat-icon> {{this.iconName}}</mat-icon> -->
<!-- <textarea>
    FLIP
</textarea> -->


<svg-icon *ngIf="(brokenImage===true)" [src]="defaultBrokenImageUrl" [applyClass]="true" class="custom-svg-icon-red"></svg-icon>




