import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-generic-object-editor',
  templateUrl: './generic-object-editor.component.html',
  styleUrls: ['./generic-object-editor.component.scss']
})
export class GenericObjectEditorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
