import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProcedureCode } from '@app/_alchemint/alchemint_dm';

@Component({
  selector: 'app-edit-procedure-code',
  templateUrl: './edit-procedure-code.component.html',
  styleUrls: ['./edit-procedure-code.component.scss']
})
export class EditProcedureCodeComponent implements OnInit {
  
  public createMode = false;
  public procedureCode: ProcedureCode;
  constructor(public dialogRef: MatDialogRef<EditProcedureCodeComponent>, @Inject(MAT_DIALOG_DATA) private data: ProcedureCode) {
    this.procedureCode = data ?  data :  new ProcedureCode();
    if(!this.procedureCode.id){
        this.createMode = true;
    }
  }

  ngOnInit(): void {
  }
}
