import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlchemintSharedService } from '@app/alchemint-shared.service';
import { UserSelectEntityComponent  } from '@app/user-select-entity/user-select-entity.component';
import { UserSeletInfo } from '@app/_alchemint/alchemint_composite_requests';
import { Artifact, ArtifactType, ArtiForm, ArtiFormFld, ArtiFormState, Contact } from '@app/_alchemint/alchemint_dm';
import { ApiInterfaceService } from '@app/_services/alchemint.apiinterface.service';
import { WebApiInterfaceService } from '@app/_services/alchemint.webapiinterface.service';
import { Observable, throwError } from 'rxjs';
import { ArtiFormHelper } from '@app/_helpers/artiformhelper';
import { boolean } from 'mathjs';

@Injectable({
  providedIn: 'root'
})
export class ArtiFormsService {

  constructor(private apiInterfaceService : ApiInterfaceService, public dialog: MatDialog, private webApiInterfaceService: WebApiInterfaceService, private alchemintSharedService : AlchemintSharedService ) {}

  public userSelectForm (
    prompt : string, 
    makeNoneOptionAvailable : boolean, 
    bypassIfNoForms : boolean, 
    includeDisabledForms : boolean, 
    useSharedFormLibrary: boolean, 
    excludeSickNoteAndScript: boolean, 
    onlyExternalForms: boolean, 
    allowMultiSelect: boolean, 
    includeBillingForms: boolean,
    includeNonBillingForms: boolean,
    includePatientRequestDetails: boolean,
    includePatientSignContracts: boolean,
    ) : Observable<ArtiForm[]>
  {
    return new Observable<ArtiForm[]> (
      observer => 
      {
        var getter : Observable<ArtiForm[]>;
        if (useSharedFormLibrary)
        {
          getter = this.webApiInterfaceService.getArtiFormsFromSharelLibrary();
        }
        else
        {
          // getter = this.webApiInterfaceService.getArtiForms();
          getter = this.webApiInterfaceService.getArtiFormsWithNameLongerThanTwoChars();
          
        }
        getter.subscribe(
          entities => 
          {

            var billingFormsOnly: boolean = false;
            if (entities?.length > 0)
            {
              if ((includeBillingForms === true) && (includeNonBillingForms === true))
              {
                // do nothing
                entities = entities.filter(x => x.isBillableForm === true);
              }
              else if ((includeBillingForms === true) && (includeNonBillingForms === false))
              {
                billingFormsOnly = true;
                entities = entities.filter(x => x.isBillableForm === true);
              }
              else if ((includeBillingForms === false) && (includeNonBillingForms === true))
              {
                entities = entities.filter(x => (x.isBillableForm ?? false) === false);
              }
            }

            
            if ((bypassIfNoForms === true) && ((entities === null) || (entities?.length === 0)))
            {
              if ((includePatientRequestDetails === false) && (includePatientSignContracts === false))
              {
                observer.next(null);
                return;
              }
            }

            var userSeletInfo : UserSeletInfo = new UserSeletInfo ();
            

            if (onlyExternalForms===true)
            {
              entities = entities.filter(x => (x.isExternalUseForm ?? false) == true);
              //entities = entities.filter(x => (x.isGeneralForm ?? false) == false);

              if ((entities?.length <= 0))
              {
                if ((includePatientRequestDetails === false) && (includePatientSignContracts === false))
                {
                  this.alchemintSharedService.openSnackBar("ℹ️ No forms have been marked for external use. If you have additional forms you wish a patient to fill in then please mark those forms in the Form Builder for External Use.", 6000)
                }
              }
            }

            if (excludeSickNoteAndScript)
            {
              entities = entities.filter(x => x.functionalCode !== 'SCRIPT').filter(x => x.functionalCode !== 'SNOTE').filter(x => x.functionalCode !== 'SNOTE1');
            }

            if (includeDisabledForms)
            {
              userSeletInfo.entities = entities?.sort((x,y) => x.name > y.name ? 1 : -1) ;
            }
            else
            {
              userSeletInfo.entities = entities?.filter(x => (x.enabled != false)) .sort((x,y) => x.name > y.name ? 1 : -1) ;
            }
            
            if (entities?.length === 0)
            {
              if ((includePatientRequestDetails === false) && (includePatientSignContracts === false))
              {

                if (billingFormsOnly===true)
                {
                  this.alchemintSharedService.openOkMessageBoxSync("No Billable Forms Configured", "There are no forms configured as Billable Forms. Please configure forms as Billable in the Form Builder in order to submit Billing Instructions.");
                }
                else
                {
                  this.alchemintSharedService.openOkMessageBoxSync("No forms found", "No forms were found. Please create a forms in the Form Builder.");
                }
                
                observer.next(null);
                return;
              }
            }

            userSeletInfo.prompt = prompt; 
            userSeletInfo.makeSelectNonOptionAvailable = makeNoneOptionAvailable;
            userSeletInfo.allowMultiSelect = allowMultiSelect;

            userSeletInfo.includePatientDetailsRequest = includePatientRequestDetails;
            userSeletInfo.includePatientSignContracts = includePatientSignContracts;

            userSeletInfo.matIconForEntity = 'article';

            const dialogRef = this.dialog.open(UserSelectEntityComponent, {data : userSeletInfo, panelClass: 'alchemintdialogpanel'});
    
            dialogRef.afterClosed().subscribe(result => {
              if (result)
              {
                if ((result === null) || (result?.length === 0))
                {
                  observer.next(null);
                }
                else
                {
                  if (allowMultiSelect)
                  {
                    observer.next(result);
                  }
                  else
                  {
                    observer.next([result]);
                  }
                  
                }
              }

            }, 
            error => {
              return throwError(error);
            }
            );
          }
        );
      }
    );

  }

  public userSelectTemplate (prompt : string, makeNoneOptionAvailable : boolean, bypassIfNoTemplates : boolean, useSharedFormLibrary: boolean, allowMultiSelect: boolean) : Observable<Artifact[]>
  {
    return new Observable<Artifact[]> (
      observer => 
      {
        var getter : Observable<Artifact[]>;
        if (useSharedFormLibrary)
        {
          getter = this.webApiInterfaceService.getTemplatesFromSharelLibrary();
        }
        else
        {
          getter = null // this.webApiInterfaceService.getTemplates();
        }
        getter.subscribe(
          entities => 
          {

            if ((bypassIfNoTemplates === true) && ((entities === null) || (entities?.length === 0)))
            {
              observer.next(null);
              return;
            }

            var userSeletInfo : UserSeletInfo = new UserSeletInfo ();

            if (entities?.length === 0)
            {
              observer.next(null);
              return;
            }
            
            userSeletInfo.entities = entities
            userSeletInfo.prompt = prompt; 
            userSeletInfo.makeSelectNonOptionAvailable = makeNoneOptionAvailable;
            userSeletInfo.allowMultiSelect = allowMultiSelect;
            userSeletInfo.matIconForEntity = 'article';

            const dialogRef = this.dialog.open(UserSelectEntityComponent, {data : userSeletInfo, panelClass: 'alchemintdialogpanel'});
    
            dialogRef.afterClosed().subscribe(result => {
              if (result)
              {
                if ((result === null) || (result?.length === 0))
                {
                  observer.next(null);
                }
                else
                {
                  if (allowMultiSelect)
                  {
                    observer.next(result);
                  }
                  else
                  {
                    observer.next([result]);
                  }
                  
                }
              }

            }, 
            error => {
              return throwError(error);
            }
            );
          }
        );
      }
    );

  }

  public userSelectArtifactType (
    prompt : string,
    entities: ArtifactType[],
    ) : Observable<ArtifactType>
  {
    return new Observable<ArtifactType> (
      observer => 
      {

        var userSeletInfo : UserSeletInfo = new UserSeletInfo ();

        if (entities?.length === 0)
        {
          observer.next(null);
          return;
        }
        
        userSeletInfo.entities = entities
        userSeletInfo.prompt = prompt; 
        userSeletInfo.matIconForEntity = 'folder';
        const dialogRef = this.dialog.open(UserSelectEntityComponent, {data : userSeletInfo, panelClass: 'alchemintdialogpanel'});

        dialogRef.afterClosed().subscribe(result => {
          if (result)
          {
            if ((result === null) || (result?.length === 0))
            {
              observer.next(null);
            }
            else
            {
              observer.next(result);
            }
          }

        }, 
        error => {
          return throwError(error);
        }
        );

      }
    );

  }

  public userSelectContact (
    prompt : string,
    entities: Contact[],
    ) : Observable<Contact>
  {
    return new Observable<Contact> (
      observer => 
      {

        var userSeletInfo : UserSeletInfo = new UserSeletInfo ();

        if (entities?.length === 0)
        {
          observer.next(null);
          return;
        }
        
        userSeletInfo.entities = entities
        userSeletInfo.prompt = prompt; 
        userSeletInfo.matIconForEntity = 'person';
        const dialogRef = this.dialog.open(UserSelectEntityComponent, {data : userSeletInfo, panelClass: 'alchemintdialogpanel'});

        dialogRef.afterClosed().subscribe(result => {
          if (result)
          {
            if ((result === null) || (result?.length === 0))
            {
              observer.next(null);
            }
            else
            {
              observer.next(result);
            }
          }
          else
          {
            observer.next(null);
          }

        }, 
        error => {
          return throwError(error);
        }
        );

      }
    );

  }


  public createNewFormField(toCreate : ArtiFormFld ) : Observable<ArtiFormFld>
  {
    return new Observable<ArtiFormFld>(
      obs => {
        this.apiInterfaceService.createEntity<ArtiFormFld>(new ArtiFormFld(), toCreate).subscribe(
          response => { obs.next(response); }, 
          err => { obs.error (err); }
        );
      }
    );
  }

  public prepareFieldForStoring (fld : ArtiFormFld,  selectedStyles: any) : ArtiFormFld
  {
    if (selectedStyles)
    {
      fld.labelStyle = selectedStyles.join(',');
    }
    
    if (fld.position)
    {
      return fld;
    }
    else
    {
      fld.position = 0;
      return fld;
    }

    
  }

  public mustDisableTemplates(artiform: ArtiForm) : boolean
  {

    const disableFormsOfFunctionalCode : string [] = ["SCRIPT", "SNOTE1"];

    if (artiform)
    {
      if (disableFormsOfFunctionalCode.includes (artiform.functionalCode ?? ""))
      {
        if (artiform.templateArtifactId)
        {
          return  false;
        }
        else
        {
          return true;
        }
      }
      else
      {
        return false;
      }
    }
    else
    {
      return false;
    }
  }

  public fieldTypeChangeWillBreakForm (artiFormState: ArtiFormState, addMode:boolean, changeToFieldType: string, originalFieldType: string) : FormChangeCompatabilityResult
  {

    var result : FormChangeCompatabilityResult = new FormChangeCompatabilityResult();
    if (addMode)
    {
      result.isNotCompatable = false;
    }
    else if (artiFormState?.storageStructureExists === false)
    {
      result.isNotCompatable = false;
      return result;

    }
    else 
    {
      //var changeToFieldType = this.fieldForm.controls['artiFormFldTypeId'].value;
      if (changeToFieldType === originalFieldType)
      {
        result.isNotCompatable = false;
      }
      else
      {
        result.isNotCompatable = (this.isCompatableFieldTypeChange(changeToFieldType, originalFieldType) === false);
      }
    }
    if (result.isNotCompatable === true)
    {
      result.reason = "Changing the field type will break the form.";
      result.smallReason= "Invalid Change"
    }
    else
    {
      result.reason = null;
      result.smallReason = null;
    }
    return result;

  }
  private isCompatableFieldTypeChange(changeToFieldType : string, originalFieldType : string) : boolean
  {
    return ArtiFormHelper.canChangeFieldType(changeToFieldType, originalFieldType);
  }

}


export class FormChangeCompatabilityResult
{
  public isNotCompatable : boolean;
  public reason : string;
  public smallReason : string;
}