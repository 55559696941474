
// combo-box.component.ts
import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, forwardRef, HostListener, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormFieldDescriber, IGlowWhenBranchActivated, IReturnValue } from '@app/_alchemint/alchemint_composite_requests';
import { CustomShortcutKeys, Patient } from '@app/_alchemint/alchemint_dm';
import { ApiInterfaceService } from '@app/_services/alchemint.apiinterface.service';
import { ShortcutKeyExecuter, ShortCutKeyHelper } from '@app/artifact-editor/ShortCutKeyHelper';
import { ICanBranchLogic, shouldDisplayDueToBranchLogic } from '@app/branch-logic-functions';
import { FormFieldDescriberExtended } from '@app/external-form/external-form.component';



@Component({
  selector: 'app-multi-line',
  templateUrl: './multi-line.component.html',
  styleUrls: ['./multi-line.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiLineComponent),
      multi: true
    }
  ]
})
export class MultiLineComponent implements OnInit, ControlValueAccessor, IReturnValue, IGlowWhenBranchActivated, ICanBranchLogic {
  
  constructor(public renderer: Renderer2, public  el: ElementRef, private apiInterfaceService: ApiInterfaceService, private datepipe : DatePipe ) {}
  public glow: boolean;

  @ViewChild('textAreaEditor') textAreaEditorref: ElementRef;

  
  
  @Input() isExternalRqMode: boolean = false;
  
  @Input() id: string;
  @Input() readOnly: boolean = false;
  @Input() classing: string;
  @Input() style: string;

  @Input() textAreaStyle: string;
  
  
  private _shortCutKeys: CustomShortcutKeys [];
  @Input() public set shortCutKeys (val: CustomShortcutKeys [])
  {
    this._shortCutKeys = val;
  }

  public get shortCutKeys (): CustomShortcutKeys []
  {
    return this._shortCutKeys;
  }
  
  public formFieldDescriberExtended: FormFieldDescriberExtended;
  public defferredLoadComponents: [string, any, IReturnValue][];



  shouldDisplay(): boolean
  {
    var ret: boolean;
    ret =  shouldDisplayDueToBranchLogic(this.formFieldDescriberExtended , this.defferredLoadComponents, this.el, this.renderer);
    return ret;
  }

  private onChange: any = () => {};
  private onTouched: any = () => {};

  public loadingImage: boolean = false;
  public brokenImage: boolean = false;

  public _textData: string;
  
  public set textData(val : string)
  {
    this._textData = val;
    this.onChange(val);
    this.onTouched();
  }
  public get textData() : string
  {
    return this._textData;
  }

  ngOnInit ()
  {

  }

  writeValue(val: string): void {
    this.setComponentValue (val);
  }

  setComponentValue(val: string)
  {
    this.textData = val;
  }


  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  public get value(): any {
    return this.textData;

  }
  public set value(val: any) {
    this.setComponentValue(val);
  }

  @Input() backColor: string = 'white';
  
  public get colorStyle(): string {
    return `background-color: ${this.backColor};`;
  }


  ngAfterViewInit(): void {
  
  
  }

  public get shortCutKeysForTitle (): string
  {
    var ret: string = '';
    if (this.shortCutKeys)
    {
      ret = 'Short Cut Keys: \n\n';

      for (var i = 0; i < this.shortCutKeys.length; i++)
      {
        ret += `${ShortcutKeyExecuter.getShortCutKeyCombo(this.shortCutKeys[i])} \n`;
      }
    }
    return ret;
  }

  onKeyDown(event: KeyboardEvent) {
    // Check if the Ctrl key is pressed along with another key
    if (event.ctrlKey && event.key !== 'Control') {
      const keyCode = event.which || event.keyCode;
      event.preventDefault();
      var shortCutKeyHelper: ShortcutKeyExecuter = new ShortcutKeyExecuter(this.textAreaEditorref.nativeElement, null);
      var text = shortCutKeyHelper.execCustomShortCutKey(keyCode,event.shiftKey, this.shortCutKeys);
    }
  }



  public textChanged: boolean = false;
  @Input() public userName: string

  @Input() currentPatient: Patient;
  

  @Output() errorEvent = new EventEmitter<any>();
  // editorKeyPress(event : any){

  //   if (event.ctrlKey === false)
  //   {
  //     return;
  //   }
  //   if (event.ctrlKey && event.keyCode == '17')
  //   {
  //     return;
  //   }

  //   ShortcutKeyExecuter._editorKeyPress(event, this.userName, this.datepipe, this.apiInterfaceService, this.textAreaEditorref.nativeElement, this.textChanged, this.currentPatient?.id, this.shortCutKeys, null).subscribe(
  //       result => {
  //         this.textChanged =  result.textChanged;
  //       },
  //       error =>
  //       {
  //         this.errorEvent?.emit(error);
  //       }
  //   );
  // }

  editorKeyPress(event: KeyboardEvent) {
    // Return early if Ctrl key is not pressed or if only Ctrl key is pressed
    if (!event.ctrlKey || event.key === 'Control') {
      return;
    }
  
    // Execute the shortcut key handler
    ShortcutKeyExecuter._editorKeyPress(
      event,
      this.userName,
      this.datepipe,
      this.apiInterfaceService,
      this.textAreaEditorref.nativeElement,
      this.textChanged,
      this.currentPatient?.id,
      this.shortCutKeys,
      null
    ).subscribe(
      result => {
        this.textChanged = result.textChanged;
      },
      error => {
        this.errorEvent?.emit(error);
      }
    );
  }
  }
  


