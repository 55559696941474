import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MultiLineComponent } from './multi-line.component';

const routes: Routes = [
  {path: "", component: MultiLineComponent , loadChildren: () => import('./multi-line.module').then(m => m.MultiLineModule)}

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MultiLineRoutingModule { }




