<!-- <h1 mat-dialog-title>Log In</h1>
<div mat-dialog-content>
  <form [formGroup]="loginForm">
    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Username</mat-label>
      <input matInput formControlName="username" required autocomplete="new-password" name="uniqueFieldId123">
    </mat-form-field>
    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Password</mat-label>
      <input matInput type="password" formControlName="password" required autocomplete="new-password" name="uniqueFieldId123">
    </mat-form-field>
    <div *ngIf="loginFailed" class="error-message">
      Invalid username or password
    </div>
  </form>
</div>
<div mat-dialog-actions class="action-buttons">
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-button color="primary" (click)="onLogin()" [disabled]="!loginForm.valid">OK</button>
</div> -->


<div *ngIf="loginForm!==null">
    <h1 mat-dialog-title>Log In</h1>
    <div mat-dialog-content>
      <form [formGroup]="loginForm">

        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Command Centre Key</mat-label>
          <!-- <input matInput formControlName="commandCentreKey" required autocomplete="new-password" name="uniqueFieldId12asd3"> -->
          <input matInput type="password" formControlName="commandCentreKey" required autocomplete="new-password" name="uniqueFieldId12asd3">

        </mat-form-field>
        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Username</mat-label>
          <input matInput formControlName="username" required autocomplete="new-password" name="uniqueFieldId123">
        </mat-form-field>
        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Password</mat-label>
          <input matInput type="password" formControlName="password" required autocomplete="new-password" name="uniqueFieldId123">
        </mat-form-field>
        <div *ngIf="loginFailed" class="error-message">
          Invalid username or password
        </div>
      </form>
    </div>
    <div mat-dialog-actions class="action-buttons">
      <button mat-button (click)="onCancel()">Cancel</button>
      <button mat-button color="primary" (click)="onLogin()" [disabled]="!(loginForm?.valid)">OK</button>
    </div>
    
</div>
