import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserAssistanceService {

  constructor() { }

  public inUserAssistanceMode : boolean = false;
  public userAssistanceModeIconColors : string  = "orange";

}


