
<div *ngIf="recording===true">
    <h1 class="centered-heading">
        {{recordingTitle}}
    </h1>
</div>

<div [hidden]="replaying===false" id="replayContainer" class="replay-container">


</div>



  




<div *ngIf="(recordMode===true) || (replayMode===true)" class="recording-buttons">
    <button (click)="toggleRecording()" [hidden]="recording===true">
        {{ recording ? 'Stop Recording' : 'Start Recording' }}
    </button>

    <button (click)="replay()" [hidden]="recording===true">Replay</button>

    <button (click)="saveRecording()" [hidden]="recording===true">Save Recording</button>

    <!-- <button (click)="getFileContentsFromFile('AddPatient.json')" [hidden]="recording===true">Load Add Patient</button> -->
    


</div>


<!-- <div *ngIf="recordMode === false" class="recording-buttons">
    <input type="file" id="fileInput" (change)="onFileChange($event)" style="display: none;">
    <label for="fileInput" class="btn btn-primary">Upload File</label>
</div> -->

<div *ngIf="recordMode === true" class="recording-buttons">
    <!-- Hidden file input -->
    <input type="file" id="fileInput" (change)="onFileChange($event)" style="display: none;">
    <!-- Button to trigger file input -->
    <button (click)="triggerFileInput()" [hidden]="recording===true">Upload File</button>

    <button (click)="storeInApi()" [hidden]="recording===true">
        Store In Api
    </button>
</div>


