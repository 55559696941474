export class User {
    id: number;
    username: string;
    password: string;
    firstName: string;
    lastName: string;
    jwtToken?: string;
}

export class UserAlchemint {
    id: string;
    userName: string;
    passwordHash: string;
    token: string;
    masterPriveledges: boolean;
    deleted: boolean;
    isAdminStaff: boolean;
    readOnlyLogon: boolean;
    enable2FA: boolean;
    cellPhoneNumber?: string;
    twoFactorAuthSettings?: {
        channel: string,
        enabled: boolean
    };
    latestTemsAndConditionsVersionAgreedTo: number;
}
