<div  >
    <app-item-selector [fromItems]="masterSet$ | async" [toItems]="activeIcdCodes$ | async" [loading]="loadingMasterSets"
     [leftBlockLabel]="'Master ICD Codes(Inactive)'" [rightBlockLabel]="'Activated ICD Codes'" 
     (onCreateNew)="launchCreate($event)"
     (onEdit)="launchEdit($event)"
     (onActivate)="activate($event)" (onDeactivate)="deactivate($event)"  ></app-item-selector>
</div>

<button class="btn btn-primary" appDoneButton>
    <app-svg color="white" class="btn-icon"> arrow_back </app-svg> Done
</button>