import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { ApiKeys, IAlchemintEntity, SourceControlCommit, SourceControlRepo, SupportDeskUser, SupportTicket, SupportTicketChatItem, UserFeatureEvaluations } from './_alchemint/alchemint_dm';
import { HttpClient } from '@angular/common/http';
import { EnvService } from './_services/environment.service';
import { formatDate } from '@angular/common';
import { AlchemintSharedService } from './alchemint-shared.service';
import { InitialSupportTicketDTO, TextDTO } from './_alchemint/alchemint_composite_requests';
import { ApplicationFeature } from './_services/features.service';
import { AuthenticationService } from './_services';

@Injectable()
export class HelpDeskService {
  apiExtendedUrl: string;
  constructor(private http: HttpClient, private envService: EnvService, private alchemintSharedService: AlchemintSharedService, private auth: AuthenticationService) {

        
    var environment = this.envService.deploymentSettings;
    this.apiExtendedUrl = environment.apiExtendedUrl;

   }

  
  // public fetchClients(): Observable<ApiKeys[]> {
  //   const mockClients: ApiKeys[] = [];

  //   for (let i = 0; i < 10; i++) {
      
  //     var client = new ApiKeys();
  //     client.id = `id-${i}`;
  //     client.companyName = `Company Name ${i}`;
  //     client.apiKey = `API_KEY_${i}`;
  //     mockClients.push(client);
  //   }

  //   return of(mockClients);
  // }


  public logonSupportDeskUser(commandCentreKey:string, username: any, password: any) : Observable<SupportDeskUser>
  {
    var queryString =  this.apiExtendedUrl + `supportdesk/verifyuserandpaswsword/${username}/${password}`;
    this.auth.helpDeskApiKey = commandCentreKey;
    return this.http.get<SupportDeskUser>(queryString,  { headers : this.buildHeadsAppJsonForLogon(commandCentreKey) });
  }


  public getClients (includeDemoClients: boolean, includeDisabledClients: boolean): Observable<ApiKeys[]>
  {
   var queryString =  this.apiExtendedUrl + `supportdesk/getclients/${includeDemoClients}/${includeDisabledClients}`;
   return this.http.get<ApiKeys[]>(queryString,  { headers : this.buildHeadsAppJson() }).pipe(
    map(apiKeys => apiKeys.sort((a, b) => a.companyName.localeCompare(b.companyName)))
  );
  }
  

  



  public getClientsByClaimsToken(claimsTokenEncoded: string, includeDemoClients: boolean, includeDisabledClients: boolean): Observable<ApiKeys[]>
  {
   var queryString =  this.apiExtendedUrl + `supportdesk/getclientsbyclaimstoken/${claimsTokenEncoded}/${includeDemoClients}/${includeDisabledClients}`;

   return this.http.get<ApiKeys[]>(queryString,  { headers : this.buildHeadsAppJson() }).pipe(
    map(apiKeys => apiKeys.sort((a, b) => a.companyName.localeCompare(b.companyName)))
  );
  }




  public getClientsWithoutBlankApikey(): Observable<ApiKeys[]> {
    const queryString = this.apiExtendedUrl + 'supportdesk/getclientswithoutblankapikey';
    return this.http.get<ApiKeys[]>(queryString, { headers: this.buildHeadsAppJson() }).pipe(
      map(apiKeys => apiKeys.sort((a, b) => a.companyName.localeCompare(b.companyName)))
    );
  }



  public getOpenTickets (claimsTokenEncoded: string, includeDemoClients: boolean, includeDisabledClients: boolean): Observable<SupportTicket[]>
  {
    var queryString =  this.apiExtendedUrl + `supportdesk/opensupporttickets/${claimsTokenEncoded}/${includeDemoClients}/${includeDisabledClients}`;
   return this.http.get<SupportTicket[]>(queryString,  { headers : this.buildHeadsAppJson() });
  }

  public getClosedTickets(fromDate: Date, toDate: Date, claimsTokenEncoded: string, includeDemoClients: boolean, includeDisabledClients: boolean): Observable<SupportTicket[]> {
    const fromDateString = formatDate(fromDate, 'yyyy-MM-dd', 'en-US');
    const toDateString = formatDate(toDate, 'yyyy-MM-dd', 'en-US');
    const queryString = `${this.apiExtendedUrl}supportdesk/closedsupporttickets/${fromDateString}/${toDateString}/${claimsTokenEncoded}/${includeDemoClients}/${includeDisabledClients}`;
    return this.http.get<SupportTicket[]>(queryString, { headers: this.buildHeadsAppJson() });
  }



  public getSupportTicket (ticketId: string): Observable<SupportTicket>
  {

    var hds = { Accept: 'application/json' };

   var queryString =  this.apiExtendedUrl + `supportchat/getsupportticket/${ticketId}`;
   return this.http.get<SupportTicket>(queryString,  { headers : hds });

   

  }

  


  // public getClosedTickets (fromDate: Date, toDate: Date): Observable<SupportTicket[]>
  // {
  //  var queryString =  this.apiExtendedUrl + `supportdesk/closedsupporttickets/${fromDate}/${toDate}`;
  //  return this.http.get<SupportTicket[]>(queryString,  { headers : this.buildHeadsAppJson() });
  // }

  public addTicket (supportTicket: SupportTicket): Observable<SupportTicket>
  {
   var queryString =  this.apiExtendedUrl + `supportdesk/addsupportticket`;
   return this.http.post<SupportTicket>(queryString, supportTicket).pipe();
  }

  public clientAddTicket (supportTicket: SupportTicket): Observable<InitialSupportTicketDTO>
  {
   var queryString =  this.apiExtendedUrl + `supportchat/clientaddsupportticket`;
   return this.http.post<InitialSupportTicketDTO>(queryString, supportTicket).pipe();
  }

  public clientSupporTicketChatItem (supportTicketChatItem: SupportTicketChatItem): Observable<SupportTicketChatItem>
  {
   var queryString =  this.apiExtendedUrl + `supportchat/addsupportticketchatitem`;
   return this.http.post<SupportTicketChatItem>(queryString, supportTicketChatItem).pipe();
  }
  public updateUserFeatureEval (userFeatureEval: UserFeatureEvaluations): Observable<UserFeatureEvaluations>
  {
   var queryString =  this.apiExtendedUrl + `supportchat/updateuserfeatureeval`;
   return this.http.put<UserFeatureEvaluations>(queryString, userFeatureEval );
  }

  public getUserFeatureEvals (loginId :  string): Observable<UserFeatureEvaluations[]>
  {
   var queryString =  this.apiExtendedUrl + `supportchat/getuserfeatureevals/${loginId}`;
  //  return this.http.get<UserFeatureEvaluations[]>(queryString, { headers : this.buildHeadsAppJson() });
   return this.http.get<UserFeatureEvaluations[]>(queryString);
  }

  public getApplicationFeatures  (): Observable<ApplicationFeature[]>
  {
   var queryString =  this.apiExtendedUrl + `supportchat/getapplicationfeatures`;
  //  return this.http.get<ApplicationFeature[]>(queryString, { headers : this.buildHeadsAppJson() });
   return this.http.get<ApplicationFeature[]>(queryString);
  }

  



  public getClientSupporTicketChatItems (supportTicketId: string): Observable<SupportTicketChatItem[]>
  {


    var hds = { 
      Accept: 'application/json'  
    };

   var queryString =  this.apiExtendedUrl + `supportchat/getsupportticketchatitems/${supportTicketId}`;
   return this.http.get<SupportTicketChatItem[]>(queryString, { headers : hds }).pipe();
  }

  



  public updateTicket (supportTicket: SupportTicket): Observable<SupportTicket>
  {
   var queryString =  this.apiExtendedUrl + `supportdesk/updatesupportticket`;
   return this.http.put<SupportTicket>(queryString, supportTicket, { headers : this.buildHeadsAppJson() });
  }



  


  public deleteTicket (supportTicketId: string): Observable<boolean>
  {
     var queryString =  this.apiExtendedUrl + `supportdesk/deletesupportticket/${supportTicketId}`;
     return this.http.delete<boolean>(queryString,{ headers : this.buildHeadsAppJson() });
  }

  public getUsers(): Observable<SupportDeskUser[]>
  {
    var queryString =  this.apiExtendedUrl + `supportdesk/getusers`;
    return this.http.get<SupportDeskUser[]>(queryString,  { headers : this.buildHeadsAppJson() });
  }

  public updateUser(user: SupportDeskUser): Observable<SupportDeskUser>
  {
    var queryString =  this.apiExtendedUrl + `supportdesk/updateuser`;
    return this.http.put<SupportDeskUser>(queryString, user, { headers : this.buildHeadsAppJson() });
  }
  public createUser(user: SupportDeskUser): Observable<SupportDeskUser>
  {
    var queryString =  this.apiExtendedUrl + `supportdesk/adduser`;
    return this.http.post<SupportDeskUser>(queryString, user, { headers : this.buildHeadsAppJson() });
  }

  public sendUserPassword(user: SupportDeskUser): Observable<SupportDeskUser>
  {
    var queryString =  this.apiExtendedUrl + `supportdesk/senduserpassword`;
    return this.http.post<SupportDeskUser>(queryString, user, { headers : this.buildHeadsAppJson() });
  }

  

  public initSourceCodeRepo(id: string, name: string): Observable<SourceControlRepo>
  {
    var queryString =  this.apiExtendedUrl + `supportdesk/initsourcecoderepo/${id}/${name}`;
    return this.http.get<SourceControlRepo>(queryString , { headers : this.buildHeadsAppJson() });
  }


  public createSourceCodeFile(fileName: string, code: string, commitMessage: string): Observable<SourceControlCommit>
  {
    var textDTO: TextDTO = new TextDTO();
    textDTO.text = code;

    var queryString =  this.apiExtendedUrl + `supportdesk/createsourcecodefile/${fileName}/${commitMessage}`;
    return this.http.post<SourceControlCommit>(queryString , textDTO, { headers : this.buildHeadsAppJson() });
  }
  




  public commitSourceCode(id: string, code: string, commitMessage: string): Observable<SourceControlCommit>
  {
    var textDTO: TextDTO = new TextDTO();
    textDTO.text = code;
    var queryString =  this.apiExtendedUrl + `supportdesk/modifysourcecodefile/${id}/${commitMessage}`;
    return this.http.post<SourceControlCommit>(queryString , textDTO, { headers : this.buildHeadsAppJson() });
  }



  public GetAISummaryOfHelpDeskForMonth (month: string, year: string): Observable<TextDTO>
  {
   var queryString =  this.apiExtendedUrl + `supportdesk/getaisummaryformonth/${month}/${year}`;
   return this.http.get<TextDTO>(queryString,  { headers : this.buildHeadsAppJson() });
  }

  private buildHeadsAppJson () : any
  {
      var hds = { 
        Accept: 'application/json' , 
        ApiKey: this.getSupportDeckApiKey() ,
  
      };
      return hds;

  }

  private buildHeadsAppJsonForLogon (commandCentreKey: string) : any
  {
      var hds = { 
        Accept: 'application/json' , 
        ApiKey: commandCentreKey
  
      };
      return hds;

  }



  private buildHeadsAppJson2 () : any
  {
     var hds = { 
   
      ApiKey: '4714ec75-b410-4855-b80d-868572c3cef7' ,

    };
     return hds;
  }

  public storeSupportDeckApiKey (apiKey: string): void
  {
    localStorage.setItem('helpDeskApiKey', apiKey);
  }

  public getSupportDeckApiKey () : string
  {
    return localStorage.getItem('helpDeskApiKey');
  }
}
